import { Result, Typography } from "antd";
import { ReactNode, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ConfirmDetails } from "../../types";

export const Confirmation = () => {
  const { state } = useLocation();
  const details = state as ConfirmDetails;

  const navigate = useNavigate();

  const { Paragraph } = Typography;

  const displaySubTitle = (): ReactNode => {
    if (Array.isArray(details?.subTitle)) {
      const ps = details?.subTitle.map((s) => (
        <Paragraph key={s}>{s}</Paragraph>
      ));
      return <>{ps}</>;
    } else {
      return <Paragraph>{details?.subTitle}</Paragraph>;
    }
  };

  useEffect(() => {
    const returnUrl = details && details.returnUrl ? details.returnUrl : "/";
    const timeout = setTimeout(() => {
      if (details.returnState) {
        navigate(returnUrl, {
          replace: true,
          state: { ...details.returnState },
        });
      } else {
        navigate(returnUrl, { replace: true });
      }
    }, details.timeout || 30000);

    return () => {
      clearTimeout(timeout);
    };
  }, [details, navigate]);

  return (
    <>
      {details && (
        <Result
          status="success"
          title={details?.title}
          subTitle={displaySubTitle()}
        />
      )}
    </>
  );
};
