import { Alert, Layout, Spin, Typography } from "antd";
import { useEffect } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";

import "./App.css";

import { Navbar } from "./components/Navbar";
import { useAuthContext } from "./hooks/useAuthContext";
import { useCacheContext } from "./hooks/useCacheContext";
import { BatchDetails } from "./pages/batch/BatchDetails";
import { BatchReport } from "./pages/batch/BatchReport";
import { CreateBatch } from "./pages/batch/CreateBatch";
import { PrintBatch } from "./pages/batch/PrintBatch";
import { SearchBatches } from "./pages/batch/SearchBatches";
import { Confirmation } from "./pages/confirmation/Confirmation";
import { Dashboard } from "./pages/dashboard/Dashboard";
import { DepositDetails } from "./pages/deposit/DepositDetails";
import { DepositReport } from "./pages/deposit/DepositReport";
import { EditDeposit } from "./pages/deposit/EditDeposit";
import { PrintDeposit } from "./pages/deposit/PrintDeposit";
import { SearchDeposits } from "./pages/deposit/SearchDeposits";
import { AddDonor } from "./pages/donor/AddDonor";
import { ChangeDonorDetails } from "./pages/donor/ChangeDonorDetails";
import { DonorDetails } from "./pages/donor/DonorDetails";
import { DonorReport } from "./pages/donor/DonorReport";
import { EditDonor } from "./pages/donor/EditDonor";
import { SearchDonors } from "./pages/donor/SearchDonors";
import { UploadDonorFile } from "./pages/donor/UploadDonorFile";
import { EditPledge } from "./pages/pledge/EditPledge";
import { IssuePledgeBulk } from "./pages/pledge/IssuePledgeBulk";
import { PledgeAssignment } from "./pages/pledge/PledgeAssignment";
import { PledgeDetails } from "./pages/pledge/PledgeDetails";
import { PledgeReport } from "./pages/pledge/PledgeReport";
import { ReassignPledge } from "./pages/pledge/ReassignPledge";
import { SearchPledges } from "./pages/pledge/SearchPledges";
import { SendPledgeAcknowledgement } from "./pages/pledge/SendPledgeAcknowledgement";
import { SearchTransactions } from "./pages/transaction/SearchTransactions";
import { TransactionDetails } from "./pages/transaction/TransactionDetails";
import { AddUser } from "./pages/user/AddUser";
import { Agreement } from "./pages/user/Agreement";
import { EditUser } from "./pages/user/EditUser";
import { EditUserBulk } from "./pages/user/EditUserBulk";
import { Login } from "./pages/user/Login";
import { SearchUsers } from "./pages/user/SearchUsers";
import { SendDonorChangeEmail } from "./pages/user/SendDonorChangeEmail";
import { SendReminderEmails } from "./pages/user/SendReminderEmails";
import { TempPassword } from "./pages/user/TempPassword";
import { UploadUserFile } from "./pages/user/UploadUserFile";
import { UserDetails } from "./pages/user/UserDetails";
import { UserReport } from "./pages/user/UserReport";
import { EditTransaction } from "./pages/transaction/EditTransaction";

function App() {
  const { user, authIsReady } = useAuthContext();
  const navigate = useNavigate();

  const { Header, Footer, Content } = Layout;
  const { Link: AntLink } = Typography;

  const { envInfo } = useCacheContext();

  useEffect(() => {
    if (envInfo) {
      document.title = envInfo.messagesMap["application.name"];
    }
  }, [envInfo]);

  return (
    <div className="App">
      {(!authIsReady || !envInfo) && (
        <div className="loading">
          <Spin size="large" />
        </div>
      )}
      {authIsReady && envInfo && (
        <Layout className="content-full-height">
          <Header className="header">
            <div className="logo">
              <AntLink
                onClick={() => {
                  navigate("/");
                }}
                style={{
                  textDecoration: "none",
                  color: "whitesmoke",
                  fontWeight: "bold",
                }}
              >
                {envInfo.messagesMap["application.name"]}
              </AntLink>
            </div>
            {user && !user.tempPassword && user.agreementDate && <Navbar />}
          </Header>
          <Content>
            {envInfo.isProd && (
              <Alert
                type="warning"
                message={
                  <span
                    dangerouslySetInnerHTML={{
                      __html: envInfo.messagesMap["application.prodMessage"],
                    }}
                  ></span>
                }
              />
            )}
            {!envInfo.isProd && (
              <Alert type="info" message="This is the Training Environment" />
            )}
            <Routes>
              {user && !user.tempPassword && user.agreementDate && (
                <>
                  {user.auth?.isAdmin && (
                    <>
                      <Route
                        path="/searchTransactions"
                        element={<SearchTransactions />}
                      />
                      <Route
                        path="/transactionDetails/:id"
                        element={<TransactionDetails />}
                      />
                      <Route
                        path="/editTransaction/:id"
                        element={<EditTransaction />}
                      />
                      <Route
                        path="/uploadUserFile"
                        element={<UploadUserFile />}
                      />
                      <Route
                        path="/sendReminderEmails"
                        element={<SendReminderEmails />}
                      />
                      <Route
                        path="/sendDonorChangeEmail"
                        element={<SendDonorChangeEmail />}
                      />
                      <Route path="/editUserBulk" element={<EditUserBulk />} />
                    </>
                  )}
                  {user.auth?.isAdminOrAkfc && (
                    <>
                      <Route
                        path="/uploadDonorFile"
                        element={<UploadDonorFile />}
                      />
                      <Route
                        path="/issuePledgeBulk"
                        element={<IssuePledgeBulk />}
                      />
                      <Route
                        path="/sendPledgeAcknowledgement"
                        element={<SendPledgeAcknowledgement />}
                      />
                    </>
                  )}
                  {(user.auth?.canAddUsers ||
                    user.auth?.isDs ||
                    user.auth?.isCfc) && (
                    <>
                      <Route path="/addUser" element={<AddUser />} />
                      <Route path="/searchUsers" element={<SearchUsers />} />
                      <Route path="/editUser/:id" element={<EditUser />} />
                      <Route
                        path="/userDetails/:id"
                        element={<UserDetails />}
                      />
                      <Route path="/userReport" element={<UserReport />} />
                      <Route path="/searchUsers" element={<SearchUsers />} />
                      <Route
                        path="/userDetails/:id"
                        element={<UserDetails />}
                      />
                    </>
                  )}

                  <Route path="/" element={<Dashboard />} />
                  <Route path="/confirm" element={<Confirmation />} />

                  <Route path="/searchPledges" element={<SearchPledges />} />
                  <Route
                    path="/pledgeDetails/:id"
                    element={<PledgeDetails />}
                  />

                  <Route path="/searchDonors" element={<SearchDonors />} />
                  <Route path="/donorDetails/:id" element={<DonorDetails />} />

                  {(user.auth?.isDs || user.auth?.isCfc) && (
                    <>
                      <Route
                        path="/pledgeAssignment"
                        element={<PledgeAssignment />}
                      />
                      <Route path="/editPledge" element={<EditPledge />} />
                      <Route
                        path="/reassignPledge"
                        element={<ReassignPledge />}
                      />
                      <Route path="/addDonor" element={<AddDonor />} />
                      <Route path="/editDonor/:id" element={<EditDonor />} />
                      <Route
                        path="/changeDonorDetails/:id"
                        element={<ChangeDonorDetails />}
                      />
                      <Route path="/createBatch" element={<CreateBatch />} />
                      <Route
                        path="/searchBatches"
                        element={<SearchBatches />}
                      />
                      <Route path="/printBatch/:id" element={<PrintBatch />} />
                      <Route
                        path="/batchDetails/:id"
                        element={<BatchDetails />}
                      />
                      <Route path="/batchReport" element={<BatchReport />} />
                      <Route
                        path="/depositReport"
                        element={<DepositReport />}
                      />
                      <Route
                        path="/searchDeposits"
                        element={<SearchDeposits />}
                      />
                      <Route
                        path="/editDeposit/:id"
                        element={<EditDeposit />}
                      />
                      <Route path="/editDeposit" element={<EditDeposit />} />
                      <Route
                        path="/printDeposit/:id"
                        element={<PrintDeposit />}
                      />
                      <Route
                        path="/depositDetails/:id"
                        element={<DepositDetails />}
                      />
                    </>
                  )}

                  <Route path="/pledgeReport" element={<PledgeReport />} />
                  <Route path="/donorReport" element={<DonorReport />} />

                  <Route path="*" element={<Navigate replace to="/" />} />
                </>
              )}
              {user && user.tempPassword && (
                <>
                  <Route path="/tempPassword" element={<TempPassword />} />
                  <Route
                    path="*"
                    element={<Navigate replace to="/tempPassword" />}
                  />
                </>
              )}
              {user && !user.agreementDate && (
                <>
                  <Route
                    path="/agreement"
                    element={<Agreement {...envInfo} />}
                  />
                  <Route
                    path="*"
                    element={<Navigate replace to="/agreement" />}
                  />
                </>
              )}
              {!user && (
                <>
                  <Route path="/login" element={<Login />} />
                  <Route path="*" element={<Navigate replace to="/login" />} />
                </>
              )}
            </Routes>
          </Content>
          <Footer>
            <footer className="text-center">
              <hr />
              <small>
                <i>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: envInfo.messagesMap["application.copyright"],
                    }}
                  ></span>
                </i>
              </small>
              <br />
              <small>
                <i>Website Developed By: Gilani &amp; Associates Inc.</i>
              </small>
            </footer>
          </Footer>
        </Layout>
      )}
    </div>
  );
}

export default App;
