import { CacheContext } from "../context/CacheContext";
import { useContext } from "react";

export const useCacheContext = () => {
  const context = useContext(CacheContext);

  if (!context) {
    throw Error("useCacheContext must be used inside a CacheContextProvider");
  }

  return context;
};
