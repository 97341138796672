import {
  MinusCircleOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Collapse,
  DatePicker,
  Form,
  Image,
  Input,
  InputNumber,
  Popover,
  Select,
  Space,
  Spin,
  Typography,
} from "antd";
import { Rule } from "antd/lib/form";
import moment from "moment";
import {
  FocusEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { BackButton } from "../../components/BackButton";
import { CustomInput } from "../../components/CustomInput";
import { Exception } from "../../components/Exception";
import { JkSelect } from "../../components/JkSelect";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useCacheContext } from "../../hooks/useCacheContext";
import {
  Collection,
  ConfirmDetails,
  Donor,
  FLOW_TYPE,
  Pledge,
  User,
} from "../../types";
import { CommonUtils } from "../../utils/CommonUtils";

type FORM_DISPLAY = {
  header: string;
  submitButton: string;
};

export const EditPledge = () => {
  const { state }: { state: any } = useLocation();
  const display: Pledge = state;

  const [formDisplay, setFormDisplay] = useState<FORM_DISPLAY | null>(null);
  const [pledge, setPledge] = useState<Pledge>({});
  const [paymentOptions, setPaymentOptions] = useState<any[]>([]);

  const [error, setError] = useState<string | null>(null);
  const [isPending, setIsPending] = useState(false);

  const { user: _sessionUser } = useAuthContext();
  const sessionUser = useRef<User>(_sessionUser).current as User;
  const isDataLoading = useRef(false);

  const {
    envInfo,
    ccMonthList,
    ccExpiryYearList,
    financialInstitutions,
    minFormNumberDigits,
  } = useCacheContext();

  const [formInstance] = Form.useForm();
  const { Text, Title, Link: AntLink } = Typography;
  const { TextArea } = Input;
  const { Option } = Select;
  const { Panel } = Collapse;
  const navigate = useNavigate();

  const {
    fetchFc,
    formatCurrency,
    formatPhoneNumber,
    ageRangeArray,
    yesNoArray,
    checkCollection,
    allCheckCollections,
    allCollections,
    pdcCollection,
    creditCardTypes,
    validateFormNumber,
    validateNumberField,
  } = CommonUtils();

  const getCashCollectionFromForm = useCallback(
    (form: Pledge, denomination: number): number | undefined => {
      let found: number | undefined = undefined;

      if (form && form.collectionList && form.collectionList.length > 0) {
        found = form.collectionList.find(
          (c) => c.cashDenomination === denomination
        )?.cashNumber;
      }

      return found;
    },
    []
  );

  const getCashCollection = useCallback(
    (form: Pledge): Collection[] => {
      const collectionList = [
        {
          cashDenomination: 10000,
          cashDenominationDisplay: "100",
          cashNumber: getCashCollectionFromForm(form, 10000),
        },
        {
          cashDenomination: 5000,
          cashDenominationDisplay: "50",
          cashNumber: getCashCollectionFromForm(form, 5000),
        },
        {
          cashDenomination: 2000,
          cashDenominationDisplay: "20",
          cashNumber: getCashCollectionFromForm(form, 2000),
        },
        {
          cashDenomination: 1000,
          cashDenominationDisplay: "10",
          cashNumber: getCashCollectionFromForm(form, 1000),
        },
        {
          cashDenomination: 500,
          cashDenominationDisplay: "5",
          cashNumber: getCashCollectionFromForm(form, 500),
        },
        {
          cashDenomination: 1,
          cashDenominationDisplay: "Coins",
          cashNumber: getCashCollectionFromForm(form, 1),
        },
      ];

      return collectionList;
    },
    [getCashCollectionFromForm]
  );

  const formOneTimePaymentOptions = useMemo(() => {
    const paymentArray = [
      { key: "Business Cheque", value: "Business Cheque" },
      {
        key: "Business Post Dated Cheques",
        value: "Business Post Dated Cheques",
      },
      { key: "Cash", value: "Cash" },
      { key: "CreditCard", value: "Credit Card" },
    ];

    if (!envInfo?.isAkfc) {
      paymentArray.push({ key: "DirectDebit", value: "Direct Debit" });
    }

    paymentArray.push(
      ...[
        { key: "Personal Cheque", value: "Personal Cheque" },
        {
          key: "Personal Post Dated Cheques",
          value: "Personal Post Dated Cheques",
        },
      ]
    );

    return paymentArray;
  }, [envInfo?.isAkfc]);

  const onlineOneTimePaymentOptions = useMemo(() => {
    const paymentArray = [{ key: "CreditCard", value: "Credit Card" }];
    if (!envInfo?.isAkfc) {
      paymentArray.push({ key: "DirectDebit", value: "Direct Debit" });
    }
    return paymentArray;
  }, [envInfo?.isAkfc]);

  const pppPaymentOptions = useMemo(() => {
    return [
      { key: "CreditCard", value: "Credit Card" },
      { key: "DirectDebit", value: "Direct Debit" },
    ];
  }, []);

  const handlePaymentOptions = useCallback(
    (paymentMethod: string) => {
      if (paymentMethod === "PPP") {
        setPaymentOptions(pppPaymentOptions);
      } else {
        if (display.flowType !== FLOW_TYPE.ONLINE) {
          setPaymentOptions(formOneTimePaymentOptions);
        } else {
          setPaymentOptions(onlineOneTimePaymentOptions);
        }
      }
    },
    [
      display.flowType,
      formOneTimePaymentOptions,
      onlineOneTimePaymentOptions,
      pppPaymentOptions,
    ]
  );

  const sumTotalCollectionAndFixDates = useCallback(
    (collectionList: Collection[], paymentType: string): number => {
      const { checkCollection, pdcCollection } = CommonUtils();

      let total = 0;
      if (paymentType === "Cash") {
        total = collectionList
          .filter((c) => c.cashNumber && c.cashNumber > 0)
          .reduce(
            (sum, current) =>
              sum + (current.cashDenomination! / 100) * current.cashNumber!,
            0
          );
      } else if (checkCollection.includes(paymentType)) {
        for (let c of collectionList) {
          if (c) {
            if (
              c.checkAmount &&
              typeof c.checkAmount === "number" &&
              c.checkAmount > 0
            ) {
              total += c.checkAmount;
            }

            if (c.checkDate) {
              c.checkDate = moment(c.checkDate);
            }
          }
        }
      } else if (pdcCollection.includes(paymentType)) {
        for (let c of collectionList) {
          if (c) {
            if (
              c.pdcAmount &&
              typeof c.pdcAmount === "number" &&
              c.pdcAmount > 0
            ) {
              total += c.pdcAmount;
            }

            if (c.pdcDate) {
              c.pdcDate = moment(c.pdcDate);
            }
          }
        }
      }

      return total;
    },
    []
  );

  useEffect(() => {
    if (!envInfo) {
      return;
    }

    const { fetchFc, formatCurrency } = CommonUtils();

    if (display.flowType === FLOW_TYPE.ENTER) {
      setFormDisplay({
        header: envInfo.messagesMap["main.enterPledge"],
        submitButton: "Enter Form",
      });
    } else if (display.flowType === FLOW_TYPE.VOID) {
      setFormDisplay({
        header: "Void PA Form",
        submitButton: "Void Form",
      });
    } else if (display.flowType === FLOW_TYPE.AUDIT) {
      setFormDisplay({
        header: "Audit Form",
        submitButton: "Audit Form",
      });
    } else if (display.flowType === FLOW_TYPE.ONLINE) {
      setFormDisplay({
        header: "Online Contribution",
        submitButton: "Submit Online Form",
      });
    }

    const loadForm = async (id: number): Promise<Pledge> => {
      try {
        const form = await fetchFc<Pledge>(`/pledges/${id}`, "GET");
        return form;
      } catch (err) {
        throw err;
      }
    };

    const loadDonor = async (id: number): Promise<Donor> => {
      try {
        return await fetchFc<Donor>(`/donors/${id}`, "GET");
      } catch (err) {
        throw err;
      }
    };

    const loadUser = async (id: number): Promise<User> => {
      try {
        return await fetchFc<User>(`/users/${id}`, "GET");
      } catch (err) {
        throw err;
      }
    };

    const loadData = async () => {
      isDataLoading.current = true;

      setError(null);
      setIsPending(true);

      let p: Pledge | undefined = undefined;
      if (display.id) {
        p = await loadForm(display.id);
        if (p.paymentMethod === "Cash") {
          p.collectionList = getCashCollection(p);
        }
        p.giftDate = moment(p.giftDate);
        p.amount = formatCurrency(p.amount as number);
        handlePaymentOptions(p.paymentMethod!);

        if (p.paymentType === "Cash") {
          p.collectionList = getCashCollection(p);
        }

        const total = sumTotalCollectionAndFixDates(
          p.collectionList!,
          p.paymentType!
        );
        p.totalCollection = formatCurrency(total, 2, false);
      } else {
        p = {};
      }

      if (display.donor?.id) {
        p.donor = await loadDonor(display.donor.id);
      }

      let duplicateError = false;
      if (display.volunteer1?.id) {
        p.volunteer1 = await loadUser(display.volunteer1.id);

        if (
          display.volunteer2?.id &&
          display.volunteer1.id === display.volunteer2.id
        ) {
          duplicateError = true;
        }
      }

      if (!duplicateError && display.volunteer2?.id) {
        p.volunteer2 = await loadUser(display.volunteer2.id);
      }

      if (duplicateError) {
        setError("2 volunteers cannot be the same");
        display.volunteer2 = { id: undefined, login: undefined };
      }

      setPledge(p);
      setIsPending(false);

      isDataLoading.current = false;
    };

    if (!isDataLoading.current) {
      loadData();
    }
  }, [
    isDataLoading,
    display,
    envInfo,
    getCashCollection,
    handlePaymentOptions,
    sumTotalCollectionAndFixDates,
  ]);

  const handlePaymentTypeChange = (value: string) => {
    if (value === "Cash") {
      if (display.flowType === FLOW_TYPE.ENTER) {
        formInstance.setFieldsValue({
          collectionList: getCashCollection(formInstance.getFieldsValue()),
          totalCollection: undefined,
          amount: undefined,
        });
      }
    } else if (checkCollection.includes(value)) {
      if (display.flowType === FLOW_TYPE.ENTER) {
        const collectionList = [
          { checkNumber: undefined, checkAmount: undefined },
        ];
        formInstance.setFieldsValue({
          collectionList,
          totalCollection: undefined,
          amount: undefined,
        });
      }
    } else if (pdcCollection.includes(value)) {
      const collectionList = [{ pdcNumber: undefined, pdcAmount: undefined }];
      formInstance.setFieldsValue({
        collectionList,
        totalCollection: undefined,
        amount: undefined,
      });
    }
  };

  const onFinish = async (formInput: Pledge) => {
    setError(null);

    try {
      if (!pledge.donor?.id) {
        setError("Donor ID not defined.");
        return;
      }

      if (display.flowType !== FLOW_TYPE.ONLINE) {
        if (!pledge.id) {
          setError("Pledge ID not defined.");
          return;
        }

        if (!pledge.volunteer1?.id || !pledge.volunteer2?.id) {
          setError("Both volunteers not defined.");
          return;
        }

        const giftDate = formInput.giftDate as moment.Moment;
        if (giftDate.isAfter()) {
          setError("Entered Date cannot be in the future");
          return;
        }

        const giftDateStr = giftDate.format("DD/MM/YYYY");
        formInput = { ...formInput, giftDateStr, giftDate: undefined };
      }

      if (formInput.monthlyTransactionDate) {
        const monthlyDate = formInput.monthlyTransactionDate as moment.Moment;
        const monthlyTransactionDateStr = monthlyDate.format("DD/MM/YYYY");
        formInput = {
          ...formInput,
          monthlyTransactionDateStr,
          monthlyTransactionDate: undefined,
        };
      }

      if (
        formInput.numberInstalments &&
        (formInput.numberInstalments < 2 || formInput.numberInstalments > 200)
      ) {
        setError(
          "Number of Instalments must be greater than 1 and less than 201"
        );
        return;
      }

      let amount: number = 0;

      if (typeof formInput.amount === "string") {
        amount = Number(formInput.amount.replace(/[^0-9.-]+/g, ""));
      } else {
        amount = formInput.amount as number;
      }

      const totalCollection = Number(
        formInput.totalCollection?.replace(/[^0-9.-]+/g, "")
      );

      if (
        allCollections.includes(formInput.paymentType!) &&
        amount !== totalCollection
      ) {
        setError("Amount and Collection Amount must match");
        return;
      }

      if (formInput.collectionList) {
        for (let c of formInput.collectionList) {
          if (typeof c.checkAmount === "string") {
            c.checkAmount = Number(c.checkAmount.replace(/[^0-9.-]+/g, ""));
          } else if (typeof c.pdcAmount === "string") {
            c.pdcAmount = Number(c.pdcAmount.replace(/[^0-9.-]+/g, ""));
          }
        }
      }

      formInput.id = pledge.id;
      const donor = formInput.donor;
      formInput.donor = { ...donor, id: pledge.donor.id };
      formInput.volunteer1 = { id: pledge.volunteer1?.id };
      formInput.volunteer2 = { id: pledge.volunteer2?.id };
      formInput.deposit = { id: display.deposit?.id };
      formInput.position = display.position;
      formInput.flowType = display.flowType;
      formInput.amount = amount;

      setIsPending(true);
      const result = await fetchFc<Pledge>(
        `/pledges/${display.id}`,
        "POST",
        formInput
      );

      const confirmDetails: ConfirmDetails = {
        title: formDisplay?.header + " Successful",
        subTitle: `${result.formNumber} successfully updated`,
      };

      if (display.flowType === FLOW_TYPE.AUDIT) {
        navigate("/editDeposit", {
          state: { flowType: FLOW_TYPE.EDIT, id: result.deposit?.id },
        });
      } else {
        navigate("/confirm", { state: confirmDetails });
      }
    } catch (err) {
      const errorMessage = (err as Error).message;
      setError(errorMessage);
      setIsPending(false);
    }
  };

  const onValuesChange = (changedValues: any, values: Pledge) => {
    if (changedValues.paymentMethod) {
      setPledge((prevState) => {
        return {
          ...prevState,
          paymentMethod: changedValues.paymentMethod,
          paymentType: undefined,
        };
      });

      handlePaymentOptions(changedValues.paymentMethod);

      formInstance.setFieldsValue({
        paymentType: null,
      });
    }

    if (changedValues.paymentType) {
      setPledge((prevState) => {
        return { ...prevState, paymentType: changedValues.paymentType };
      });
      handlePaymentTypeChange(changedValues.paymentType);
    }

    if (changedValues.collectionList) {
      const collectionList = values.collectionList;

      if (collectionList && values.paymentType) {
        const total = sumTotalCollectionAndFixDates(
          collectionList,
          values.paymentType
        );
        formInstance.setFieldsValue({
          totalCollection: formatCurrency(total, 2, false),
          amount: total,
        });
      }
    }

    if (
      changedValues.monthlyInstalment ||
      changedValues.numberInstalments ||
      changedValues.untilCancelled
    ) {
      const pppAmount = calculatePPPAmount(
        values.monthlyInstalment,
        values.numberInstalments,
        values.untilCancelled
      );

      formInstance.setFieldsValue({ amount: pppAmount });
    }
  };

  const calculatePPPAmount = (
    monthlyInstalment?: number,
    numInstalments?: number,
    untilCancelled?: boolean
  ): number => {
    if (monthlyInstalment && monthlyInstalment > 0) {
      if (numInstalments && numInstalments < 13) {
        return monthlyInstalment * numInstalments;
      } else if ((numInstalments && numInstalments > 12) || untilCancelled) {
        return monthlyInstalment * 12;
      }
    }

    return 0;
  };

  const handleFormNumberBlur = async (e: FocusEvent) => {
    setError(null);

    const element = e.target as HTMLInputElement;
    const formNumber = element.value;

    try {
      setIsPending(true);
      const result = await fetchFc<Pledge[]>(
        `/pledges?formNumber=${formNumber}`,
        "GET"
      );

      if (result.length === 0) {
        setError("Form Number not found");
        setIsPending(false);
        return;
      }

      const found = result[0];
      if (found.donor) {
        setError("Form Number is already assigned to a Donor");
        setIsPending(false);
        return;
      }

      if (found.voidDate) {
        setError("Form Number is has been marked as void");
        setIsPending(false);
        return;
      }

      if (!found.receiveDate) {
        setError("Form Number has not been received at JK");
        setIsPending(false);
        return;
      }

      if (found.batch) {
        setError("Form Number has already been batched");
        setIsPending(false);
        return;
      }

      formInstance.setFieldsValue({ ...found, volunteer1: sessionUser });
      setPledge((p) => ({ ...p, ...found, volunteer1: sessionUser }));
      display.id = found.id;
      display.volunteer1 = { id: sessionUser.id };
      setIsPending(false);
    } catch (err) {
      setError((err as Error).message);
      setIsPending(false);
    }
  };

  const handleSearchUser = (volNumber: number) => {
    if (volNumber === 1) {
      navigate("/searchUsers", {
        state: {
          ...display,
          volunteer1: { login: "vol1" },
          volunteer2: { login: undefined, id: pledge.volunteer2?.id },
        },
      });
    } else {
      navigate("/searchUsers", {
        state: {
          ...display,
          volunteer1: { login: undefined, id: pledge.volunteer1?.id },
          volunteer2: { login: "vol2" },
        },
      });
    }
  };

  const checkNumValidator = async (_: Rule, value: string) => {
    if (!value) {
      throw new Error("Check Number is required");
    }

    if (!validateNumberField(value)) {
      throw new Error("Check Number must be a number");
    }

    return Promise.resolve();
  };

  const formNumValidator = async (_: Rule, value: number) => {
    validateFormNumber(
      envInfo?.isAkfc ? true : false,
      minFormNumberDigits,
      value
    );

    return Promise.resolve();
  };

  const numValidator = async (_: Rule, value: string) => {
    if (!validateNumberField(value)) {
      throw new Error("Value must be a number");
    }

    return Promise.resolve();
  };

  return (
    <div>
      {isPending && (
        <div className="loading">
          <Spin size="large" />
        </div>
      )}
      {!isPending && formDisplay && (
        <>
          <BackButton
            buttonText={
              display.flowType === FLOW_TYPE.AUDIT
                ? "Back to Create Deposit"
                : "Back to search page"
            }
          />
          <Title className="text-center">{formDisplay.header}</Title>
          {error && <Exception exceptionDetails={error} />}
          <Form
            form={formInstance}
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 8 }}
            autoComplete="off"
            onFinish={onFinish}
            onValuesChange={onValuesChange}
            scrollToFirstError={true}
            initialValues={{ ...pledge }}
          >
            {display.flowType !== FLOW_TYPE.ONLINE && (
              <>
                <Form.Item label="Form Number" required>
                  <Space>
                    <Form.Item
                      name="formNumber"
                      noStyle
                      rules={[{ required: true, validator: formNumValidator }]}
                    >
                      <InputNumber
                        controls={false}
                        style={{ width: "100%" }}
                        onBlur={handleFormNumberBlur}
                        disabled={display.id != null}
                        min={0}
                        precision={0}
                      />
                    </Form.Item>
                    {display.id != null &&
                      display.flowType === FLOW_TYPE.ENTER && (
                        <Button type="primary" danger>
                          <Link
                            to="/editPledge"
                            state={{
                              donor: { id: display.donor?.id },
                              flowType: FLOW_TYPE.ENTER,
                            }}
                          >
                            Reset
                          </Link>
                        </Button>
                      )}
                  </Space>
                </Form.Item>
                <Form.Item label="Jamatkhana" name={["jamatKhana", "name"]}>
                  <CustomInput disabled={true} />
                </Form.Item>
              </>
            )}
            <Collapse
              defaultActiveKey={["1", "2", "3", "4"]}
              className="content-margin"
            >
              <Panel header="1.0 Donor Details" key="1">
                <Form.Item label="Donor ID" name={["donor", "raiserId"]}>
                  <CustomInput disabled={true} />
                </Form.Item>
                <Form.Item label="First Name" name={["donor", "firstName"]}>
                  <CustomInput disabled={true} />
                </Form.Item>
                <Form.Item label="Initial" name={["donor", "initial"]}>
                  <CustomInput disabled={true} />
                </Form.Item>
                <Form.Item label="Last Name" name={["donor", "lastName"]}>
                  <CustomInput disabled={true} />
                </Form.Item>
                <Form.Item label="Org Name" name={["donor", "orgName"]}>
                  <CustomInput disabled={true} />
                </Form.Item>
                {envInfo?.isAkfc && sessionUser.auth?.canUpdateDonors && (
                  <Form.Item label="Age Range" name={["donor", "ageRange"]}>
                    <Select>
                      {ageRangeArray.map((t) => {
                        return (
                          <Option key={t} value={t}>
                            {t}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                )}
                {(!envInfo?.isAkfc || !sessionUser.auth?.canUpdateDonors) && (
                  <Form.Item label="Age Range" name={["donor", "ageRange"]}>
                    <CustomInput disabled={true} />
                  </Form.Item>
                )}
                <Form.Item
                  label="Residential Phone"
                  name={["donor", "resPhone"]}
                >
                  <CustomInput
                    mask="(000) 000-0000"
                    disabled={
                      !envInfo?.isAkfc || !sessionUser.auth?.canUpdateDonors
                    }
                  />
                </Form.Item>
                <Form.Item label="Business Phone" name={["donor", "busPhone"]}>
                  <CustomInput
                    mask="(000) 000-0000"
                    disabled={
                      !envInfo?.isAkfc || !sessionUser.auth?.canUpdateDonors
                    }
                  />
                </Form.Item>
                <Form.Item label="Cell Phone" name={["donor", "cellPhone"]}>
                  <CustomInput
                    mask="(000) 000-0000"
                    disabled={
                      !envInfo?.isAkfc || !sessionUser.auth?.canUpdateDonors
                    }
                  />
                </Form.Item>
                <Form.Item label="Email" name={["donor", "email"]}>
                  <CustomInput
                    disabled={
                      !envInfo?.isAkfc || !sessionUser.auth?.canUpdateDonors
                    }
                  />
                </Form.Item>
                {envInfo?.isAkfc && sessionUser.auth?.canUpdateDonors && (
                  <JkSelect
                    form={formInstance}
                    isRequired={true}
                    isRestricted={false}
                    showAll={true}
                    namePath={["donor", "jamatKhana", "id"]}
                  />
                )}
                {(!envInfo?.isAkfc || !sessionUser.auth?.canUpdateDonors) && (
                  <Form.Item
                    label="Jamat Khana"
                    name={["donor", "jamatKhana", "name"]}
                  >
                    <CustomInput disabled={true} />
                  </Form.Item>
                )}
                {envInfo?.isAkfc && sessionUser.auth?.canUpdateDonors && (
                  <Form.Item
                    label="Receive Email"
                    name={["donor", "receiveEmail"]}
                    rules={[
                      { required: true, message: "Please input Receive Email" },
                    ]}
                  >
                    <Select>
                      {yesNoArray.map((t) => {
                        return (
                          <Option key={t.label} value={t.value}>
                            {t.label}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                )}
                {(!envInfo?.isAkfc || !sessionUser.auth?.canUpdateDonors) && (
                  <Form.Item
                    label="Receive Email"
                    name={["donor", "receiveEmail"]}
                  >
                    <CustomInput disabled={true} />
                  </Form.Item>
                )}
              </Panel>
              {display.flowType !== FLOW_TYPE.ONLINE && display.id && (
                <Panel header="Volunteer Details" key="2">
                  <Form.Item label="Volunteer 1">
                    <Space>
                      <Text>{pledge.volunteer1?.fullName}</Text>
                      <Text>{"-"}</Text>
                      <Text>{formatPhoneNumber(pledge.volunteer1?.phone)}</Text>
                      <Text>{"-"}</Text>
                      <AntLink onClick={() => handleSearchUser(1)}>
                        Change Volunteer
                      </AntLink>
                    </Space>
                  </Form.Item>
                  <Form.Item label="Volunteer 2">
                    {pledge.volunteer2 && (
                      <Space>
                        <Text>{pledge.volunteer2?.fullName}</Text>
                        <Text>{"-"}</Text>
                        <Text>
                          {formatPhoneNumber(pledge.volunteer2?.phone)}
                        </Text>
                        <Text>{"-"}</Text>
                        <AntLink onClick={() => handleSearchUser(2)}>
                          Change Volunteer
                        </AntLink>
                      </Space>
                    )}
                    {!pledge.volunteer2 && (
                      <AntLink onClick={() => handleSearchUser(2)}>
                        Search Volunteer
                      </AntLink>
                    )}
                  </Form.Item>
                </Panel>
              )}
              {(display.flowType === FLOW_TYPE.ONLINE ||
                (pledge.id &&
                  pledge.volunteer1?.id &&
                  pledge.volunteer2?.id)) && (
                <Panel header="2.0 Payment Options" key="3">
                  <Form.Item
                    label="Select Contribution Type"
                    name="paymentMethod"
                    rules={[
                      {
                        required: true,
                        message: "Contribution Type is required!",
                      },
                    ]}
                  >
                    <Select>
                      <Option key="PPP" value="PPP">
                        Option 1: Pre-authorized Payment Plan (PPP)
                      </Option>
                      <Option key="ONETIME" value="ONETIME">
                        Option 2: One Time Payment
                      </Option>
                    </Select>
                  </Form.Item>
                  {pledge.paymentMethod &&
                    paymentOptions &&
                    paymentOptions.length > 0 && (
                      <Form.Item
                        label="Select Payment Method"
                        name="paymentType"
                        rules={[
                          {
                            required: true,
                            message: "Payment method is required!",
                          },
                        ]}
                      >
                        <Select>
                          {paymentOptions.map((o) => {
                            return (
                              <Option key={o.key} value={o.key}>
                                {o.value}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    )}
                  {pledge.paymentMethod === "PPP" && pledge.paymentType && (
                    <>
                      <Form.Item
                        label="Monthly Contribution"
                        name="monthlyInstalment"
                        rules={[
                          {
                            required: true,
                            message:
                              "Please enter the Monthly Contribution Amount!",
                          },
                        ]}
                      >
                        <InputNumber
                          style={{ width: "100%" }}
                          addonBefore="$"
                          controls={false}
                          min={0}
                          precision={2}
                        />
                      </Form.Item>
                      {(!envInfo?.isAkfc ||
                        (envInfo.isAkfc && sessionUser.auth?.isCfc)) && (
                        <Form.Item
                          label="Number of Months"
                          name="numberInstalments"
                        >
                          <InputNumber
                            style={{ width: "100%" }}
                            controls={false}
                            min={0}
                            precision={0}
                          />
                        </Form.Item>
                      )}
                      <Form.Item
                        label="Until Cancelled By Donor"
                        name="untilCancelled"
                        valuePropName="checked"
                      >
                        <Checkbox />
                      </Form.Item>
                      <Form.Item
                        label="Monthly Transaction Date"
                        name="monthlyTransactionDate"
                        rules={[
                          {
                            required: true,
                            message: "Monthly Transaction Date is required!",
                          },
                        ]}
                      >
                        <DatePicker />
                      </Form.Item>
                    </>
                  )}
                  {pledge.paymentType &&
                    allCollections.includes(pledge.paymentType) && (
                      <>
                        <Form.Item
                          label="Collections"
                          shouldUpdate={(oldForm, newForm) =>
                            oldForm.paymentType !== newForm.paymentType
                          }
                        >
                          <Form.List name="collectionList">
                            {(fields, { add, remove }) => (
                              <>
                                {fields.map(({ key, name, ...restField }) => (
                                  <Space
                                    key={key}
                                    style={{ display: "flex", marginBottom: 6 }}
                                    align="baseline"
                                  >
                                    {checkCollection.includes(
                                      pledge.paymentType!
                                    ) && (
                                      <>
                                        <Form.Item
                                          {...restField}
                                          name={[name, "checkNumber"]}
                                          rules={[
                                            {
                                              required: true,
                                              message:
                                                "Check Number is required",
                                              validator: checkNumValidator,
                                            },
                                          ]}
                                        >
                                          <Input
                                            placeholder="Number"
                                            style={{
                                              maxWidth: "90px",
                                            }}
                                            min={0}
                                          />
                                        </Form.Item>
                                        <Form.Item
                                          {...restField}
                                          name={[name, "checkAmount"]}
                                          rules={[
                                            {
                                              required: true,
                                              message:
                                                "Check Amount is required",
                                            },
                                          ]}
                                        >
                                          <InputNumber
                                            placeholder="Amount"
                                            style={{ minWidth: 70 }}
                                            controls={false}
                                            min={0}
                                            precision={2}
                                          />
                                        </Form.Item>
                                        <Form.Item
                                          {...restField}
                                          name={[name, "checkDate"]}
                                          rules={[
                                            {
                                              required: true,
                                              message: "Check Date is required",
                                            },
                                          ]}
                                        >
                                          <DatePicker />
                                        </Form.Item>
                                        {name > 0 && (
                                          <MinusCircleOutlined
                                            onClick={() => remove(name)}
                                          />
                                        )}
                                      </>
                                    )}
                                    {pdcCollection.includes(
                                      pledge.paymentType!
                                    ) && (
                                      <>
                                        <Form.Item
                                          {...restField}
                                          name={[name, "pdcNumber"]}
                                          rules={[
                                            {
                                              required: true,
                                              message: "PDC Number is required",
                                              validator: checkNumValidator,
                                            },
                                          ]}
                                        >
                                          <Input
                                            placeholder="Number"
                                            style={{ maxWidth: 90 }}
                                            min={0}
                                          />
                                        </Form.Item>
                                        <Form.Item
                                          {...restField}
                                          name={[name, "pdcAmount"]}
                                          rules={[
                                            {
                                              required: true,
                                              message: "PDC Amount is required",
                                            },
                                          ]}
                                        >
                                          <InputNumber
                                            placeholder="Amount"
                                            style={{ minWidth: 70 }}
                                            controls={false}
                                            min={0}
                                            precision={2}
                                          />
                                        </Form.Item>
                                        <Form.Item
                                          {...restField}
                                          name={[name, "pdcDate"]}
                                          rules={[
                                            {
                                              required: true,
                                              message: "PDC Date is required",
                                            },
                                          ]}
                                        >
                                          <DatePicker />
                                        </Form.Item>
                                        {name > 0 && (
                                          <MinusCircleOutlined
                                            onClick={() => remove(name)}
                                          />
                                        )}
                                      </>
                                    )}
                                    {pledge.paymentType === "Cash" && (
                                      <>
                                        <Form.Item
                                          {...restField}
                                          name={[
                                            name,
                                            "cashDenominationDisplay",
                                          ]}
                                        >
                                          <InputNumber
                                            disabled={true}
                                            addonBefore="$"
                                          />
                                        </Form.Item>
                                        X
                                        <Form.Item
                                          {...restField}
                                          name={[name, "cashNumber"]}
                                        >
                                          <InputNumber
                                            placeholder="Num"
                                            min={0}
                                            precision={0}
                                          />
                                        </Form.Item>
                                      </>
                                    )}
                                  </Space>
                                ))}
                                {allCheckCollections.includes(
                                  pledge.paymentType!
                                ) && (
                                  <Form.Item>
                                    <Button
                                      type="dashed"
                                      onClick={() => add()}
                                      block
                                      icon={<PlusOutlined />}
                                    >
                                      Add Check
                                    </Button>
                                  </Form.Item>
                                )}
                              </>
                            )}
                          </Form.List>
                        </Form.Item>
                        <Form.Item
                          label="Total Collection"
                          name="totalCollection"
                          shouldUpdate={(oldForm, newForm) =>
                            oldForm.collectionList !== newForm.collectionList
                          }
                        >
                          <InputNumber
                            placeholder="Total"
                            disabled
                            addonBefore="$"
                          />
                        </Form.Item>
                      </>
                    )}
                  {pledge.paymentType === "CreditCard" && (
                    <>
                      <Form.Item
                        label="Credit Card Type"
                        name="ccType"
                        rules={[
                          {
                            required: true,
                            message: "Credit Card Type is required!",
                          },
                        ]}
                      >
                        <Select>
                          {creditCardTypes.map((s) => {
                            return (
                              <Option key={s} value={s}>
                                {s}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        label="Credit Card Number"
                        name="cardNumber"
                        rules={[
                          {
                            required: true,
                            message: "Please enter the Credit Card Number!",
                          },
                        ]}
                      >
                        <InputNumber
                          style={{ width: "100%" }}
                          controls={false}
                          min={0}
                          precision={0}
                        />
                      </Form.Item>
                      <Form.Item label="Credit Card Expiration" required>
                        <Space>
                          <Form.Item
                            name="ccExpirationMonth"
                            noStyle
                            rules={[
                              {
                                required: true,
                                message:
                                  "Card Card Expiration Month is required!",
                              },
                            ]}
                          >
                            <Select style={{ width: 70 }} placeholder="MM">
                              {ccMonthList.map((s) => {
                                return (
                                  <Option key={s} value={s}>
                                    {s}
                                  </Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                          {" / "}
                          <Form.Item
                            name="ccExpirationYear"
                            noStyle
                            rules={[
                              {
                                required: true,
                                message:
                                  "Card Card Expiration Year is required!",
                              },
                            ]}
                          >
                            <Select style={{ width: 80 }} placeholder="YYYY">
                              {ccExpiryYearList.map((s) => {
                                return (
                                  <Option key={s} value={s}>
                                    {s}
                                  </Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                        </Space>
                      </Form.Item>
                      <Form.Item
                        label="Card Holder Name"
                        name="cardHolderName"
                        rules={[
                          {
                            required: true,
                            message: "Please enter the Card Holder Name!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        label="Card Security Code"
                        name="ccSecurityCode"
                        rules={[
                          {
                            required: true,
                            message:
                              "Card Security Code is required and must be numeric!",
                            validator: numValidator,
                          },
                        ]}
                      >
                        <Input style={{ width: "100%" }} />
                      </Form.Item>
                    </>
                  )}
                  {pledge.paymentType === "DirectDebit" && (
                    <>
                      <Form.Item
                        label="Void Cheque Enclosed"
                        name="voidChequeEnclosed"
                      >
                        <Checkbox />
                      </Form.Item>
                      <Form.Item
                        label="Bank Name"
                        name="bankName"
                        rules={[
                          {
                            required: true,
                            message: "Bank Name is required!",
                          },
                        ]}
                      >
                        <Select>
                          {financialInstitutions.map((s) => {
                            return (
                              <Option key={s} value={s}>
                                {s}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        label="Transit Number"
                        name="transitNumber"
                        rules={[
                          {
                            required: true,
                            message:
                              "Transit Number is required and must be numeric!!",
                            validator: numValidator,
                          },
                        ]}
                      >
                        <Input style={{ width: "100%" }} />
                      </Form.Item>
                      <Form.Item label="Institution ID" required>
                        <Space>
                          <Form.Item
                            noStyle
                            name="institutionId"
                            rules={[
                              {
                                required: true,
                                message:
                                  "Institution ID is required and must be numeric!!",
                                validator: numValidator,
                              },
                            ]}
                          >
                            <Input style={{ width: "100%" }} />
                          </Form.Item>
                          <Popover
                            title="Help"
                            content={
                              <Image src="https://www.akfcnetcommunity.ca/NetCommunity/images/CanCheck.bmp" />
                            }
                          >
                            <QuestionCircleOutlined />
                          </Popover>
                        </Space>
                      </Form.Item>
                      <Form.Item
                        label="Account Number"
                        name="accountNumber"
                        rules={[
                          {
                            required: true,
                            message:
                              "Account Number is required and must be numeric!",
                            validator: numValidator,
                          },
                        ]}
                      >
                        <Input style={{ width: "100%" }} />
                      </Form.Item>
                      <Form.Item
                        label="Account Holder's Name"
                        name="accountHolderName"
                        rules={[
                          {
                            required: true,
                            message: "Account Holder's Name is required!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </>
                  )}
                </Panel>
              )}
              {(display.flowType === FLOW_TYPE.ONLINE ||
                (pledge.id &&
                  pledge.volunteer1?.id &&
                  pledge.volunteer2?.id)) &&
                pledge.paymentType && (
                  <Panel header="3.0 Pledge Information" key="4">
                    <Form.Item label="Contribution amount($) for 2023" required>
                      <Space>
                        <Form.Item
                          name="amount"
                          noStyle
                          rules={[
                            {
                              required: true,
                              message: "Please enter the Amount!",
                            },
                          ]}
                        >
                          <InputNumber
                            style={{ width: "100%" }}
                            addonBefore="$"
                            controls={false}
                            min={0}
                            precision={2}
                          />
                        </Form.Item>
                        <Popover
                          title="Help"
                          content={
                            <>
                              <p>
                                For PPP contributions, regardless of the date of
                                the contribution, this value is:
                              </p>
                              <p>
                                Monthly Contribution x Number of Months (if less
                                than or equal to 12 months)
                              </p>
                              <p>
                                Monthly Contribution x 12 (if greater than or
                                equal to 12 months)
                              </p>
                            </>
                          }
                        >
                          <QuestionCircleOutlined />
                        </Popover>
                      </Space>
                    </Form.Item>
                    {display.flowType !== FLOW_TYPE.ONLINE && (
                      <Form.Item
                        label="Gift Date"
                        name="giftDate"
                        rules={[
                          {
                            required: true,
                            message: "Gift Date is required!",
                          },
                        ]}
                      >
                        <DatePicker />
                      </Form.Item>
                    )}
                    <Form.Item label="Notes" name="comment">
                      <TextArea placeholder="Comment" />
                    </Form.Item>
                  </Panel>
                )}
            </Collapse>
            <Form.Item wrapperCol={{ offset: 8, span: 8 }}>
              {!isPending && (
                <Button type="primary" htmlType="submit">
                  {formDisplay.submitButton}
                </Button>
              )}
              {isPending && (
                <Button type="primary" disabled>
                  Processing...
                </Button>
              )}
            </Form.Item>
          </Form>
        </>
      )}
    </div>
  );
};
