import { Button, Space, Spin } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { BackButton } from "../../components/BackButton";
import { Exception } from "../../components/Exception";
import { useCacheContext } from "../../hooks/useCacheContext";
import { Deposit } from "../../types";
import { CommonUtils } from "../../utils/CommonUtils";

type Display = {
  fromPage?: string;
};

export const PrintDeposit = () => {
  const [deposit, setDeposit] = useState<Deposit | null>(null);

  const { envInfo } = useCacheContext();

  const [error, setError] = useState<string | null>(null);
  const [isPending, setIsPending] = useState(false);

  const { state }: { state: any } = useLocation();
  const display: Display = state;
  const { id } = useParams();

  const { formatCurrency, formatDate } = CommonUtils();

  const printDivCallBack = useCallback(() => {
    const printDiv = document.getElementById("printDiv");
    if (printDiv) {
      const printWindow = window.open(
        "",
        "",
        "left=0,top=0,width=900,height=900,toolbar=0,scrollbars=0,status=0"
      );
      if (printWindow) {
        printWindow.document.write(printDiv.innerHTML);
        printWindow.document.close();
        printWindow.focus();
        printWindow.print();
        printWindow.close();
      }
    }
  }, []);

  useEffect(() => {
    setIsPending(true);

    async function loadDeposit() {
      const { fetchFc } = CommonUtils();

      try {
        const deposit = await fetchFc<Deposit>(
          `/deposits/${id}?includeDetails=true`,
          "GET"
        );
        setDeposit(deposit);

        setIsPending(false);

        setTimeout(() => {
          printDivCallBack();
        }, 500);
      } catch (err) {
        setError((err as Error).message);
        setIsPending(false);
      }
    }

    loadDeposit();
  }, [id, printDivCallBack]);

  return (
    <div>
      {isPending && (
        <div className="loading">
          <Spin size="large" />
        </div>
      )}
      {!isPending && !deposit && error && (
        <Exception exceptionDetails={error} />
      )}
      {!isPending && deposit && (
        <>
          <Space>
            <BackButton
              buttonText={
                display.fromPage === "search"
                  ? "Back to search page"
                  : "Back to details page"
              }
            />
            <Button onClick={printDivCallBack}>Print Deposit</Button>
          </Space>
          <div id="printDiv" style={{ width: "900px", margin: "20px" }}>
            <div style={{ float: "left", width: "370px" }}>
              <div>
                <span style={{ fontWeight: "bold", fontSize: "large" }}>
                  CREDIT ACCOUNT OF:
                </span>
                <br />
                {envInfo?.isAkfc && (
                  <span style={{ fontSize: "large" }}>
                    HIS HIGHNESS PRINCE
                    <br />
                    AGA KHAN COUNCIL FOR CANADA - JSF A/C
                    <br />
                    02022 002 0053112
                  </span>
                )}
                {!envInfo?.isAkfc && (
                  <span style={{ fontSize: "large" }}>
                    FOCUS HUMANITARIAN ASSISTANCE CANADA
                    <br />
                    HEAD OFFICE
                    <br />
                    06142 003 1010388
                  </span>
                )}
                <br />
              </div>
              <div style={{ marginTop: "30px", textAlign: "right" }}>
                <span style={{ fontWeight: "bold", fontSize: "medium" }}>
                  SERIAL NUMBER
                </span>
                <br />
                <span style={{ fontWeight: "bold", fontSize: "medium" }}>
                  {deposit.number}
                </span>
              </div>
              <div style={{ marginTop: "30px" }}>
                <table>
                  <thead>
                    <tr>
                      <th colSpan={2}>LIST OF CHECKS</th>
                    </tr>
                    <tr>
                      <th>PARTICULARS</th>
                      <th>CHECK AMOUNT</th>
                    </tr>
                  </thead>
                  <tbody>
                    {deposit.allChecks &&
                      deposit.allChecks.map((c) => {
                        return (
                          <tr key={c.id}>
                            <td>{c.checkNumber}</td>
                            <td style={{ textAlign: "right" }}>
                              {formatCurrency(c.checkAmount as number)}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td style={{ textAlign: "right" }}>CHEQUE SUBTOTAL:</td>
                      <td style={{ textAlign: "right" }}>
                        {formatCurrency(deposit.totalChequeAmount)}
                      </td>
                    </tr>
                  </tfoot>
                </table>
                <br />
              </div>
              <div>
                <table>
                  <thead>
                    <tr>
                      <th colSpan={4}>Pledge Forms</th>
                    </tr>
                    <tr>
                      <th>Item</th>
                      <th>Form Number</th>
                      <th>Amount</th>
                      <th>Payment Method</th>
                    </tr>
                  </thead>
                  <tbody>
                    {deposit.formList &&
                      deposit.formList.map((f, index) => {
                        return (
                          <tr key={f.id}>
                            <td style={{ textAlign: "center" }}>{index + 1}</td>
                            <td style={{ textAlign: "center" }}>
                              {f.formNumber}
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {formatCurrency(f.amount as number)}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              {f.paymentMethod}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
                <br />
              </div>
            </div>
            <div style={{ marginLeft: "400px" }}>
              <div id="depositInfo">
                <span style={{ fontSize: "medium" }}>
                  BUSINESS ACCOUNT DEPOSIT RECORD
                </span>
                <br />
                {envInfo?.isAkfc && (
                  <>
                    <span style={{ fontSize: "large" }}>
                      THE BANK OF NOVA SCOTIA
                    </span>
                    <br />
                    <span style={{ fontWeight: "bold" }}>
                      www.scotiabank.com 1-800-4-SCOTIA
                    </span>
                    <br />
                    <br />
                    <span>885 LAWRENCE AVENUE EAST</span>
                    <br />
                    <span>TORONTO, ONTARIO, M3C 1P7</span>
                    <br />
                    <br />
                    <span style={{ fontSize: "medium" }}>
                      CREDIT ACCOUNT OF:
                    </span>
                    <br />
                    <span style={{ fontSize: "large" }}>
                      HIS HIGHNESS PRINCE
                    </span>
                    <br />
                    <span style={{ fontSize: "large" }}>
                      AGA KHAN COUNCIL FOR CANADA - JSF A/C
                    </span>
                    <br />
                    <span style={{ fontSize: "large" }}>02022 002 0053112</span>
                  </>
                )}
                {!envInfo?.isAkfc && (
                  <>
                    <span style={{ fontSize: "large" }}>
                      ROYAL BANK OF CANADA
                    </span>
                    <br />
                    <span style={{ fontWeight: "bold" }}>
                      www.rbcroyalbank.com 1-800-769-2511
                    </span>
                    <br />
                    <br />
                    <span>10090 DON MILLS ROAD</span>
                    <br />
                    <span>NORTH YORK, ONTARIO, M3C 3R6</span>
                    <br />
                    <br />
                    <span style={{ fontSize: "medium" }}>
                      CREDIT ACCOUNT OF:
                    </span>
                    <br />
                    <span style={{ fontSize: "large" }}>
                      FOCUS HUMANITARIAN ASSISTANCE CANADA
                    </span>
                    <br />
                    <span style={{ fontSize: "large" }}>HEAD OFFICE</span>
                    <br />
                    <span style={{ fontSize: "large" }}>06142 003 1010388</span>
                  </>
                )}
                <br />
                <br />
                <table style={{ border: "none" }}>
                  <tbody>
                    <tr>
                      <td>DATE:</td>
                      <td>{formatDate(deposit.date as string, true)}</td>
                    </tr>
                    <tr>
                      <td>DEPOSITED BY:</td>
                      <td>{deposit.submittedBy}</td>
                    </tr>
                    <tr>
                      <td>REFERENCE NUMBER:</td>
                      <td>___________________</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              {deposit.cashMap && (
                <table
                  style={{
                    marginRight: "30px",
                    marginTop: "30px",
                    border: "none",
                  }}
                >
                  <tbody>
                    <tr>
                      <td style={{ paddingBottom: "10px" }}>X5</td>
                      <td>{deposit.cashMap[500]}</td>
                    </tr>
                    <tr>
                      <td style={{ paddingBottom: "10px" }}>X10</td>
                      <td>{deposit.cashMap[1000]}</td>
                    </tr>
                    <tr>
                      <td style={{ paddingBottom: "10px" }}>X20</td>
                      <td>{deposit.cashMap[2000]}</td>
                    </tr>
                    <tr>
                      <td style={{ paddingBottom: "10px" }}>X50</td>
                      <td>{deposit.cashMap[5000]}</td>
                    </tr>
                    <tr>
                      <td style={{ paddingBottom: "10px" }}>X100</td>
                      <td>{deposit.cashMap[10000]}</td>
                    </tr>
                    <tr>
                      <td style={{ paddingBottom: "10px" }}>X</td>
                      <td>_______________</td>
                    </tr>
                    <tr>
                      <td style={{ paddingBottom: "10px" }}>U.S. CASH</td>
                      <td>_______________</td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "bold",
                          fontSize: "medium",
                          paddingBottom: "10px",
                        }}
                      >
                        TOTAL CASH
                      </td>
                      <td>{formatCurrency(deposit.totalCashBillAmount)}</td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "bold",
                          fontSize: "medium",
                          paddingBottom: "10px",
                        }}
                      >
                        COIN
                      </td>
                      <td>{formatCurrency(deposit.totalCashCoinAmount)}</td>
                    </tr>
                    <tr>
                      <td style={{ paddingBottom: "10px" }}>
                        SUB TOTAL CASH &amp; COIN
                      </td>
                      <td>{formatCurrency(deposit.totalCashAmount)}</td>
                    </tr>
                    <tr>
                      <td style={{ paddingBottom: "10px" }}>TOTAL CHEQUES</td>
                      <td>{formatCurrency(deposit.totalChequeAmount)}</td>
                    </tr>
                    <tr>
                      <td style={{ paddingBottom: "10px" }}>U.S. CHEQUES</td>
                      <td>_______________</td>
                    </tr>
                    <tr>
                      <td style={{ paddingBottom: "10px" }}>
                        TOTAL NUMBER OF ITEMS
                      </td>
                      <td>_______________</td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontWeight: "bold",
                          fontSize: "medium",
                          paddingBottom: "10px",
                        }}
                      >
                        TOTAL DEPOSIT
                      </td>
                      <td>{formatCurrency(deposit.amount as number)}</td>
                    </tr>
                  </tbody>
                </table>
              )}
            </div>
            <hr style={{ clear: "both" }} />
            <div style={{ marginTop: "30px" }}>
              <table>
                <tbody>
                  <tr>
                    <td style={{ paddingBottom: "10px" }}>
                      Name and signature of Volunteer / Staff
                    </td>
                    <td>__________________________________________________</td>
                  </tr>
                  <tr>
                    <td>Date</td>
                    <td>__________________________________________________</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
