import { MinusCircleOutlined } from "@ant-design/icons";
import {
  Button,
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Space,
  Spin,
  Tooltip,
  Typography,
} from "antd";
import { Rule } from "antd/lib/form";
import { FormListFieldData } from "antd/lib/form/FormList";
import { useCallback, useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Exception } from "../../components/Exception";
import { JkSelect } from "../../components/JkSelect";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useCacheContext } from "../../hooks/useCacheContext";
import { ConfirmDetails, Deposit, FLOW_TYPE, Pledge, User } from "../../types";
import { CommonUtils } from "../../utils/CommonUtils";

type DepositDisplay = {
  header: string;
  submitButton: string;
};

type PageState = {
  depositDisplay: DepositDisplay | undefined;
  error: string | null;
  isPending: boolean;
  deposit: Deposit;
};

const initialPageState: PageState = {
  depositDisplay: undefined,
  error: null,
  isPending: false,
  deposit: {},
};

export const EditDeposit = () => {
  const { state }: { state: any } = useLocation();
  const display: Deposit = state;

  const [pageState, setPageState] = useState<PageState>(initialPageState);

  const [formInstance] = Form.useForm();

  const { user: _sessionUser } = useAuthContext();
  const sessionUser = useRef<User>(_sessionUser).current as User;

  const { isCacheReady, envInfo } = useCacheContext();

  const { Title } = Typography;
  const { TextArea } = Input;
  const navigate = useNavigate();

  const { fetchFc, pdcCollection, validateDepositNumber } = CommonUtils();

  const isDepositActionNotAuthorized = useCallback(
    (deposit: Deposit): string | undefined => {
      if (!sessionUser.auth?.canCreateDeposit) {
        return "Not authorized";
      }

      if (deposit.date) {
        return `Deposit # ${deposit.number} is already submitted`;
      }

      if (
        sessionUser.auth?.isDsRegionalConvenor &&
        sessionUser.region?.id !== deposit.jamatKhana?.region?.id
      ) {
        return "Not authorized";
      }

      if (
        sessionUser.auth.isDsJkConvenor &&
        sessionUser.jamatKhana?.id !== deposit.jamatKhana?.id
      ) {
        return "Not authorized";
      }
    },
    [sessionUser]
  );

  useEffect(() => {
    async function loadDeposit() {
      setPageState((prevState) => {
        return { ...prevState, isPending: true, error: null };
      });

      const { fetchFc } = CommonUtils();

      try {
        const deposit = await fetchFc<Deposit>(
          `/deposits/${display.id}`,
          "GET"
        );

        const isNotAuthorized = isDepositActionNotAuthorized(deposit);
        if (isNotAuthorized) {
          setPageState((prevState) => {
            return {
              ...prevState,
              isPending: false,
              error: isNotAuthorized,
              deposit: {},
            };
          });
        } else {
          if (deposit.formList) {
            const { formatCurrency } = CommonUtils();
            deposit.formList.forEach((f) => {
              f.amount = formatCurrency(f.amount as number);
            });
          } else {
            deposit.formList = [];
          }

          deposit.formList.push({ formNumber: undefined });
          setPageState((prevState) => {
            return {
              ...prevState,
              isPending: false,
              deposit,
            };
          });
          formInstance.setFieldsValue({ ...deposit });
        }
      } catch (err) {
        const errorMessage = (err as Error).message;
        setPageState((prevState) => {
          return { ...prevState, error: errorMessage, isPending: false };
        });
      }
    }

    if (display.id) {
      loadDeposit();
    } else {
      if (sessionUser.jamatKhana) {
        formInstance.setFieldsValue({
          jamatKhana: sessionUser.jamatKhana,
          formList: [{ formNumber: undefined }],
        });
        setPageState((prevState) => {
          return {
            ...prevState,
            deposit: { jamatKhana: sessionUser.jamatKhana },
          };
        });
      }
    }
  }, [display.id, sessionUser, formInstance, isDepositActionNotAuthorized]);

  useEffect(() => {
    let formDisplay: DepositDisplay;
    if (display.flowType === FLOW_TYPE.CREATE) {
      formDisplay = {
        header: "Create Deposit",
        submitButton: "Create Deposit",
      };
    } else {
      formDisplay = {
        header: "Edit Deposit",
        submitButton: "Submit Deposit",
      };
    }

    formInstance.setFieldValue("formList", [{ formNumber: undefined }]);

    setPageState((prevState) => {
      return {
        ...prevState,
        depositDisplay: formDisplay,
        deposit: {
          jamatKhana: prevState.deposit.jamatKhana,
        },
      };
    });
  }, [display, formInstance]);

  const onFinish = async (depositInput: Deposit) => {
    setPageState((prevState) => {
      return { ...prevState, isPending: true, error: null };
    });

    try {
      if (!depositInput.number || depositInput.number.length !== 12) {
        setPageState((prevState) => {
          return {
            ...prevState,
            isPending: false,
            error: "Deposit Number is not valid",
          };
        });

        return;
      }

      const input: Deposit = {
        ...pageState.deposit,
        ...depositInput,
        formList: [],
      };

      let result: Deposit = {};
      if (display.id) {
        if (
          !pageState.deposit.formList ||
          pageState.deposit.formList.length === 0
        ) {
          setPageState((prevState) => {
            return {
              ...prevState,
              isPending: false,
              error: "No forms added to the Deposit",
            };
          });
          return;
        }

        result = await fetchFc<Deposit>(
          `/deposits/submit/${input.id}`,
          "POST",
          input
        );
      }

      const confirmDetails: ConfirmDetails = {
        title: pageState.depositDisplay?.header + " Successful",
        subTitle: `${result.number} successfully submitted`,
      };

      navigate("/confirm", { state: confirmDetails });
    } catch (err) {
      const errorMessage = (err as Error).message;
      setPageState((prevState) => {
        return { ...prevState, error: errorMessage, isPending: false };
      });
    }
  };

  const onValuesChange = (changedValues: any, values: Deposit) => {
    if (changedValues.jamatKhana) {
      formInstance.setFieldsValue({
        formList: [{ formNumber: undefined }],
      });

      setPageState((prevState) => {
        return {
          ...prevState,
          deposit: { jamatKhana: changedValues.jamatKhana },
        };
      });
    }
  };

  const handleAuditForm = async (
    e: React.MouseEvent,
    field: FormListFieldData
  ) => {
    e.preventDefault();
    setPageState((prevState) => {
      return { ...prevState, isPending: true, error: null };
    });

    try {
      const depositNumber = formInstance.getFieldValue("number");
      if (
        !envInfo?.isAkfc &&
        !display.id &&
        formInstance.getFieldError("number").length > 0
      ) {
        setPageState((prevState) => {
          return {
            ...prevState,
            isPending: false,
            error: "Deposit Number is required for Focus",
          };
        });
        return;
      }

      const formList = formInstance.getFieldValue("formList") as Pledge[];
      const searchForm = formList[field.name];

      let result: Pledge[] = [];
      try {
        result = await fetchFc<Pledge[]>(
          `/pledges?formNumber=${searchForm.formNumber}`,
          "GET"
        );
      } catch (err) {
        const errorMessage = (err as Error).message;
        setPageState((prevState) => {
          return { ...prevState, error: errorMessage, isPending: false };
        });
        return;
      }

      if (result.length === 0) {
        setPageState((prevState) => {
          return {
            ...prevState,
            isPending: false,
            error: `Form Number: ${searchForm.formNumber} not found`,
          };
        });
        return;
      }

      if (result.length > 1) {
        setPageState((prevState) => {
          return {
            ...prevState,
            isPending: false,
            error: `Multiple forms found for Form Number: ${searchForm.formNumber}. Please contact the system administrators.`,
          };
        });
        return;
      }
      const form = result[0];

      if (form.jamatKhana?.id !== pageState.deposit.jamatKhana?.id) {
        setPageState((prevState) => {
          return {
            ...prevState,
            isPending: false,
            error: `Form Number: ${searchForm.formNumber} does not belong to ${pageState.deposit.jamatKhana?.name} JK`,
          };
        });
        return;
      }

      if (!form.giftDate) {
        setPageState((prevState) => {
          return {
            ...prevState,
            isPending: false,
            error: `Form Number: ${searchForm.formNumber} has not been entered yet`,
          };
        });
        return;
      }

      if (form.voidDate) {
        setPageState((prevState) => {
          return {
            ...prevState,
            isPending: false,
            error: `Form Number: ${searchForm.formNumber} has been voided`,
          };
        });
        return;
      }

      if (form.dateLost) {
        setPageState((prevState) => {
          return {
            ...prevState,
            isPending: false,
            error: `Form Number: ${searchForm.formNumber} has been marked as lost`,
          };
        });
        return;
      }

      if (form.deposit) {
        setPageState((prevState) => {
          return {
            ...prevState,
            isPending: false,
            error: `Form Number: ${searchForm.formNumber} is already a part of Deposit Number ${form.deposit?.number}`,
          };
        });
        return;
      }

      if (form.paymentType && pdcCollection.includes(form.paymentType)) {
        setPageState((prevState) => {
          return {
            ...prevState,
            isPending: false,
            error: `Form Number: ${searchForm.formNumber} is Post Dated Cheque and cannot be part of the Bank Deposit`,
          };
        });
        return;
      }

      if (!display.id) {
        try {
          // create deposit
          const deposit = await fetchFc<Deposit>(`/deposits`, "POST", {
            jamatKhana: { id: pageState.deposit.jamatKhana?.id },
            number: depositNumber,
          });
          pageState.deposit = deposit;
        } catch (err) {
          const errorMessage = (err as Error).message;
          setPageState((prevState) => {
            return { ...prevState, error: errorMessage, isPending: false };
          });
          return;
        }
      }

      navigate("/editPledge", {
        state: {
          flowType: FLOW_TYPE.AUDIT,
          deposit: { id: pageState.deposit.id },
          position: field.name,
          id: form.id,
        },
      });
    } catch (err) {
      const errorMessage = (err as Error).message;
      setPageState((prevState) => {
        return { ...prevState, error: errorMessage, isPending: false };
      });
    }
  };

  const isFormInDeposit = (field: FormListFieldData): boolean => {
    const formList = formInstance.getFieldValue("formList") as Pledge[];
    const form = formList[field.name];
    return form.id ? true : false;
  };

  const removeFormFromDeposit = async (field: FormListFieldData) => {
    setPageState((prevState) => {
      return { ...prevState, isPending: true, error: null };
    });

    try {
      const formList = formInstance.getFieldValue("formList") as Pledge[];
      const form = formList[field.name];
      const result = await fetchFc<Deposit>(
        `/pledges/removeFromDeposit/${form.id}`,
        "POST"
      );

      const newFormList = result.formList ? result.formList : [];
      newFormList.push({ formNumber: undefined });
      formInstance.setFieldsValue({ formList: newFormList });

      setPageState((prevState) => {
        return { ...prevState, deposit: result, isPending: false };
      });
    } catch (err) {
      const errorMessage = (err as Error).message;
      setPageState((prevState) => {
        return { ...prevState, error: errorMessage, isPending: false };
      });
    }
  };

  const depositNumValidator = async (_: Rule, value: string) => {
    validateDepositNumber(value);
    return Promise.resolve();
  };

  return (
    <div>
      {(pageState.isPending || !isCacheReady) && (
        <div className="loading">
          <Spin size="large" />
        </div>
      )}
      {!pageState.isPending &&
        pageState.error &&
        !pageState.depositDisplay &&
        isCacheReady && <Exception exceptionDetails={pageState.error} />}
      {!pageState.isPending && pageState.depositDisplay && isCacheReady && (
        <>
          <Title className="text-center">
            {pageState.depositDisplay.header}
          </Title>
          {pageState.error && <Exception exceptionDetails={pageState.error} />}
          <Form
            form={formInstance}
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 8 }}
            autoComplete="off"
            onFinish={onFinish}
            onValuesChange={onValuesChange}
            initialValues={{
              number: pageState.deposit.number,
              jamatKhana: {
                id:
                  pageState.deposit.jamatKhana?.id ||
                  sessionUser.jamatKhana?.id,
                region: {
                  id:
                    pageState.deposit.jamatKhana?.region?.id ||
                    sessionUser.region?.id,
                },
              },
              comment: pageState.deposit.comment,
              formList: pageState.deposit.formList,
            }}
          >
            <JkSelect
              form={formInstance}
              isRequired={true}
              isDisabled={display.id ? true : false}
            />
            {(display.id || !envInfo?.isAkfc) && (
              <Form.Item label="Number" required>
                <Space>
                  <Form.Item
                    noStyle
                    name="number"
                    rules={[
                      {
                        required: true,
                        validator: depositNumValidator,
                      },
                    ]}
                  >
                    <Input disabled={display.id ? true : false} />
                  </Form.Item>
                  {display.id && pageState.deposit.formList?.length === 1 && (
                    <Button type="primary" danger>
                      <Link
                        to="/editDeposit"
                        state={{ flowType: FLOW_TYPE.CREATE }}
                      >
                        Reset
                      </Link>
                    </Button>
                  )}
                </Space>
              </Form.Item>
            )}
            {(display.id || pageState.deposit.jamatKhana) && (
              <Form.Item label="Forms">
                <Form.List name="formList">
                  {(fields) => (
                    <>
                      {fields.map((field) => (
                        <Space
                          key={field.key}
                          style={{ display: "flex", marginBottom: 6 }}
                          align="baseline"
                        >
                          <Form.Item
                            {...field}
                            name={[field.name, "formNumber"]}
                          >
                            <InputNumber
                              placeholder="Form Number"
                              style={{ minWidth: 160 }}
                              disabled={isFormInDeposit(field)}
                              controls={false}
                            />
                          </Form.Item>
                          {isFormInDeposit(field) && (
                            <>
                              <Form.Item
                                {...field}
                                name={[field.name, "amount"]}
                              >
                                <InputNumber
                                  placeholder="Form Amount"
                                  style={{ minWidth: 140 }}
                                  disabled
                                />
                              </Form.Item>
                              <Popconfirm
                                title="Remove Form"
                                onConfirm={() => removeFormFromDeposit(field)}
                                okButtonProps={{
                                  loading: pageState.isPending,
                                }}
                              >
                                <Tooltip placement="right" title="Remove Form">
                                  <MinusCircleOutlined />
                                </Tooltip>
                              </Popconfirm>
                            </>
                          )}
                          {!isFormInDeposit(field) && (
                            <Form.Item>
                              <Button
                                type="primary"
                                htmlType="submit"
                                onClick={(e) => handleAuditForm(e, field)}
                              >
                                Audit Form
                              </Button>
                            </Form.Item>
                          )}
                        </Space>
                      ))}
                    </>
                  )}
                </Form.List>
              </Form.Item>
            )}
            {display.id && (
              <>
                <Form.Item label="Notes" name="comment">
                  <TextArea placeholder="Comment" />
                </Form.Item>

                <Form.Item wrapperCol={{ offset: 8, span: 8 }}>
                  {!pageState.isPending && (
                    <Button
                      type="primary"
                      htmlType="submit"
                      disabled={
                        !pageState.deposit.formList ||
                        pageState.deposit.formList.length === 0
                          ? true
                          : false
                      }
                    >
                      {pageState.depositDisplay.submitButton}
                    </Button>
                  )}
                  {pageState.isPending && (
                    <Button type="primary" disabled>
                      Processing...
                    </Button>
                  )}
                </Form.Item>
              </>
            )}
          </Form>
        </>
      )}
    </div>
  );
};
