import { MinusCircleOutlined } from "@ant-design/icons";
import {
  Button,
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Space,
  Spin,
  Tooltip,
  Typography,
} from "antd";
import { FormListFieldData } from "antd/lib/form/FormList";
import { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Exception } from "../../components/Exception";
import { JkSelect } from "../../components/JkSelect";
import { useAuthContext } from "../../hooks/useAuthContext";
import { Batch, ConfirmDetails, FLOW_TYPE, Pledge, User } from "../../types";
import { CommonUtils } from "../../utils/CommonUtils";
import { useCacheContext } from "../../hooks/useCacheContext";

type PageState = {
  error: string | null;
  isPending: boolean;
  batch: Batch;
};

const initialPageState: PageState = {
  error: null,
  isPending: false,
  batch: {},
};

type FORM_DISPLAY = {
  header: string;
  submitButton: string;
};

export const CreateBatch = () => {
  const { state }: { state: any } = useLocation();
  const display: Batch = state;

  const navigate = useNavigate();

  const { user: _sessionUser } = useAuthContext();
  const sessionUser = useRef<User>(_sessionUser).current as User;

  const [pageState, setPageState] = useState<PageState>(initialPageState);
  const [formDisplay, setFormDisplay] = useState<FORM_DISPLAY | null>(null);

  const { envInfo } = useCacheContext();

  const [formInstance] = Form.useForm();

  const { Title } = Typography;
  const { TextArea } = Input;

  const { getUserJk } = CommonUtils();

  const fetchFormsToBeBatched = useCallback(
    async (jkId: number) => {
      setPageState((prevState) => {
        return { ...prevState, error: null, isPending: true };
      });

      const { fetchFc, formatCurrency, formatDate } = CommonUtils();

      try {
        const unused = display.flowType === FLOW_TYPE.UNUSED;
        const pledges = await fetchFc<Pledge[]>(
          `/pledges/batch/${jkId}?unused=${unused}`,
          "GET"
        );

        pledges.forEach((f) => {
          f.amount = formatCurrency(f.amount as number);
          f.giftDate = formatDate(f.giftDate as string);
        });

        if (pledges && pledges.length === 0) {
          setPageState((prevState) => {
            return {
              ...prevState,
              isPending: false,
              error: "No Pledges found for batch",
              batch: {},
            };
          });

          return;
        }

        setPageState((prevState) => {
          return {
            ...prevState,
            isPending: false,
            error: null,
            batch: { jamatKhana: { id: jkId }, formList: pledges },
          };
        });

        if (formInstance.__INTERNAL__) {
          formInstance.setFieldsValue({ formList: pledges });
        }
      } catch (err) {
        const errorMessage = (err as Error).message;
        setPageState((prevState) => {
          return { ...prevState, error: errorMessage, isPending: false };
        });
      }
    },
    [display.flowType, formInstance]
  );

  useEffect(() => {
    if (display.flowType === FLOW_TYPE.UNUSED) {
      setFormDisplay({
        header: envInfo!.messagesMap["main.returnUnusedPledges"],
        submitButton: "Create Unused Batch",
      });
    } else {
      setFormDisplay({
        header: "Create Batch",
        submitButton: "Create Batch",
      });
    }

    if (sessionUser.jamatKhana) {
      fetchFormsToBeBatched(sessionUser.jamatKhana.id);
    }
  }, [
    sessionUser,
    fetchFormsToBeBatched,
    formInstance,
    display.flowType,
    envInfo,
  ]);

  const removeFormFromDeposit = async (field: FormListFieldData) => {
    setPageState((prevState) => {
      return { ...prevState, isPending: true, error: null };
    });

    try {
      const formList = formInstance.getFieldValue("formList") as Pledge[];
      formList.splice(field.name, 1);
      formInstance.setFieldsValue({ formList });

      setPageState((prevState) => {
        return {
          ...prevState,
          batch: { jamatKhana: prevState.batch.jamatKhana, formList },
          isPending: false,
        };
      });
    } catch (err) {
      const errorMessage = (err as Error).message;
      setPageState((prevState) => {
        return { ...prevState, error: errorMessage, isPending: false };
      });
    }
  };

  const onFinish = async (batchInput: Batch) => {
    setPageState((prevState) => {
      return { ...prevState, isPending: true, error: null };
    });

    if (!batchInput.formList || batchInput.formList.length === 0) {
      setPageState((prevState) => {
        return {
          ...prevState,
          isPending: false,
          error: "No forms added to the Batch",
        };
      });
      return;
    }

    const { fetchFc } = CommonUtils();

    try {
      const input: Pledge[] = [];
      batchInput.formList.forEach((f) => {
        input.push({ id: f.id });
      });
      batchInput.formList = input;

      const result = await fetchFc<Batch>(`/batches`, "POST", batchInput);

      const confirmDetails: ConfirmDetails = {
        title: "Successfully created batch",
        subTitle: `${result.name} successfully created`,
      };

      navigate("/confirm", { state: confirmDetails });
    } catch (err) {
      const errorMessage = (err as Error).message;
      setPageState((prevState) => {
        return { ...prevState, error: errorMessage, isPending: false };
      });
    }
  };

  const onValuesChange = (changedValues: any, values: Batch) => {
    if (changedValues.jamatKhana) {
      fetchFormsToBeBatched(changedValues.jamatKhana.id);
    }
  };

  return (
    <div>
      {pageState.isPending && (
        <div className="loading">
          <Spin size="large" />
        </div>
      )}
      {!pageState.isPending && formDisplay && (
        <>
          <Title className="text-center">{formDisplay.header}</Title>
          {pageState.error && <Exception exceptionDetails={pageState.error} />}
          <Form
            form={formInstance}
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 8 }}
            autoComplete="off"
            onFinish={onFinish}
            onValuesChange={onValuesChange}
            initialValues={{
              ...pageState.batch,
              jamatKhana: getUserJk(sessionUser),
            }}
          >
            <JkSelect form={formInstance} isRequired={true} />
            {pageState.batch.jamatKhana && (
              <>
                <Title level={3} className="text-center">
                  Forms ready to be batched
                </Title>
                <Form.Item
                  style={
                    display.flowType === FLOW_TYPE.CREATE
                      ? { marginLeft: "20%" }
                      : { marginLeft: "50%" }
                  }
                >
                  <Form.List name="formList">
                    {(fields) => (
                      <>
                        {fields.map((field) => (
                          <Space
                            key={field.key}
                            style={{ display: "flex", marginBottom: 6 }}
                            align="baseline"
                          >
                            <Form.Item
                              {...field}
                              name={[field.name, "formNumber"]}
                            >
                              <InputNumber
                                placeholder="Form Number"
                                style={{ minWidth: 120 }}
                                disabled={true}
                                controls={false}
                              />
                            </Form.Item>
                            {display.flowType === FLOW_TYPE.CREATE && (
                              <>
                                <Form.Item
                                  {...field}
                                  name={[field.name, "giftDate"]}
                                >
                                  <Input
                                    placeholder="Gift Date"
                                    style={{ minWidth: 170 }}
                                    disabled={true}
                                  />
                                </Form.Item>
                                <Form.Item
                                  {...field}
                                  name={[field.name, "batchStatusForPrint"]}
                                >
                                  <Input
                                    placeholder="Status"
                                    style={{ minWidth: 160 }}
                                    disabled={true}
                                  />
                                </Form.Item>
                                <Form.Item
                                  {...field}
                                  name={[field.name, "amount"]}
                                >
                                  <InputNumber
                                    placeholder="Form Amount"
                                    style={{ minWidth: 120 }}
                                    disabled
                                  />
                                </Form.Item>
                              </>
                            )}
                            <Popconfirm
                              title="Remove Form"
                              onConfirm={() => removeFormFromDeposit(field)}
                              okButtonProps={{
                                loading: pageState.isPending,
                              }}
                            >
                              <Tooltip placement="right" title="Remove Form">
                                <MinusCircleOutlined />
                              </Tooltip>
                            </Popconfirm>
                          </Space>
                        ))}
                      </>
                    )}
                  </Form.List>
                </Form.Item>
                <Form.Item label="Notes" name="comment">
                  <TextArea placeholder="Comment" />
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 8, span: 8 }}>
                  {!pageState.isPending && (
                    <Button
                      type="primary"
                      htmlType="submit"
                      disabled={
                        !pageState.batch.formList ||
                        pageState.batch.formList.length === 0
                          ? true
                          : false
                      }
                    >
                      {formDisplay.submitButton}
                    </Button>
                  )}
                  {pageState.isPending && (
                    <Button type="primary" disabled>
                      Processing...
                    </Button>
                  )}
                </Form.Item>
              </>
            )}
          </Form>
        </>
      )}
    </div>
  );
};
