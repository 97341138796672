import { Button, Form, Input, Spin, Typography } from "antd";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { BackButton } from "../../components/BackButton";
import { Exception } from "../../components/Exception";
import { useCacheContext } from "../../hooks/useCacheContext";
import { ConfirmDetails, Pledge } from "../../types";
import { CommonUtils } from "../../utils/CommonUtils";

export const SendPledgeAcknowledgement = () => {
  const { state }: { state: any } = useLocation();
  const display: Pledge = state;

  const [pledge, setPledge] = useState<Pledge>({});

  const [error, setError] = useState<string | null>(null);
  const [isPending, setIsPending] = useState(false);

  const { envInfo } = useCacheContext();

  const [formInstance] = Form.useForm();
  const { Title } = Typography;
  const { TextArea } = Input;
  const navigate = useNavigate();

  const { fetchFc } = CommonUtils();

  useEffect(() => {
    if (!envInfo) {
      return;
    }

    const { fetchFc } = CommonUtils();

    if (!display.id) {
      setError("Pledge ID not provided");
      return;
    }

    const loadForm = async () => {
      setError(null);
      setIsPending(true);

      try {
        const form = await fetchFc<Pledge>(`/pledges/${display.id}`, "GET");
        setPledge(form);
        setIsPending(false);
      } catch (err) {
        setError((err as Error).message);
        setIsPending(false);
      }
    };

    loadForm();
  }, [display, envInfo]);

  const onFinish = async (formInput: Pledge) => {
    setError(null);

    try {
      setIsPending(true);
      await fetchFc<Pledge>(
        `/pledges/sendPledgeAcknowledgement/${display.id}`,
        "POST",
        { ...formInput, id: display.id }
      );

      const confirmDetails: ConfirmDetails = {
        title: "Pledge Acknowledgement Sent",
      };

      navigate("/confirm", { state: confirmDetails });
    } catch (err) {
      const errorMessage = (err as Error).message;
      setError(errorMessage);
      setIsPending(false);
    }
  };

  return (
    <div>
      {isPending && (
        <div className="loading">
          <Spin size="large" />
        </div>
      )}
      {!isPending && (
        <>
          <BackButton buttonText="Back to search page" />
          <Title className="text-center">Send Pledge Acknowledgement</Title>
          {error && <Exception exceptionDetails={error} />}
          <Form
            form={formInstance}
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 8 }}
            autoComplete="off"
            onFinish={onFinish}
            scrollToFirstError={true}
            initialValues={{ ...pledge }}
          >
            {pledge.formNumber && (
              <Form.Item label="Form Number" name="formNumber">
                <Input disabled />
              </Form.Item>
            )}
            {pledge.reGiftId && (
              <Form.Item label="RE Gift ID" name="reGiftId">
                <Input disabled />
              </Form.Item>
            )}
            <Form.Item label="Donor" name={["donor", "fullName"]}>
              <Input disabled />
            </Form.Item>
            <Form.Item label="Donor" name={["donor", "email"]}>
              <Input />
            </Form.Item>
            <Form.Item label="Reason" name="comment">
              <TextArea placeholder="Reason" />
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 8, span: 8 }}>
              {!isPending && (
                <Button type="primary" htmlType="submit">
                  Send Pledge Acknowledgement
                </Button>
              )}
              {isPending && (
                <Button type="primary" disabled>
                  Processing...
                </Button>
              )}
            </Form.Item>
          </Form>
        </>
      )}
    </div>
  );
};
