import { Form, Select } from "antd";
import { NamePath } from "antd/lib/form/interface";
import { useEffect, useRef, useState } from "react";
import { useAuthContext } from "../hooks/useAuthContext";
import { useCacheContext } from "../hooks/useCacheContext";
import { JamatKhana, User } from "../types";
import { CommonUtils } from "../utils/CommonUtils";

type JkSelectInput = {
  value?: number;
  onChange?: (jkId: number) => void;
  form: any;
  isRestricted?: boolean;
  isRequired?: boolean;
  showAll?: boolean;
  namePath?: NamePath;
  label?: string;
  isDisabled?: boolean;
};

export const JkSelect: React.FC<JkSelectInput> = ({
  value,
  onChange,
  form,
  isRestricted = true,
  isRequired = false,
  showAll = false,
  namePath = ["jamatKhana", "id"],
  label = "Jamat Khana",
  isDisabled = false,
}) => {
  const { Option } = Select;

  const { user: _sessionUser } = useAuthContext();
  const sessionUser = useRef<User>(_sessionUser).current as User;

  const { jks, displayJks, isCacheReady } = useCacheContext();

  const [jkDisplay, setJkDisplay] = useState<JamatKhana[]>([]);

  const { getFieldValue } = form;
  const regionId = getFieldValue(["jamatKhana", "region", "id"]);

  useEffect(() => {
    const { getJksByRegion } = CommonUtils();

    if (isCacheReady) {
      if (isRestricted) {
        if (displayJks.length === 1) {
          setJkDisplay(displayJks);
        } else if (regionId && regionId > 0) {
          setJkDisplay(getJksByRegion(regionId, displayJks));
        } else {
          setJkDisplay(jks);
        }
      } else {
        if (showAll || !regionId || regionId < 1) {
          setJkDisplay(jks);
        } else {
          setJkDisplay(getJksByRegion(regionId, jks));
        }
      }
    }
  }, [isCacheReady, isRestricted, displayJks, jks, regionId, showAll]);

  return (
    <Form.Item
      label={label}
      name={namePath}
      rules={[
        {
          required: isRequired,
          message: `${label} is required!`,
        },
      ]}
    >
      <Select
        showSearch
        optionFilterProp="children"
        filterOption={(input, option) =>
          option!.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        allowClear={jkDisplay.length > 1}
        defaultValue={value || sessionUser.jamatKhana?.id}
        onChange={onChange}
        disabled={isDisabled}
      >
        {jkDisplay &&
          jkDisplay.map((jk) => {
            return (
              <Option key={jk.name} value={jk.id}>
                {jk.name}
              </Option>
            );
          })}
      </Select>
    </Form.Item>
  );
};
