import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useAuthContext } from "../hooks/useAuthContext";
import { EnvInfo, User, Cache } from "../types";
import { CommonUtils } from "../utils/CommonUtils";

const initialState: Cache = {
  regions: [],
  displayRegions: [],
  jks: [],
  displayJks: [],
  newUserRoles: [],
  isCacheReady: false,
  envInfo: undefined,
  ccMonthList: [],
  ccExpiryYearList: [],
  ccValidYearList: [],
  yearOfBirthList: [],
  titleList: [],
  transactionTypes: [],
  financialInstitutions: [],
  minFormNumberDigits: 0,
};

export const CacheContext = createContext<Cache>(initialState);

interface Props {
  children: React.ReactNode;
}

export const CacheContextProvider: React.FC<Props> = ({ children }) => {
  const [cache, setCache] = useState(initialState);

  const { user: _user } = useAuthContext();
  const userMemo = useMemo<User | null>(() => {
    return _user;
  }, [_user]);

  const resetCache = () => {
    sessionStorage.removeItem("cacheState");
    loadCacheAsync();
  };

  const loadCacheAsync = async () => {
    const { fetchFc } = CommonUtils();

    let cacheFromSession = sessionStorage.getItem("cacheState");
    let cacheState: Cache;
    if (
      !cacheFromSession ||
      cacheFromSession === undefined ||
      cacheFromSession === "undefined"
    ) {
      cacheState = await fetchFc<Cache>("/general/cacheInfo", "GET");
      sessionStorage.setItem("cacheState", JSON.stringify(cacheState));
    } else {
      cacheState = JSON.parse(cacheFromSession);
    }

    setCache((c) => ({ ...c, ...cacheState, isCacheReady: true }));
  };

  const fetchEnvInfo = useCallback(async () => {
    let envInfoFromSession = sessionStorage.getItem("envInfo");
    let envInfo: EnvInfo;
    if (
      !envInfoFromSession ||
      envInfoFromSession === undefined ||
      envInfoFromSession === "undefined"
    ) {
      const { fetchFc } = CommonUtils();
      envInfo = await fetchFc<EnvInfo>("/general/envInfo", "GET");
      sessionStorage.setItem("envInfo", JSON.stringify(envInfo));
    } else {
      envInfo = JSON.parse(envInfoFromSession);
    }

    setCache((c) => ({ ...c, envInfo }));
  }, []);

  useEffect(() => {
    if (userMemo) {
      loadCacheAsync();
    }

    if (!cache.envInfo) {
      fetchEnvInfo();
    }
  }, [userMemo, fetchEnvInfo, cache.envInfo]);

  return (
    <CacheContext.Provider value={{ ...cache, resetCache }}>
      {children}
    </CacheContext.Provider>
  );
};
