import { ReactNode } from "react";

export type Role = {
  id: number;
  name: string;
  displayName: string;
};

export type User = {
  id?: number;
  login?: string;
  firstName?: string;
  lastName?: string;
  fullName?: string;
  email?: string;
  phone?: string;
  roleName?: string;
  tempPassword?: string;
  agreementDate?: Date | string;
  lastLogin?: Date | string;
  region?: Region;
  jamatKhana?: JamatKhana;
  newPassword?: string;
  tranList?: Transaction[];
  auth?: UserAuth;
  enabled?: boolean;
  agreementDisplay?: string;
  dateRange?: moment.Moment[];
  startDateStr?: string;
  endDateStr?: string;
  currentRoleDisplayName?: string;
  currentRoleName?: string;
};

export type Region = {
  id: number;
  name?: string;
};

export type JamatKhana = {
  id: number;
  name?: string;
  code?: number;
  displayName?: string;
  region?: Region;
  depositSeq?: string;
};

export type Transaction = {
  id: number;
  type: string;
  time: Date | string;
  timeTaken?: number;
  oldVal?: string;
  newVal?: string;
  comment?: string;
  message?: string;
  donor?: Donor;
  pledge?: Pledge;
  batch?: Batch;
  deposit?: Deposit;
  user?: User;
  typeName?: string;
  showDownloadLink?: boolean;
};

export enum ReportType {
  PLEDGE = "PLEDGE",
  PLEDGE_STATUS = "PLEDGE_STATUS",
  BATCH = "BATCH",
  BATCH_SUMMARY = "BATCH_SUMMARY",
  DEPOSIT = "DEPOSIT",
  USER = "USER",
  USER_DUTY = "USER_DUTY",
  DONOR = "DONOR",
  DONOR_CHANGE = "DONOR_CHANGE",
  DONOR_FOLLOW_UP = "DONOR_FOLLOW_UP",
  DONOR_ALTERNATE_JK = "DONOR_ALTERNATE_JK",
  DONOR_EXTRACT = "DONOR_EXTRACT",
}

export type UserAuth = {
  isAdmin: boolean;
  isAkfc: boolean;
  isAdminOrAkfc: boolean;
  isDsRegionalConvenor: boolean;
  isDsJkConvenor: boolean;
  allRegionsForDisplay: boolean;
  allJksForDisplay: boolean;
  canDistributeForms: boolean;
  canCreateDeposit: boolean;
  isDs: boolean;
  isJsf: boolean;
  canAddUsers: boolean;
  canReceiveForms: boolean;
  canRunReports: boolean;
  regionSummaryReport: boolean;
  canUpdateDonors: boolean;
  isJsfJkConvenor: boolean;
  isCfc: boolean;
};

export type ConfirmDetails = {
  title: string;
  subTitle?: string | string[];
  returnUrl?: string;
  returnState?: any;
  timeout?: number;
};

export type Donor = {
  id?: number;
  raiserId?: number;
  firstName?: string;
  lastName?: string;
  fullName?: string;
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  province?: string;
  postalCode?: string;
  country?: string;
  resPhone?: string;
  cellPhone?: string;
  busPhone?: string;
  email?: string;
  status?: string;
  valid?: boolean;
  createDate?: Date | string;
  updateDate?: Date | string;
  pledgeList?: Pledge[];
  jamatKhana?: JamatKhana;
  newDonor?: string;
  prefix?: string;
  initial?: string;
  deceased?: boolean;
  spouseFullName?: string;
  spouseDeceased?: boolean;
  ageRange?: string;
  orgName?: string;
  receiveEmail?: string;
  createdBy?: string;
  transactionList?: Transaction[];
  dateRange?: moment.Moment[];
  startDateStr?: string;
  endDateStr?: string;
  reGiftId?: string;
  activePledges?: Pledge[];
  selectedPledge?: Pledge;
  comment?: string;
  spouseFirstName?: string;
  spouseLastName?: string;
  displayPhone?: string;
};

export type Deposit = {
  id?: number;
  jamatKhana?: JamatKhana;
  formList?: Pledge[];
  number?: string;
  amount?: number | string;
  verifiedDate?: Date | string;
  submittedBy?: string;
  date?: Date | string;
  comment?: string;
  dateCreated?: Date | string;
  dateUpdated?: Date | string;
  trackingNumber?: string;
  dateMailed?: Date | string;
  dateReceived?: Date | string;
  transactionList?: Transaction[];
  dateRange?: moment.Moment[];
  startDateStr?: string;
  endDateStr?: string;
  allChecks?: Collection[];
  totalChequeAmount?: number;
  cashMap?: Record<number, number>;
  totalCashBillAmount?: number;
  totalCashCoinAmount?: number;
  totalCashAmount?: number;
  createdBy?: string;
  flowType?: FLOW_TYPE;
};

export type Collection = {
  id?: number;
  form?: Pledge;
  position?: number;
  checkNumber?: string;
  checkAmount?: number | string;
  checkDate?: Date | string | moment.Moment;
  cashDenomination?: number;
  cashNumber?: number;
  cashDenominationDisplay?: string;
  pdcNumber?: string;
  pdcAmount?: number | string;
  pdcDate?: Date | string | moment.Moment;
};

export enum FLOW_TYPE {
  ISSUE = "ISSUE",
  REASSIGN = "REASSIGN",
  RECEIVE_JK_COPY = "RECEIVE_JK_COPY",
  ENTER = "ENTER",
  EDIT = "EDIT",
  AUDIT = "AUDIT",
  VOID = "VOID",
  LOST = "LOST",
  CREATE = "CREATE",
  PRINT = "PRINT",
  RESET = "RESET",
  RECEIVE = "RECEIVE",
  ONLINE = "ONLINE",
  UNUSED = "UNUSED",
}

export type Batch = {
  id?: number;
  name?: string;
  comment?: string;
  jamatKhana?: JamatKhana;
  transactionList?: Transaction[];
  createDate?: Date | string | moment.Moment;
  formList?: Pledge[];
  summaryReportType?: string;
  flowType?: FLOW_TYPE;
  dateRange?: moment.Moment[];
  startDateStr?: string;
  endDateStr?: string;
  submittedBy?: string;
  numberOfForms?: number;
  totalPledgeAmount?: number;
  numberOfOneTimeCash?: number;
  numberOfOneTimeCheque?: number;
  numberOfPdc?: number;
  numberOfOneTime?: number;
  totalOneTimeCash?: number;
  totalOneTimeCheque?: number;
  totalOneTimePdc?: number;
  totalOneTimeAmount?: number;
  numberOfPPP?: number;
  totalPPPAmount?: number;
  totalDepositAmount?: number;
  deposits?: Deposit[];
};

export type Pledge = {
  id?: number;
  jamatKhana?: JamatKhana;
  transactionList?: Transaction[];
  donor?: Donor;
  newDonor?: Donor;
  deposit?: Deposit;
  batch?: Batch;
  formNumber?: number;
  amount?: number | string;
  enteredDate?: Date | string | moment.Moment;
  enteredBy?: string;
  giftDate?: Date | string | moment.Moment;
  giftBalance?: number;
  monthlyInstalment?: number;
  numberInstalments?: number;
  monthlyTransactionDate?: Date | string | moment.Moment;
  endDateInstalments?: Date | string | moment.Moment;
  latestInstalment?: Date | string | moment.Moment;
  paymentMethod?: string;
  paymentType?: string;
  bankName?: string;
  ccType?: string;
  ccNumber?: string;
  ccExpiryDate?: string;
  processedOnline?: boolean;
  ppp?: boolean;
  untilCancelled?: boolean;
  voidChequeEnclosed?: boolean;
  audited?: boolean;
  position?: number;
  fund?: string;
  comment?: string;
  createDate?: Date | string;
  updateDate?: Date | string;
  returnDate?: Date | string;
  receiveDate?: Date | string;
  emailDate?: Date | string;
  dateLost?: Date | string;
  voidDate?: Date | string;
  taxReceipt?: boolean;
  receivedByHeadOffice?: Date | string;
  collectionList?: Collection[];
  startNumber?: number;
  endNumber?: number;
  flowType?: FLOW_TYPE | string;
  newJkId?: number;
  totalCollection?: string;
  dateRange?: moment.Moment[];
  startDateStr?: string;
  endDateStr?: string;
  status?: string;
  eft?: boolean;
  appeal?: string;
  pledgeStatus?: string;
  reGiftId?: string;
  volunteer1?: User;
  volunteer2?: User;
  "@id"?: number;
  giftDateStr?: string;
  monthlyTransactionDateStr?: string;
  batchStatusForPrint?: string;
  canReset?: boolean;
  showDetailLink?: boolean;
};

export type EnvInfo = {
  isProd: boolean;
  isAkfc: boolean;
  messagesMap: Record<string, string>;
};

export type Cache = {
  regions: Region[];
  displayRegions: Region[];
  jks: JamatKhana[];
  displayJks: JamatKhana[];
  newUserRoles: Role[];
  isCacheReady: boolean;
  envInfo: EnvInfo | undefined;
  resetCache?: () => void;
  ccMonthList: string[];
  ccExpiryYearList: string[];
  ccValidYearList: string[];
  yearOfBirthList: string[];
  titleList: string[];
  transactionTypes: string[];
  financialInstitutions: string[];
  minFormNumberDigits: number;
};

export type DataMap = {
  key: number;
  label: string;
  value?: string | number | ReactNode;
};
