import { Form, Input, Typography, Spin, Button } from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Exception } from "../../components/Exception";
import { ConfirmDetails, Transaction } from "../../types";
import { CommonUtils } from "../../utils/CommonUtils";
import TextArea from "antd/lib/input/TextArea";
import { BackButton } from "../../components/BackButton";

export const EditTransaction = () => {
  const [error, setError] = useState<string | React.ReactNode | null>(null);
  const [isPending, setIsPending] = useState(false);

  const [transaction, setTransaction] = useState<Transaction | null>(null);

  const [formInstance] = Form.useForm();
  const { Title } = Typography;
  const navigate = useNavigate();

  const { fetchFc } = CommonUtils();

  const { id } = useParams();

  useEffect(() => {
    setIsPending(true);

    async function loadTransaction() {
      const { fetchFc } = CommonUtils();

      try {
        const transaction = await fetchFc<Transaction>(
          `/transactions/${id}`,
          "GET"
        );
        setTransaction(transaction);

        setIsPending(false);
      } catch (err) {
        setError((err as Error).message);
        setIsPending(false);
      }
    }

    loadTransaction();
  }, [id]);

  const onFinish = async (t: Transaction) => {
    setIsPending(true);
    setError(null);

    try {
      await fetchFc(`/transactions/${id}`, "POST", t);

      const confirmDetails: ConfirmDetails = {
        title: "Transaction Updated",
        subTitle: `Transaction ID: ${t.id} updated successfully`,
        returnUrl: `/searchTransactions`,
      };

      navigate("/confirm", { state: confirmDetails });
    } catch (err) {
      setError((err as Error).message);
      setIsPending(false);
    }
  };

  return (
    <div>
      {isPending && (
        <div className="loading">
          <Spin size="large" />
        </div>
      )}
      {!isPending && !transaction && error && (
        <Exception exceptionDetails={error} />
      )}
      {!isPending && transaction && (
        <>
          <BackButton buttonText="Back to search page" />
          <Title className="text-center">Edit Transaction</Title>
          {error && <Exception exceptionDetails={error} />}
          <Form
            form={formInstance}
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 8 }}
            onFinish={onFinish}
            autoComplete="off"
            initialValues={{
              id: transaction.id,
              typeName: transaction.typeName,
              message: transaction.message,
              comment: transaction.comment,
            }}
          >
            <Form.Item label="Transaction ID" name={["id"]}>
              <Input disabled={true} />
            </Form.Item>
            <Form.Item label="Transaction Type" name={["typeName"]}>
              <Input disabled={true} />
            </Form.Item>
            <Form.Item label="Transaction Message" name={["message"]}>
              <Input disabled={true} />
            </Form.Item>
            <Form.Item label="Transaction Comment" name={["comment"]}>
              <TextArea placeholder="Comment" />
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 8, span: 8 }}>
              {!isPending && (
                <Button type="primary" htmlType="submit">
                  Edit Transaction
                </Button>
              )}
              {isPending && (
                <Button type="primary" disabled>
                  Processing...
                </Button>
              )}
            </Form.Item>
          </Form>
        </>
      )}
    </div>
  );
};
