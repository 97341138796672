import { Form, Input, Button, Typography, Select, Spin, Tooltip } from "antd";
import { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Exception } from "../../components/Exception";
import { JkSelect } from "../../components/JkSelect";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useCacheContext } from "../../hooks/useCacheContext";
import { ConfirmDetails, Donor, User } from "../../types";
import { CommonUtils } from "../../utils/CommonUtils";

export const AddDonor = () => {
  const { user: _sessionUser } = useAuthContext();
  const sessionUser = useRef<User>(_sessionUser).current as User;

  const { isCacheReady, titleList } = useCacheContext();

  const [error, setError] = useState<string | React.ReactNode | null>(null);
  const [isPending, setIsPending] = useState(false);

  const [form] = Form.useForm();

  const { Title } = Typography;
  const { Option } = Select;
  const navigate = useNavigate();

  const {
    fetchFc,
    booleanArray,
    yesNoArray,
    ageRangeArray,
    provinceArray,
    countryArray,
    getUserJk,
    validatePhoneNumber,
  } = CommonUtils();

  const onFinish = async (donor: Donor) => {
    if (!donor.cellPhone && !donor.resPhone && !donor.busPhone) {
      setError("At least 1 phone number is required for the donor");
      return;
    }

    if (donor.cellPhone && !validatePhoneNumber(donor.cellPhone)) {
      setError("Cell Phone Number is not valid");
      return;
    }

    if (donor.resPhone && !validatePhoneNumber(donor.resPhone)) {
      setError("Residential Phone Number is not valid");
      return;
    }

    if (donor.busPhone && !validatePhoneNumber(donor.busPhone)) {
      setError("Business Phone Number is not valid");
      return;
    }

    const isSpouseNameProvided = donor.spouseFirstName || donor.spouseLastName;
    if (
      isSpouseNameProvided &&
      (!donor.spouseFirstName || !donor.spouseLastName)
    ) {
      setError("Full spouse name is required or else blank it");
      return;
    }

    setIsPending(true);
    setError(null);

    try {
      let search = "?";
      if (donor.firstName) {
        search += `firstName=${donor.firstName}&`;
      }

      if (donor.lastName) {
        search += `lastName=${donor.lastName}&`;
      }

      if (donor.email) {
        search += `email=${donor.email}&`;
      }

      if (donor.cellPhone) {
        search += `cellPhone=${donor.cellPhone}&`;
      }

      if (donor.resPhone) {
        search += `cellPhone=${donor.resPhone}&`;
      }

      if (donor.busPhone) {
        search += `cellPhone=${donor.busPhone}&`;
      }

      const result = await fetchFc<Donor[]>(`/donors${search}`, "GET");
      if (result.length > 0) {
        setError(
          <>
            Donor already exists.{" "}
            <Link to={`/searchDonors${search}`}>Search Donor</Link>
          </>
        );
        setIsPending(false);
        return;
      }

      const created = await fetchFc<Donor>("/donors", "POST", donor);

      const confirmDetails: ConfirmDetails = {
        title: "Donor Added",
        subTitle: `Donor ${created.fullName} created successfully`,
        returnUrl: `/searchDonors?id=${created.id}`,
        timeout: 10000,
      };

      navigate("/confirm", { state: confirmDetails });
    } catch (err) {
      setError((err as Error).message);
      setIsPending(false);
    }
  };

  return (
    <div>
      {!isCacheReady && (
        <div className="loading">
          <Spin size="large" />
        </div>
      )}
      {isCacheReady && (
        <>
          <Title className="text-center">Add Donor</Title>
          {error && <Exception exceptionDetails={error} />}
          <Form
            form={form}
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 8 }}
            onFinish={onFinish}
            autoComplete="off"
            initialValues={{
              jamatKhana: getUserJk(sessionUser),
            }}
          >
            <Form.Item label="Title" name="prefix">
              <Select>
                {titleList &&
                  titleList.map((t) => {
                    return (
                      <Option key={t} value={t}>
                        {t}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
            <Form.Item
              label="First Name"
              name="firstName"
              rules={[{ required: true, message: "Please input First Name!" }]}
            >
              <Input placeholder="First Name" />
            </Form.Item>
            <Form.Item label="Initial" name="initial">
              <Input placeholder="Initial" />
            </Form.Item>
            <Form.Item
              label="Last Name"
              name="lastName"
              rules={[{ required: true, message: "Please input Last Name!" }]}
            >
              <Input placeholder="Last Name" />
            </Form.Item>
            <Form.Item label="Spouse Title" name="spouseTitle">
              <Select>
                {titleList &&
                  titleList.map((t) => {
                    return (
                      <Option key={t} value={t}>
                        {t}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
            <Form.Item label="Spouse First Name" name="spouseFirstName">
              <Input placeholder="Spouse First Name" />
            </Form.Item>
            <Form.Item label="Spouse Last Name" name="spouseLastName">
              <Input placeholder="Spouse Last Name" />
            </Form.Item>
            <Form.Item label="Spouse Deceased" name="spouseDeceased">
              <Select>
                {booleanArray.map((t) => {
                  return (
                    <Option key={t.label} value={t.value}>
                      {t.label}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item label="Age Range" name="ageRange">
              <Select>
                {ageRangeArray.map((t) => {
                  return (
                    <Option key={t} value={t}>
                      {t}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item label="Email Address" name="email">
              <Input type="email" placeholder="first.last@ds.akdn.org" />
            </Form.Item>
            <Tooltip
              placement="top"
              title="At least one phone number is required"
            >
              <Form.Item label="Cell Phone Number" name="cellPhone">
                <Input placeholder="(111) 222-3333" />
              </Form.Item>
              <Form.Item label="Res Phone Number" name="resPhone">
                <Input placeholder="(111) 222-3333" />
              </Form.Item>
              <Form.Item label="Bus Phone Number" name="busPhone">
                <Input placeholder="(111) 222-3333" />
              </Form.Item>
            </Tooltip>
            <Form.Item label="Org Name" name="orgName">
              <Input placeholder="Org Name" />
            </Form.Item>
            <Form.Item
              label="Address Line 1"
              name="addressLine1"
              rules={[
                { required: true, message: "Please input Address Line 1!" },
              ]}
            >
              <Input placeholder="Address Line 1" />
            </Form.Item>
            <Form.Item label="Address Line 2" name="addressLine2">
              <Input placeholder="Address Line 2" />
            </Form.Item>
            <Form.Item
              label="City"
              name="city"
              rules={[{ required: true, message: "Please input City!" }]}
            >
              <Input placeholder="City" />
            </Form.Item>
            <Form.Item
              label="Province"
              name="province"
              rules={[{ required: true, message: "Please input Province!" }]}
            >
              <Select>
                {provinceArray.map((t) => {
                  return (
                    <Option key={t.abbr} value={t.abbr}>
                      {t.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item
              label="Postal Code"
              name="postalCode"
              rules={[{ required: true, message: "Please input Postal Code!" }]}
            >
              <Input placeholder="Postal Code" />
            </Form.Item>
            <Form.Item
              label="Country"
              name="country"
              rules={[{ required: true, message: "Please input Country!" }]}
            >
              <Select>
                {countryArray.map((t) => {
                  return (
                    <Option key={t} value={t}>
                      {t}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item
              label="Receive Email"
              name="receiveEmail"
              rules={[
                { required: true, message: "Please input Receive Email" },
              ]}
            >
              <Select>
                {yesNoArray.map((t) => {
                  return (
                    <Option key={t.label} value={t.value}>
                      {t.label}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <JkSelect
              form={form}
              isRequired={true}
              isRestricted={false}
              showAll={true}
            />
            <Form.Item wrapperCol={{ offset: 8, span: 8 }}>
              {!isPending && (
                <Button type="primary" htmlType="submit">
                  Add Donor
                </Button>
              )}
              {isPending && (
                <Button type="primary" disabled>
                  Adding Donor...
                </Button>
              )}
            </Form.Item>
          </Form>
        </>
      )}
    </div>
  );
};
