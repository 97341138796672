import React from "react";
import { Input, Typography } from "antd";
import { MaskedInput } from "antd-mask-input";
import { CommonUtils } from "../utils/CommonUtils";

interface InputProps {
  value?: any;
  onChange?: (value: any) => void;
  disabled?: boolean;
  mask?: string;
}

export const CustomInput: React.FC<InputProps> = ({
  value = "",
  onChange,
  disabled,
  mask,
}) => {
  const { Text } = Typography;
  const { formatPhoneNumber } = CommonUtils();

  const triggerChange = (changedValue: string) => {
    onChange?.(changedValue);
  };

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    triggerChange(e.target.value);
  };

  return (
    <span>
      {!disabled && !mask && (
        <Input type="text" value={value || ""} onChange={onInputChange} />
      )}
      {!disabled && mask && (
        <MaskedInput mask={mask} value={value || ""} onChange={onInputChange} />
      )}
      {disabled && !mask && <Text strong>{value}</Text>}
      {disabled && mask === "(000) 000-0000" && (
        <Text strong>{formatPhoneNumber(value)}</Text>
      )}
    </span>
  );
};
