import { Button, Space, Spin } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { BackButton } from "../../components/BackButton";
import { Exception } from "../../components/Exception";
import { useCacheContext } from "../../hooks/useCacheContext";
import { Batch } from "../../types";
import { CommonUtils } from "../../utils/CommonUtils";

type Display = {
  fromPage?: string;
};

export const PrintBatch = () => {
  const [batch, setBatch] = useState<Batch | null>(null);

  const [error, setError] = useState<string | null>(null);
  const [isPending, setIsPending] = useState(false);

  const { state }: { state: any } = useLocation();
  const display: Display = state;
  const { id } = useParams();

  const { envInfo } = useCacheContext();

  const { formatCurrency, formatDate } = CommonUtils();

  const printDivCallBack = useCallback(() => {
    const printDiv = document.getElementById("printDiv");
    if (printDiv) {
      const printWindow = window.open(
        "",
        "",
        "left=0,top=0,width=900,height=900,toolbar=0,scrollbars=0,status=0"
      );
      if (printWindow) {
        printWindow.document.write(printDiv.innerHTML);
        printWindow.document.close();
        printWindow.focus();
        printWindow.print();
        printWindow.close();
      }
    }
  }, []);

  useEffect(() => {
    setIsPending(true);

    async function loadBatch() {
      const { fetchFc } = CommonUtils();

      try {
        const batch = await fetchFc<Batch>(`/batches/${id}`, "GET");
        setBatch(batch);

        setIsPending(false);

        setTimeout(() => {
          printDivCallBack();
        }, 500);
      } catch (err) {
        setError((err as Error).message);
        setIsPending(false);
      }
    }

    loadBatch();
  }, [id, printDivCallBack]);

  return (
    <div>
      {isPending && (
        <div className="loading">
          <Spin size="large" />
        </div>
      )}
      {!isPending && !batch && error && <Exception exceptionDetails={error} />}
      {!isPending && batch && (
        <>
          <Space>
            <BackButton
              buttonText={
                display.fromPage === "search"
                  ? "Back to search page"
                  : "Back to details page"
              }
            />
            <Button onClick={printDivCallBack}>Print Batch</Button>
          </Space>
          <div id="printDiv" style={{ width: "900px", margin: "20px" }}>
            {envInfo?.isAkfc && (
              <>
                <div>
                  <p style={{ fontWeight: "bold", fontSize: "medium" }}>
                    AGA KHAN COUNCIL FOR CANADA
                  </p>
                  <p>
                    The Ismaili Centre
                    <br />
                    49 Wynford Drive,
                    <br />
                    Toronto Ontario M3C 1K1
                    <br />
                    Tel: 416 646 6965 x 2032
                    <br />
                    email: jsf@iicanada.net
                  </p>
                </div>
                <div id="header">
                  <p>
                    JSF Fund Collection
                    <br />
                    CFC Batch
                  </p>
                </div>
              </>
            )}
            {!envInfo?.isAkfc && (
              <>
                <div>
                  <p style={{ fontWeight: "bold", fontSize: "medium" }}>
                    FOCUS HUMANITARIAN ASSISTANCE CANADA
                  </p>
                  <p>
                    The Ismaili Centre
                    <br />
                    49 Wynford Drive, Toronto Ontario M3C 1K1
                  </p>
                </div>
                <div id="header">
                  <p>
                    Focus Fund Collection
                    <br />
                    Focus Batch
                  </p>
                </div>
              </>
            )}
            <div style={{ marginTop: "20px" }}>
              <p>
                Date Created: {formatDate(batch.createDate as string)}
                <br />
                Jamat Khana: {batch.jamatKhana?.name}
                <br />
                Submitted By: {batch.submittedBy}
              </p>
            </div>
            <div style={{ marginTop: "20px" }}>
              <table style={{ width: "500px" }}>
                <thead>
                  <tr>
                    <th colSpan={4}>Forms</th>
                  </tr>
                  <tr>
                    <th colSpan={4}>
                      Total Number of Forms: {batch.formList?.length}
                    </th>
                  </tr>
                  <tr>
                    <th>Form Number</th>

                    {envInfo?.isAkfc && <th>Pledge Created</th>}
                    {!envInfo?.isAkfc && <th>Donor Name</th>}
                    <th>Amount</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {batch.formList &&
                    batch.formList.map((p) => {
                      return (
                        <tr>
                          <td>{p.formNumber}</td>
                          {envInfo?.isAkfc && (
                            <td>{formatDate(p.giftDate as string)}</td>
                          )}
                          {!envInfo?.isAkfc && <td>{p.donor?.fullName}</td>}
                          <td style={{ textAlign: "right" }}>
                            {formatCurrency(p.amount as number)}
                          </td>
                          <td>{p.batchStatusForPrint}</td>
                        </tr>
                      );
                    })}
                </tbody>
                <tfoot>
                  <tr>
                    <th
                      colSpan={2}
                      style={{ textAlign: "right", paddingRight: "10px" }}
                    >
                      Total Donation Amount
                    </th>
                    <th>{formatCurrency(batch.totalPledgeAmount)}</th>
                    <th>&nbsp;</th>
                  </tr>
                </tfoot>
              </table>
            </div>
            <div style={{ marginTop: "20px" }}>
              <table style={{ width: "500px" }}>
                <thead>
                  <tr>
                    <th colSpan={2}>Batch Summary</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Number of One Time Cash Donations</td>
                    <td style={{ textAlign: "right" }}>
                      {batch.numberOfOneTimeCash}
                    </td>
                  </tr>
                  <tr>
                    <td>Number of One Time Cheque Donations</td>
                    <td style={{ textAlign: "right" }}>
                      {batch.numberOfOneTimeCheque}
                    </td>
                  </tr>
                  <tr>
                    <td>Number of Post Dated Cheques</td>
                    <td style={{ textAlign: "right" }}>{batch.numberOfPdc}</td>
                  </tr>
                  <tr>
                    <td>Number of One Time Donations</td>
                    <td style={{ textAlign: "right" }}>
                      {batch.numberOfOneTime}
                    </td>
                  </tr>
                  <tr>
                    <th colSpan={2}>&nbsp;</th>
                  </tr>
                  <tr>
                    <td>Total One Time Cash Donation Amount</td>
                    <td style={{ textAlign: "right" }}>
                      {formatCurrency(batch.totalOneTimeCash)}
                    </td>
                  </tr>
                  <tr>
                    <td>Total One Time Cheque Donation Amount</td>
                    <td style={{ textAlign: "right" }}>
                      {formatCurrency(batch.totalOneTimeCheque)}
                    </td>
                  </tr>
                  <tr>
                    <td>Total One Time Post Dated Cheque Donation Amount</td>
                    <td style={{ textAlign: "right" }}>
                      {formatCurrency(batch.totalOneTimePdc)}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: "bold" }}>
                      Total One Time Donation Amount
                    </td>
                    <td style={{ fontWeight: "bold", textAlign: "right" }}>
                      {formatCurrency(batch.totalOneTimeAmount)}
                    </td>
                  </tr>
                  <tr>
                    <th colSpan={2}>&nbsp;</th>
                  </tr>
                  <tr>
                    <td>Number of PPP Donations</td>
                    <td style={{ textAlign: "right" }}>{batch.numberOfPPP}</td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: "bold" }}>
                      Total PPP Donation Amount
                    </td>
                    <td style={{ fontWeight: "bold", textAlign: "right" }}>
                      {formatCurrency(batch.totalPPPAmount)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            {batch.deposits && (
              <div style={{ marginTop: "20px" }}>
                <table style={{ width: "500px" }}>
                  <thead>
                    <tr>
                      <th colSpan={3}>Bank Deposits</th>
                    </tr>
                    <tr>
                      <th>Bank Deposit Number</th>
                      <th>Date Submitted</th>
                      <th>Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {batch.deposits.map((d) => {
                      return (
                        <tr>
                          <td>{d.number}</td>
                          <td>{formatDate(d.date as string)}</td>
                          <td style={{ textAlign: "right" }}>
                            {formatCurrency(d.amount as number)}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                  <tfoot>
                    <tr>
                      <th
                        colSpan={2}
                        style={{ textAlign: "right", paddingRight: "10px" }}
                      >
                        Total Deposit Amount
                      </th>
                      <th style={{ textAlign: "right" }}>
                        {formatCurrency(batch.totalDepositAmount)}
                      </th>
                    </tr>
                  </tfoot>
                </table>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};
