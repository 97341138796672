import { Col, Row, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { DataMap } from "../types";

type TransactionInput = {
  dataMap?: DataMap[];
};

export const ShowDetails: React.FC<TransactionInput> = ({ dataMap }) => {
  const detailsDisplayColumns: ColumnsType<DataMap> = [
    {
      title: "",
      dataIndex: "label",
      width: 200,
      render: (text: string) => (
        <span style={{ fontWeight: "bold" }}>{text}</span>
      ),
    },
    {
      title: "",
      dataIndex: "value",
    },
  ];

  return (
    <div>
      {dataMap && (
        <Row>
          <Col span={12} offset={6}>
            <Table
              dataSource={dataMap}
              columns={detailsDisplayColumns}
              rowKey={(u) => u.key}
              bordered
              pagination={false}
              showHeader={false}
            />
          </Col>
        </Row>
      )}
    </div>
  );
};
