import { Button, Form, Input, Typography } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Exception } from "../../components/Exception";
import { useAuthContext } from "../../hooks/useAuthContext";
import { User } from "../../types";
import { CommonUtils } from "../../utils/CommonUtils";

type TempPasswordInput = {
  newPassword: string;
  confirmPassword: string;
};

export const TempPassword = () => {
  const [error, setError] = useState<string | null>(null);
  const [isPending, setIsPending] = useState(false);

  const { user, dispatch } = useAuthContext();
  const { fetchFc } = CommonUtils();

  const { Title } = Typography;
  const navigate = useNavigate();

  const onFinish = async (values: TempPasswordInput) => {
    setIsPending(true);
    setError(null);

    if (values.newPassword !== values.confirmPassword) {
      setError("New Password and Confirm Password must match");
      return;
    }

    try {
      const body = await fetchFc<User>(
        "/users/changeTempPassword",
        "POST",
        values
      );
      dispatch!({ type: "LOGIN", payload: body });
      navigate("/");
    } catch (err) {
      setError((err as Error).message);
      setIsPending(false);
    }
  };

  return (
    <div>
      {user && (
        <>
          <Title className="text-center">Change Temporary Password</Title>
          {error && <Exception exceptionDetails={error} />}
          <Form
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 8 }}
            onFinish={onFinish}
            autoComplete="off"
          >
            <Form.Item
              label="New Password"
              name="newPassword"
              rules={[
                { required: true, message: "Please input your new password!" },
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              label="Confirm New Password"
              name="confirmPassword"
              rules={[
                { required: true, message: "Please retype your new password!" },
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 8, span: 8 }}>
              {!isPending && (
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              )}
              {isPending && (
                <Button type="primary" disabled>
                  Processing...
                </Button>
              )}
            </Form.Item>
          </Form>
        </>
      )}
    </div>
  );
};
