import { Button, Form, Input, Select, Spin, Typography } from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Exception } from "../../components/Exception";
import { JkSelect } from "../../components/JkSelect";
import { RegionSelect } from "../../components/RegionSelect";
import { useCacheContext } from "../../hooks/useCacheContext";
import { ConfirmDetails, User } from "../../types";
import { CommonUtils } from "../../utils/CommonUtils";

export const EditUser = () => {
  const { newUserRoles, isCacheReady } = useCacheContext();

  const [user, setUser] = useState<User | null>(null);

  const [error, setError] = useState<string | null>(null);
  const [isPending, setIsPending] = useState(false);

  const { Title } = Typography;
  const { Option } = Select;
  const navigate = useNavigate();

  const { fetchFc, formatPhoneNumber } = CommonUtils();

  const { id } = useParams();

  useEffect(() => {
    setIsPending(true);

    async function loadUser() {
      const { fetchFc } = CommonUtils();

      try {
        const user = await fetchFc<User>(`/users/${id}`, "GET");
        setUser(user);

        setIsPending(false);
      } catch (err) {
        setError((err as Error).message);
        setIsPending(false);
      }
    }

    loadUser();
  }, [id]);

  const onFinish = async (editedUser: User) => {
    setIsPending(true);
    setError(null);

    try {
      const mergedUser: User = { ...user, ...editedUser };
      const edited = await fetchFc<User>(`/users/${id}`, "POST", mergedUser);

      const confirmDetails: ConfirmDetails = {
        title: "User Edited",
        subTitle: `User ID: ${edited.login} edited successfully`,
        returnUrl: "/searchUsers",
      };

      navigate("/confirm", { state: confirmDetails });
    } catch (err) {
      setError((err as Error).message);
      setIsPending(false);
    }
  };

  return (
    <div>
      {(isPending || !isCacheReady) && (
        <div className="loading">
          <Spin size="large" />
        </div>
      )}
      {!isPending && isCacheReady && (
        <>
          <Title className="text-center">Edit User: {user?.fullName}</Title>
          {error && <Exception exceptionDetails={error} />}
          <Form
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 8 }}
            onFinish={onFinish}
            autoComplete="off"
            initialValues={{
              firstName: user?.firstName,
              lastName: user?.lastName,
              email: user?.email,
              phone: formatPhoneNumber(user?.phone),
              roleName: user?.currentRoleName,
              jamatKhana: {
                id: user?.jamatKhana?.id,
                region: {
                  id: user?.region?.id || user?.jamatKhana?.region?.id,
                },
              },
              enabled: user?.enabled,
            }}
          >
            <Form.Item
              label="First Name"
              name="firstName"
              rules={[
                { required: true, message: "Please input the First Name!" },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Last Name"
              name="lastName"
              rules={[
                { required: true, message: "Please input the Last Name!" },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item label="New Password" name="newPassword">
              <Input.Password />
            </Form.Item>
            <Form.Item label="Is Enabled" name="enabled">
              <Select>
                <Option key="Yes" value={true}>
                  Yes
                </Option>
                <Option key="No" value={false}>
                  No
                </Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="Email Address"
              name="email"
              rules={[
                { required: true, message: "Please input the Email Address!" },
              ]}
            >
              <Input type="email" />
            </Form.Item>
            <Form.Item
              label="Phone Number"
              name="phone"
              rules={[
                { required: true, message: "Please input the Phone Number!" },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item label="Role" name="roleName">
              <Select>
                {newUserRoles &&
                  newUserRoles.map((ur) => {
                    return (
                      <Option key={ur.name} value={ur.name}>
                        {ur.displayName}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
            <Form.Item label="Region" name={["jamatKhana", "region", "id"]}>
              <RegionSelect />
            </Form.Item>
            <Form.Item
              noStyle
              shouldUpdate={(prev, curr) =>
                prev.jamatKhana.region.id !== curr.jamatKhana.region.id
              }
            >
              {(form) => {
                return <JkSelect form={form} />;
              }}
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 8, span: 8 }}>
              {!isPending && (
                <Button type="primary" htmlType="submit">
                  Edit User
                </Button>
              )}
              {isPending && (
                <Button type="primary" disabled>
                  Editing User...
                </Button>
              )}
            </Form.Item>
          </Form>
        </>
      )}
    </div>
  );
};
