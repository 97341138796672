import { Alert, Button, Form, Input, message, Typography } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useCacheContext } from "../../hooks/useCacheContext";
import { User } from "../../types";
import { CommonUtils } from "../../utils/CommonUtils";

import "./Login.css";

type LoginInput = {
  username: string;
  password: string;
};

export const Login = () => {
  const [error, setError] = useState<string | null>(null);
  const [isPending, setIsPending] = useState(false);

  const { fetchFc } = CommonUtils();
  const { dispatch } = useAuthContext();

  const { Paragraph, Title } = Typography;
  const navigate = useNavigate();

  const { envInfo } = useCacheContext();

  const onFinish = async (loginInput: LoginInput) => {
    setIsPending(true);
    setError(null);

    try {
      const body = await fetchFc<User>("/login", "POST", loginInput);
      sessionStorage.clear();
      setIsPending(false);
      dispatch!({ type: "LOGIN", payload: body });
      navigate("/");
    } catch (err) {
      setError("Authenication Failed");
      setIsPending(false);
    }
  };

  useEffect(() => {
    const sessionTimedOut = sessionStorage.getItem("sessionTimedOut");
    if (sessionTimedOut) {
      message.error(
        "Your session timed out due to inactivity. Please sign in again.",
        4
      );
      sessionStorage.removeItem("sessionTimedOut");
    }
  }, []);

  return (
    <>
      <Title className="text-center">Sign In</Title>
      {error && (
        <Alert
          closable={true}
          message={
            <Paragraph>
              {envInfo && (
                <span
                  dangerouslySetInnerHTML={{
                    __html: envInfo.messagesMap["login.error"],
                  }}
                ></span>
              )}
              {!envInfo && "Login Error"}
            </Paragraph>
          }
          type="error"
        />
      )}
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 8 }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          label="Username"
          name="username"
          rules={[{ required: true, message: "Please input your username!" }]}
        >
          <Input placeholder="User Name" />
        </Form.Item>
        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true, message: "Please input your password!" }]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 8, span: 8 }}>
          {!isPending && (
            <Button type="primary" htmlType="submit">
              Sign in
            </Button>
          )}
          {isPending && (
            <Button type="primary" disabled>
              Signing in...
            </Button>
          )}
        </Form.Item>
      </Form>
    </>
  );
};
