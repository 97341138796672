import { Spin, Table, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { BackButton } from "../../components/BackButton";
import { Exception } from "../../components/Exception";
import { ShowDetails } from "../../components/ShowDetails";
import { TransactionList } from "../../components/TransactionList";
import { DataMap, Donor, Pledge } from "../../types";
import { CommonUtils } from "../../utils/CommonUtils";

import styles from "./Donor.module.css";

type DisplayState = {
  donor?: Donor;
  dataMap?: DataMap[];
  error?: string;
};

export const DonorDetails = () => {
  const [displayState, setDisplayState] = useState<DisplayState>({});

  const { Title } = Typography;

  const { id } = useParams();

  const { formatCurrency, formatDate } = CommonUtils();

  useEffect(() => {
    setDisplayState({ error: undefined });

    async function loadDonor() {
      const {
        fetchFc,
        formatPhoneNumber,
        formatPostalCode,
        formatBoolean,
        formatDate,
      } = CommonUtils();

      try {
        const donor = await fetchFc<Donor>(
          `/donors/${id}?retrieveDetails=true`,
          "GET"
        );

        const dataMap: DataMap[] = [];
        let key = 0;
        dataMap.push({ key: key++, label: "Donor ID", value: donor.raiserId });
        dataMap.push({ key: key++, label: "New Donor", value: donor.newDonor });
        dataMap.push({
          key: key++,
          label: "Salutation",
          value: donor.prefix,
        });
        dataMap.push({
          key: key++,
          label: "First Name",
          value: donor.firstName,
        });
        dataMap.push({
          key: key++,
          label: "Initial",
          value: donor.initial,
        });
        dataMap.push({
          key: key++,
          label: "Last Name",
          value: donor.lastName,
        });
        dataMap.push({
          key: key++,
          label: "Deceased",
          value: formatBoolean(donor.deceased),
        });
        dataMap.push({
          key: key++,
          label: "Spouse Name",
          value: donor.spouseFullName,
        });
        dataMap.push({
          key: key++,
          label: "Spouse Deceased",
          value: formatBoolean(donor.spouseDeceased),
        });
        dataMap.push({
          key: key++,
          label: "Age Range",
          value: donor.ageRange,
        });
        dataMap.push({
          key: key++,
          label: "Org Name",
          value: donor.orgName,
        });
        dataMap.push({
          key: key++,
          label: "Address Line 1",
          value: donor.addressLine1,
        });
        dataMap.push({
          key: key++,
          label: "Address Line 2",
          value: donor.addressLine2,
        });
        dataMap.push({
          key: key++,
          label: "City",
          value: donor.city,
        });
        dataMap.push({
          key: key++,
          label: "Province",
          value: donor.province,
        });
        dataMap.push({
          key: key++,
          label: "Postal Code",
          value: formatPostalCode(donor.postalCode),
        });
        dataMap.push({
          key: key++,
          label: "Country",
          value: donor.country,
        });
        dataMap.push({
          key: key++,
          label: "Res Phone",
          value: formatPhoneNumber(donor.resPhone),
        });
        dataMap.push({
          key: key++,
          label: "Cell Phone",
          value: formatPhoneNumber(donor.cellPhone),
        });
        dataMap.push({
          key: key++,
          label: "Bus Phone",
          value: formatPhoneNumber(donor.busPhone),
        });
        dataMap.push({
          key: key++,
          label: "Email Address",
          value: donor.email,
        });
        dataMap.push({
          key: key++,
          label: "Region",
          value: donor.jamatKhana?.region?.name,
        });
        dataMap.push({
          key: key++,
          label: "Jamat Khana",
          value: donor.jamatKhana?.name,
        });
        dataMap.push({
          key: key++,
          label: "Receive Emails",
          value: donor.receiveEmail,
        });
        dataMap.push({
          key: key++,
          label: "Created By",
          value: donor.createdBy,
        });
        dataMap.push({
          key: key++,
          label: "Date Created",
          value: formatDate(donor.createDate as string),
        });

        setDisplayState({ donor: donor, dataMap: dataMap });
      } catch (err) {
        const error = (err as Error).message;
        setDisplayState({ error: error });
      }
    }

    loadDonor();
  }, [id]);

  const pledgeDisplayColumns: ColumnsType<Pledge> = [
    {
      title: "Gift Campaign",
      dataIndex: "fund",
      key: "fund",
    },
    {
      title: "Form Number",
      dataIndex: "formNumber",
      key: "formNumber",
    },
    {
      title: "RE Gift ID",
      dataIndex: "reGiftId",
      key: "reGiftId",
    },
    {
      title: "Date",
      key: "giftDate",
      render: (_text, record) => formatDate(record.giftDate as string, true),
    },
    {
      title: "Amount",
      key: "amount",
      render: (_text, record) => formatCurrency(record.amount as number),
    },
    {
      title: "Monthly Amount",
      key: "monthlyInstalment",
      render: (_text, record) => formatCurrency(record.monthlyInstalment),
    },
    {
      title: "Latest Instalment",
      key: "latestInstalment",
      render: (_text, record) =>
        formatDate(record.latestInstalment as string, true),
    },
    {
      title: "Balance",
      key: "balance",
      render: (_text, record) => formatCurrency(record.giftBalance),
    },
    {
      title: "Payment Method",
      dataIndex: "paymentType",
      key: "paymentType",
    },
    {
      title: "Status",
      dataIndex: "pledgeStatus",
      key: "pledgeStatus",
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (_text, record) =>
        record.showDetailLink ? (
          <Link to={`/pledgeDetails/${record.id}`}>Show Details</Link>
        ) : (
          ""
        ),
    },
  ];

  return (
    <div>
      {!displayState.donor && (
        <div className="loading">
          <Spin size="large" />
        </div>
      )}
      {displayState.donor && (
        <>
          <BackButton buttonText="Back to search page" />
          <Title className="text-center">
            Donor Details: {displayState.donor.fullName}
          </Title>
          {displayState.error && (
            <Exception exceptionDetails={displayState.error} />
          )}
          <ShowDetails dataMap={displayState.dataMap} />
          {displayState.donor.pledgeList &&
            displayState.donor.pledgeList.length > 0 && (
              <Table
                rowClassName={(record) =>
                  record.pledgeStatus === "Cancelled"
                    ? styles.redText
                    : record.pledgeStatus === "Active"
                    ? styles.greenText
                    : ""
                }
                dataSource={displayState.donor?.pledgeList}
                columns={pledgeDisplayColumns}
                pagination={{
                  total: displayState.donor.pledgeList.length,
                  showTotal: (t) => `${t} results`,
                  defaultPageSize: 20,
                  defaultCurrent: 1,
                }}
                rowKey="id"
                title={() => (
                  <span style={{ fontWeight: "bold" }}>Donor Pledges</span>
                )}
                className="content-margin"
              />
            )}
          <TransactionList transactions={displayState.donor.transactionList} />
        </>
      )}
    </div>
  );
};
