import { Space, Spin, Table, Tooltip, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { BackButton } from "../../components/BackButton";
import { Exception } from "../../components/Exception";
import { ShowDetails } from "../../components/ShowDetails";
import { TransactionList } from "../../components/TransactionList";
import { Collection, DataMap, Pledge } from "../../types";
import { CommonUtils } from "../../utils/CommonUtils";

type DisplayState = {
  pledge?: Pledge;
  dataMap?: DataMap[];
  error?: string;
};

export const PledgeDetails = () => {
  const [displayState, setDisplayState] = useState<DisplayState>({});

  const { Title } = Typography;

  const { id } = useParams();

  useEffect(() => {
    setDisplayState({ error: undefined });

    async function loadForm() {
      const {
        fetchFc,
        formatDate,
        formatBoolean,
        formatCurrency,
        allCheckCollections,
      } = CommonUtils();

      try {
        const pledge = await fetchFc<Pledge>(
          `/pledges/${id}?retrieveDetails=true`,
          "GET"
        );

        const dataMap: DataMap[] = [];
        let key = 0;
        dataMap.push({ key: key++, label: "Campaign", value: pledge.fund });
        dataMap.push({
          key: key++,
          label: "Form Number",
          value: pledge.formNumber,
        });
        dataMap.push({
          key: key++,
          label: "RE Gift ID",
          value: pledge.reGiftId,
        });
        dataMap.push({
          key: key++,
          label: "Region",
          value: pledge.jamatKhana?.region?.name,
        });
        dataMap.push({
          key: key++,
          label: "Jamat Khana",
          value: pledge.jamatKhana?.name,
        });
        dataMap.push({
          key: key++,
          label: "Status",
          value: pledge.status,
        });
        dataMap.push({
          key: key++,
          label: "Date Created",
          value: formatDate(pledge.createDate as string),
        });
        dataMap.push({
          key: key++,
          label: "Date Updated",
          value: formatDate(pledge.updateDate as string),
        });

        if (pledge.donor) {
          dataMap.push({
            key: key++,
            label: "Donor ID",
            value: (
              <Space>
                <span>{pledge.donor.raiserId}</span>
                <Tooltip title="Donor Details">
                  <Link to={`/donorDetails/${pledge.donor.id}`}>
                    Show Details
                  </Link>
                </Tooltip>
              </Space>
            ),
          });
          dataMap.push({
            key: key++,
            label: "Donor First Name",
            value: pledge.donor.firstName,
          });
          dataMap.push({
            key: key++,
            label: "Donor Last Name",
            value: pledge.donor.lastName,
          });
          dataMap.push({
            key: key++,
            label: "Donor Org Name",
            value: pledge.donor.orgName,
          });
          dataMap.push({
            key: key++,
            label: "Donor Email",
            value: pledge.donor.email,
          });
          dataMap.push({
            key: key++,
            label: "Gift Amount",
            value: formatCurrency(pledge.amount as number),
          });
          dataMap.push({
            key: key++,
            label: "Pledge Date",
            value: formatDate(pledge.giftDate as string),
          });
          dataMap.push({
            key: key++,
            label: "Pledge Status",
            value: pledge.pledgeStatus,
          });
          dataMap.push({
            key: key++,
            label: "Appeal",
            value: pledge.appeal,
          });
          dataMap.push({
            key: key++,
            label: "Email Acknowledgement Date",
            value: formatDate(pledge.emailDate as string),
          });
          dataMap.push({
            key: key++,
            label: "Volunteer 1 Name",
            value: pledge.volunteer1?.fullName,
          });
          dataMap.push({
            key: key++,
            label: "Volunteer 1 Phone",
            value: pledge.volunteer1?.phone,
          });
          dataMap.push({
            key: key++,
            label: "Volunteer 2 Name",
            value: pledge.volunteer2?.fullName,
          });
          dataMap.push({
            key: key++,
            label: "Volunteer 2 Phone",
            value: pledge.volunteer2?.phone,
          });
          dataMap.push({
            key: key++,
            label: "Contribution Type",
            value: pledge.paymentMethod,
          });
          dataMap.push({
            key: key++,
            label: "Payment Method",
            value: pledge.paymentType,
          });

          if (pledge.ppp) {
            dataMap.push({
              key: key++,
              label: "PPP Monthly Instalment Amount",
              value: formatCurrency(pledge.monthlyInstalment as number),
            });
            dataMap.push({
              key: key++,
              label: "PPP Number of Installments",
              value: pledge.numberInstalments,
            });
            dataMap.push({
              key: key++,
              label: "PPP Latest Installment Date",
              value: formatDate(pledge.latestInstalment as string),
            });
            dataMap.push({
              key: key++,
              label: "PPP Pledge End Date",
              value: formatDate(pledge.endDateInstalments as string),
            });
            dataMap.push({
              key: key++,
              label: "PPP Until Cancelled",
              value: formatBoolean(pledge.untilCancelled),
            });
            dataMap.push({
              key: key++,
              label: "PPP Monthly Transaction Date",
              value: formatDate(pledge.monthlyTransactionDate as string),
            });
            dataMap.push({
              key: key++,
              label: "PPP Void Cheque Enclosed",
              value: formatBoolean(pledge.voidChequeEnclosed),
            });
          }

          dataMap.push({
            key: key++,
            label: "EFT",
            value: formatBoolean(pledge.eft),
          });
          dataMap.push({
            key: key++,
            label: "Processed OnLine",
            value: formatBoolean(pledge.processedOnline),
          });
          dataMap.push({
            key: key++,
            label: "Entered Date",
            value: formatDate(pledge.enteredDate as string),
          });
          dataMap.push({
            key: key++,
            label: "Entered By",
            value: pledge.enteredBy,
          });
          dataMap.push({
            key: key++,
            label: "Notes",
            value: pledge.comment,
          });

          if (pledge.collectionList && pledge.collectionList.length > 0) {
            const collectionDisplayColumns: ColumnsType<Collection> = [
              {
                title:
                  pledge.paymentType &&
                  allCheckCollections.includes(pledge.paymentType)
                    ? "Check Number"
                    : "Cash Denomination",
                dataIndex:
                  pledge.paymentType &&
                  allCheckCollections.includes(pledge.paymentType)
                    ? "checkNumber"
                    : "cashDenominationDisplay",
                key:
                  pledge.paymentType &&
                  allCheckCollections.includes(pledge.paymentType)
                    ? "checkNumber"
                    : "cashDenominationDisplay",
              },
              {
                title:
                  pledge.paymentType &&
                  allCheckCollections.includes(pledge.paymentType)
                    ? "Check Amount"
                    : "Cash Amount",
                dataIndex:
                  pledge.paymentType &&
                  allCheckCollections.includes(pledge.paymentType)
                    ? "checkAmount"
                    : "cashNumber",
                key:
                  pledge.paymentType &&
                  allCheckCollections.includes(pledge.paymentType)
                    ? "checkAmount"
                    : "cashNumber",
                render: (amount: number, record: Collection) =>
                  pledge.paymentType &&
                  allCheckCollections.includes(pledge.paymentType)
                    ? formatCurrency(amount)
                    : formatCurrency(
                        (record.cashDenomination! / 100) * record.cashNumber!
                      ),
              },
            ];

            dataMap.push({
              key: key++,
              label: "Collections",
              value: (
                <Table
                  dataSource={pledge.collectionList}
                  columns={collectionDisplayColumns}
                  pagination={false}
                  rowKey="id"
                />
              ),
            });
          }
        }

        if (pledge.deposit) {
          dataMap.push({
            key: key++,
            label: "Deposit Number",
            value: pledge.deposit.number,
          });
          dataMap.push({
            key: key++,
            label: "Deposit Date",
            value: formatDate(pledge.deposit.date as string),
          });
          dataMap.push({
            key: key++,
            label: "Deposit Amount",
            value: formatCurrency(pledge.deposit.amount as number),
          });
        }

        if (pledge.batch) {
          dataMap.push({
            key: key++,
            label: "Batch Name",
            value: pledge.batch.name,
          });
        }

        if (pledge.voidDate) {
          dataMap.push({
            key: key++,
            label: "Date Voided",
            value: formatDate(pledge.voidDate as string),
          });
        }

        if (pledge.taxReceipt) {
          dataMap.push({
            key: key++,
            label: "Tax Receipt",
            value: formatBoolean(pledge.taxReceipt),
          });
        }

        setDisplayState({ pledge: pledge, dataMap: dataMap });
      } catch (err) {
        const error = (err as Error).message;
        setDisplayState({ error: error });
      }
    }

    loadForm();
  }, [id]);

  return (
    <div>
      {!displayState.pledge && (
        <div className="loading">
          <Spin size="large" />
        </div>
      )}
      {!displayState.pledge && displayState.error && (
        <Exception exceptionDetails={displayState.error} />
      )}
      {displayState.pledge && (
        <>
          <BackButton buttonText="Back to search page" />
          <Title className="text-center">Form Details</Title>
          {displayState.error && (
            <Exception exceptionDetails={displayState.error} />
          )}
          <ShowDetails dataMap={displayState.dataMap} />
          <TransactionList transactions={displayState.pledge.transactionList} />
        </>
      )}
    </div>
  );
};
