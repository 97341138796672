import { Button, Form, Spin, Typography } from "antd";
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { Exception } from "../../components/Exception";
import { JkSelect } from "../../components/JkSelect";
import { RegionSelect } from "../../components/RegionSelect";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useCacheContext } from "../../hooks/useCacheContext";
import { User, ReportType, Deposit } from "../../types";
import { CommonUtils } from "../../utils/CommonUtils";

type Display = {
  reportType: ReportType;
};

type ReportDisplay = {
  header: string;
  restUrl: string;
  reportName: string;
};

export const DepositReport = () => {
  const { state }: { state: any } = useLocation();
  const display: Display = state;

  const [reportDisplay, setReportDisplay] = useState<ReportDisplay | null>(
    null
  );

  const { user: _sessionUser } = useAuthContext();
  const sessionUser = useRef<User>(_sessionUser).current as User;

  const [form] = Form.useForm();
  const [error, setError] = useState<string | null>(null);
  const [isPending, setIsPending] = useState(false);

  const { isCacheReady } = useCacheContext();

  const { Title } = Typography;

  useEffect(() => {
    setError(null);

    if (display) {
      if (display.reportType === ReportType.DEPOSIT) {
        setReportDisplay({
          header: "Deposit Report",
          restUrl: "/deposits/depositReport",
          reportName: "DepositReport.xlsx",
        });
      }
    }
  }, [display]);

  const { fetchBlob, downloadFile, getUserJk } = CommonUtils();

  const onFinish = async (deposit: Deposit) => {
    setError(null);

    if (!reportDisplay) {
      setError("Report display not set");
      return;
    }

    setIsPending(true);

    try {
      const blob = await fetchBlob(reportDisplay.restUrl, deposit);
      downloadFile(blob, reportDisplay.reportName);

      setIsPending(false);
    } catch (err) {
      const errorMessage = (err as Error).message;
      setError(errorMessage);
      setIsPending(false);
    }
  };

  return (
    <div>
      <>
        {!isCacheReady && !reportDisplay && (
          <div className="loading">
            <Spin size="large" />
          </div>
        )}
        {isCacheReady && reportDisplay && (
          <>
            <Title className="text-center">{reportDisplay.header}</Title>
            {error && <Exception exceptionDetails={error} />}
            <Form
              form={form}
              name="basic"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 8 }}
              onFinish={onFinish}
              autoComplete="off"
              initialValues={{
                jamatKhana: getUserJk(sessionUser),
              }}
            >
              <Form.Item label="Region" name={["jamatKhana", "region", "id"]}>
                <RegionSelect />
              </Form.Item>
              <Form.Item
                noStyle
                shouldUpdate={(prev, curr) =>
                  prev.jamatKhana.region.id !== curr.jamatKhana.region.id
                }
              >
                {(form) => {
                  return <JkSelect form={form} />;
                }}
              </Form.Item>
              <Form.Item wrapperCol={{ offset: 8, span: 8 }}>
                {!isPending && (
                  <Button type="primary" htmlType="submit">
                    Generate Report
                  </Button>
                )}
                {isPending && (
                  <Button type="primary" disabled>
                    Generating...
                  </Button>
                )}
              </Form.Item>
            </Form>
          </>
        )}
      </>
    </div>
  );
};
