import {
  Button,
  Collapse,
  Form,
  Input,
  InputNumber,
  Space,
  Spin,
  Table,
  Typography,
} from "antd";
import { ColumnsType } from "antd/lib/table";
import { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Exception } from "../../components/Exception";
import { JkSelect } from "../../components/JkSelect";
import { RegionSelect } from "../../components/RegionSelect";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useCacheContext } from "../../hooks/useCacheContext";
import { Donor, FLOW_TYPE, Pledge, User } from "../../types";
import { CommonUtils } from "../../utils/CommonUtils";

export const SearchDonors = () => {
  const { search, state }: { search: string; state: any } = useLocation();
  const display: Pledge = state;

  const { user: _sessionUser } = useAuthContext();
  const sessionUser = useRef<User>(_sessionUser).current as User;

  const [donors, setDonors] = useState<Donor[] | null>(null);

  const { isCacheReady, envInfo } = useCacheContext();

  const [error, setError] = useState<string | null>(null);
  const [isPending, setIsPending] = useState(false);

  const [openPanels, setOpenPanels] = useState<string | string[]>(["1"]);

  const [formInstance] = Form.useForm();
  const { Panel } = Collapse;
  const { Title } = Typography;
  const navigate = useNavigate();

  const { serialize, stringComparator, getUserJk } = CommonUtils();

  const donorDisplayColumns: ColumnsType<Donor> = [
    {
      title: "Region",
      dataIndex: ["jamatKhana", "region", "name"],
      key: "region",
      sorter: (a: Donor, b: Donor) =>
        stringComparator(
          a.jamatKhana?.region?.name,
          b.jamatKhana?.region?.name
        ),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Jamat Khana",
      dataIndex: ["jamatKhana", "name"],
      key: "jamatKhana",
      sorter: (a: Donor, b: Donor) =>
        stringComparator(a.jamatKhana?.name, b.jamatKhana?.name),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Donor ID",
      dataIndex: "raiserId",
      key: "raiserId",
    },
    {
      title: "Name",
      dataIndex: "fullName",
      key: "fullName",
      defaultSortOrder: "ascend",
      sorter: (a: Donor, b: Donor) => a.fullName!.localeCompare(b.fullName!),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Deceased",
      dataIndex: "deceased",
      key: "deceased",
      render: (flag: boolean) => (flag ? "Yes" : "No"),
    },
    {
      title: "Spouse Name",
      dataIndex: "spouseFullName",
      key: "spouseFullName",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Org Name",
      dataIndex: "orgName",
      key: "orgName",
    },
    {
      title: "Phone",
      dataIndex: "displayPhone",
      key: "displayPhone",
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (_text, record) => (
        <Space size="small" direction="vertical">
          {!display && (
            <>
              <Link to={`/donorDetails/${record.id}`}>Show Details</Link>
              {(sessionUser.auth?.isDs || sessionUser.auth?.isCfc) && (
                <>
                  <Link
                    to="/editPledge"
                    state={{
                      donor: { id: record.id },
                      flowType: FLOW_TYPE.ENTER,
                    }}
                  >
                    {envInfo?.messagesMap["assignPledge"]}
                  </Link>
                  <Link
                    to="/editPledge"
                    state={{
                      donor: { id: record.id },
                      flowType: FLOW_TYPE.ONLINE,
                    }}
                  >
                    Online Contribution
                  </Link>
                  <Link to={`/changeDonorDetails/${record.id}`}>
                    Submit Change Requests
                  </Link>
                  {envInfo?.isAkfc && sessionUser.auth?.canUpdateDonors && (
                    <Link to={`/editDonor/${record.id}`}>Edit Donor</Link>
                  )}
                </>
              )}
            </>
          )}
          {display && (
            <Link
              to="/reassignPledge"
              state={{ id: display.id, newDonor: { id: record.id } }}
            >
              Reassign Pledge
            </Link>
          )}
        </Space>
      ),
    },
  ];

  useEffect(() => {
    setError(null);
    setOpenPanels("1");

    const fetchDonors = async () => {
      if (search) {
        setIsPending(true);

        try {
          const { fetchFc } = CommonUtils();
          const result = await fetchFc<Donor[]>(`/donors${search}`, "GET");

          if (result.length === 0) {
            setError("No donor found matching your search criteria");
          } else {
            setDonors(result);
            setOpenPanels("2");
          }

          setIsPending(false);
        } catch (err) {
          const errorMessage = (err as Error).message;
          setError(errorMessage);
          setIsPending(false);
        }
      }
    };

    fetchDonors();
  }, [search]);

  const onFinish = async (donor: Donor) => {
    if (
      !donor.raiserId &&
      !donor.firstName &&
      !donor.lastName &&
      !donor.email &&
      !donor.resPhone &&
      !donor.reGiftId &&
      !donor.orgName &&
      !donor.addressLine1 &&
      !donor.jamatKhana?.region?.id &&
      !donor.jamatKhana?.id
    ) {
      setError("At least one search criteria is required");
      return;
    }

    const searchString = serialize(donor);
    navigate("/searchDonors?" + searchString, { state: display });
  };

  return (
    <>
      {!isCacheReady && (
        <div className="loading">
          <Spin size="large" />
        </div>
      )}
      {isCacheReady && (
        <>
          <Title className="text-center">Search Donors</Title>
          {error && <Exception exceptionDetails={error} />}
          <Collapse
            defaultActiveKey={["1"]}
            activeKey={openPanels}
            onChange={setOpenPanels}
          >
            <Panel header="Search Panel" key="1">
              <Form
                form={formInstance}
                name="basic"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 8 }}
                onFinish={onFinish}
                autoComplete="off"
                initialValues={{ jamatKhana: getUserJk(sessionUser) }}
              >
                <Form.Item label="Donor ID" name="raiserId">
                  <InputNumber
                    placeholder="Donor ID"
                    style={{ width: "100%" }}
                    controls={false}
                  />
                </Form.Item>
                <Form.Item label="First Name" name="firstName">
                  <Input placeholder="First Name" />
                </Form.Item>
                <Form.Item label="Last Name" name="lastName">
                  <Input placeholder="Last Name" />
                </Form.Item>
                <Form.Item label="Email Address" name="email">
                  <Input type="email" placeholder="first.last@ds.akdn.org" />
                </Form.Item>
                <Form.Item label="Phone Number" name="resPhone">
                  <Input placeholder="(111) 222-3333" />
                </Form.Item>
                <Form.Item label="Org Name" name="orgName">
                  <Input placeholder="Org Name" />
                </Form.Item>
                <Form.Item label="Address" name="addressLine1">
                  <Input placeholder="Address" />
                </Form.Item>
                <Form.Item label="RE Gift ID" name="reGiftId">
                  <Input placeholder="RE Gift ID" />
                </Form.Item>
                <Form.Item label="Region" name={["jamatKhana", "region", "id"]}>
                  <RegionSelect isRestricted={false} />
                </Form.Item>
                <Form.Item
                  noStyle
                  shouldUpdate={(prev, curr) =>
                    prev.jamatKhana.region.id !== curr.jamatKhana.region.id
                  }
                >
                  {(form) => {
                    return <JkSelect form={form} isRestricted={false} />;
                  }}
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 8, span: 8 }}>
                  {!isPending && (
                    <Button type="primary" htmlType="submit">
                      Search Donors
                    </Button>
                  )}
                  {isPending && (
                    <Button type="primary" disabled>
                      Searching...
                    </Button>
                  )}
                </Form.Item>
              </Form>
            </Panel>
            {donors && (
              <Panel header="Result Panel" key="2">
                <Table
                  dataSource={donors}
                  columns={donorDisplayColumns}
                  pagination={{
                    total: donors.length,
                    showTotal: (t) => `${t} results`,
                    defaultPageSize: 20,
                    defaultCurrent: 1,
                  }}
                  rowKey="id"
                />
              </Panel>
            )}
          </Collapse>
        </>
      )}
    </>
  );
};
