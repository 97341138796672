import { Button, DatePicker, Form, Typography } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Exception } from "../../components/Exception";
import { ConfirmDetails, Donor } from "../../types";
import { CommonUtils } from "../../utils/CommonUtils";

export const SendDonorChangeEmail = () => {
  const [error, setError] = useState<string | null>(null);
  const [isPending, setIsPending] = useState(false);

  const { Title } = Typography;
  const { RangePicker } = DatePicker;
  const navigate = useNavigate();

  const { fetchFc } = CommonUtils();

  const onFinish = async (donor: Donor) => {
    setIsPending(true);
    setError(null);

    try {
      if (!donor.dateRange) {
        setIsPending(false);
        setError("Date Range is required");
        return;
      }

      const startDateStr = donor.dateRange[0].format("DD/MM/YYYY");
      const endDateStr = donor.dateRange[1].format("DD/MM/YYYY");
      donor = { ...donor, startDateStr, endDateStr, dateRange: [] };

      const edited = await fetchFc<any>("/users/sendDonorChangeEmail", "POST");

      const confirmDetails: ConfirmDetails = {
        title: "Send Donor Change Emails",
        subTitle: edited.message,
      };

      navigate("/confirm", { state: confirmDetails });
    } catch (err) {
      setError((err as Error).message);
      setIsPending(false);
    }
  };

  return (
    <div>
      <Title className="text-center">Send Donor Change Emails</Title>
      {error && <Exception exceptionDetails={error} />}
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 8 }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          name="dateRange"
          label="Date Range"
          rules={[{ required: true, message: "Date Range is required" }]}
        >
          <RangePicker />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 8, span: 8 }}>
          {!isPending && (
            <Button type="primary" htmlType="submit">
              Send Donor Change Emails
            </Button>
          )}
          {isPending && (
            <Button type="primary" disabled>
              Sending Donor Change Emails...
            </Button>
          )}
        </Form.Item>
      </Form>
    </div>
  );
};
