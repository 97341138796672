import { Button, Form, Input, Select, Typography } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Exception } from "../../components/Exception";
import { ConfirmDetails } from "../../types";
import { CommonUtils } from "../../utils/CommonUtils";

type EditUserBulkInput = {
  users: string;
  type: string;
};

export const EditUserBulk = () => {
  const [error, setError] = useState<string | null>(null);
  const [isPending, setIsPending] = useState(false);

  const { Title } = Typography;
  const { Option } = Select;
  const navigate = useNavigate();

  const { TextArea } = Input;

  const { fetchFc } = CommonUtils();

  const onFinish = async (values: EditUserBulkInput) => {
    setIsPending(true);
    setError(null);

    try {
      const edited = await fetchFc<string[]>(
        "/users/editUsersBulk",
        "POST",
        values
      );

      const confirmDetails: ConfirmDetails = {
        title: "User(s) Edited",
        subTitle: edited,
      };

      navigate("/confirm", { state: confirmDetails });
    } catch (err) {
      setError((err as Error).message);
      setIsPending(false);
    }
  };

  return (
    <div>
      <Title className="text-center">Edit User Bulk</Title>
      {error && <Exception exceptionDetails={error} />}
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 8 }}
        onFinish={onFinish}
        autoComplete="off"
        initialValues={{ type: "reset_password" }}
      >
        <Form.Item
          label="Users To Edit"
          name="users"
          rules={[{ required: true, message: "Please input the login IDs!" }]}
        >
          <TextArea placeholder="Login IDs - One per line" />
        </Form.Item>
        <Form.Item
          label="Type of Edit"
          name="type"
          rules={[{ required: true, message: "Please input Edit Type!" }]}
        >
          <Select>
            <Option key="reset_password" value="reset_password">
              Reset Password
            </Option>
            <Option key="disable_account" value="disable_account">
              Disable Account
            </Option>
          </Select>
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 8, span: 8 }}>
          {!isPending && (
            <Button type="primary" htmlType="submit">
              Edit Users
            </Button>
          )}
          {isPending && (
            <Button type="primary" disabled>
              Editing Users...
            </Button>
          )}
        </Form.Item>
      </Form>
    </div>
  );
};
