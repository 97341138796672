import {
  Button,
  Collapse,
  DatePicker,
  Form,
  Input,
  Popconfirm,
  Space,
  Spin,
  Table,
  Typography,
} from "antd";
import { ColumnsType } from "antd/lib/table";
import { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Exception } from "../../components/Exception";
import { JkSelect } from "../../components/JkSelect";
import { RegionSelect } from "../../components/RegionSelect";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useCacheContext } from "../../hooks/useCacheContext";
import { User, FLOW_TYPE, ConfirmDetails, Batch } from "../../types";
import { CommonUtils } from "../../utils/CommonUtils";

type PageState = {
  error: string | null;
  isPending: boolean;
  batches?: Batch[];
  openPanels: string | string[];
};

const initialPageState: PageState = {
  error: null,
  isPending: false,
  openPanels: "1",
};

export const SearchBatches = () => {
  const { search }: { search: string } = useLocation();

  const { user: _sessionUser } = useAuthContext();
  const sessionUser = useRef<User>(_sessionUser).current as User;

  const [pageState, setPageState] = useState<PageState>(initialPageState);

  const { isCacheReady } = useCacheContext();

  const [formInstance] = Form.useForm();
  const { Panel } = Collapse;
  const { RangePicker } = DatePicker;
  const { Link: AntLink, Text, Title } = Typography;
  const navigate = useNavigate();

  const { serialize, stringComparator, getUserJk, formatDate } = CommonUtils();

  useEffect(() => {
    const fetchBatches = async () => {
      if (search) {
        setPageState((prevState) => {
          return { ...prevState, isPending: true };
        });

        try {
          const { fetchFc } = CommonUtils();
          const result = await fetchFc<Batch[]>(`/batches${search}`, "GET");

          if (result.length === 0) {
            setPageState((prevState) => {
              return {
                ...prevState,
                error: `No batches found matching the search criteria`,
                isPending: false,
              };
            });
          } else {
            setPageState((prevState) => {
              return {
                ...prevState,
                batches: result,
                openPanels: "2",
                isPending: false,
              };
            });
          }
        } catch (err) {
          const errorMessage = (err as Error).message;
          setPageState((prevState) => {
            return { ...prevState, error: errorMessage, isPending: false };
          });
        }
      }
    };

    fetchBatches();
  }, [search]);

  const onFinish = async (batch: Batch) => {
    if (
      !batch.name &&
      !batch.dateRange &&
      !batch.jamatKhana?.region?.id &&
      !batch.jamatKhana?.id
    ) {
      setPageState((prevState) => {
        return { ...prevState, error: "No search criteria provided" };
      });

      return;
    }

    if (batch.dateRange) {
      const startDateStr = batch.dateRange[0].format("DD/MM/YYYY");
      const endDateStr = batch.dateRange[1].format("DD/MM/YYYY");
      batch = { ...batch, startDateStr, endDateStr, dateRange: [] };
    }

    const searchString = serialize(batch);
    navigate(`/searchBatches?${searchString}`);
  };

  const resetBatch = async (batch: Batch) => {
    setPageState((prevState) => {
      return { ...prevState, error: null, isPending: true };
    });

    try {
      const { fetchFc } = CommonUtils();
      const result = await fetchFc<Batch>(
        `/batches/reset/${batch.id}`,
        "POST",
        {
          ...batch,
          flowInd: FLOW_TYPE.RESET,
        }
      );

      const confirmDetails: ConfirmDetails = {
        title: "Reset Successful",
        subTitle: `${result.name} successfully reset`,
        returnUrl: `/searchBatches${search}`,
      };

      navigate("/confirm", { state: confirmDetails });
    } catch (err) {
      const errorMessage = (err as Error).message;
      setPageState((prevState) => {
        return { ...prevState, error: errorMessage, isPending: false };
      });
    }
  };

  const batchDisplayColumns: ColumnsType<Batch> = [
    {
      title: "Region",
      dataIndex: ["jamatKhana", "region", "name"],
      key: "region",
      sorter: (a: Batch, b: Batch) =>
        stringComparator(
          a.jamatKhana?.region?.name,
          b.jamatKhana?.region?.name
        ),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Jamat Khana",
      dataIndex: ["jamatKhana", "name"],
      key: "jamatKhana",
      sorter: (a: Batch, b: Batch) =>
        stringComparator(a.jamatKhana?.name, b.jamatKhana?.name),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Batch Name",
      dataIndex: "name",
      key: "name",
      defaultSortOrder: "ascend",
      sorter: (a: Batch, b: Batch) => a.name!.localeCompare(b.name!),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "# of forms",
      dataIndex: "numberOfForms",
      key: "numberOfForms",
    },
    {
      title: "Submitted By",
      dataIndex: "submittedBy",
      key: "submittedBy",
    },
    {
      title: "Date Created",
      dataIndex: "createDate",
      key: "createDate",
      render: (date: string) => formatDate(date),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (_text, record) => (
        <Space size="small" direction="vertical">
          <Link to={`/batchDetails/${record.id}`}>Show Details</Link>
          <Link to={`/printBatch/${record.id}`} state={{ fromPage: "search" }}>
            Print Batch
          </Link>
          <Popconfirm
            title="Reset Batch"
            onConfirm={() => resetBatch(record)}
            okButtonProps={{ loading: pageState.isPending }}
            placement="topLeft"
          >
            <AntLink>
              <Text type="danger">Reset Batch</Text>
            </AntLink>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <>
      {(!isCacheReady || pageState.isPending) && (
        <div className="loading">
          <Spin size="large" />
        </div>
      )}
      {isCacheReady && !pageState.isPending && (
        <>
          <Title className="text-center">Search Batches</Title>
          {pageState.error && <Exception exceptionDetails={pageState.error} />}
          <Collapse
            defaultActiveKey={["1"]}
            activeKey={pageState.openPanels}
            onChange={(key) =>
              setPageState((prevState) => {
                return { ...prevState, openPanels: key };
              })
            }
          >
            <Panel header="Search Panel" key="1">
              <Form
                form={formInstance}
                name="basic"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 8 }}
                onFinish={onFinish}
                autoComplete="off"
                initialValues={{
                  jamatKhana: getUserJk(sessionUser),
                }}
              >
                <Form.Item label="Batch Name" name="name">
                  <Input placeholder="Batch Name" />
                </Form.Item>
                <Form.Item name="dateRange" label="Date Range">
                  <RangePicker />
                </Form.Item>
                <Form.Item label="Region" name={["jamatKhana", "region", "id"]}>
                  <RegionSelect />
                </Form.Item>
                <Form.Item
                  noStyle
                  shouldUpdate={(prev, curr) =>
                    prev.jamatKhana.region.id !== curr.jamatKhana.region.id
                  }
                >
                  {(form) => {
                    return <JkSelect form={form} />;
                  }}
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 8, span: 8 }}>
                  {!pageState.isPending && (
                    <Button type="primary" htmlType="submit">
                      Search Batches
                    </Button>
                  )}
                  {pageState.isPending && (
                    <Button type="primary" disabled>
                      Searching...
                    </Button>
                  )}
                </Form.Item>
              </Form>
            </Panel>
            {pageState.batches && (
              <Panel header="Result Panel" key="2">
                <Table
                  dataSource={pageState.batches}
                  columns={batchDisplayColumns}
                  pagination={{
                    total: pageState.batches.length,
                    showTotal: (t) => `${t} results`,
                    defaultPageSize: 20,
                    defaultCurrent: 1,
                  }}
                  rowKey="id"
                />
              </Panel>
            )}
          </Collapse>
        </>
      )}
    </>
  );
};
