import { Select } from "antd";
import { useEffect, useRef, useState } from "react";
import { useAuthContext } from "../hooks/useAuthContext";
import { useCacheContext } from "../hooks/useCacheContext";
import { Region, User } from "../types";

type RegionSelectInput = {
  value?: number;
  onChange?: (regionId: number) => void;
  isRestricted?: boolean;
};

export const RegionSelect: React.FC<RegionSelectInput> = ({
  value,
  onChange,
  isRestricted = true,
}) => {
  const { Option } = Select;

  const { user: _sessionUser } = useAuthContext();
  const sessionUser = useRef<User>(_sessionUser).current as User;

  const { regions, displayRegions, isCacheReady } = useCacheContext();

  const [regionDisplay, setRegionDisplay] = useState<Region[]>([]);

  useEffect(() => {
    if (isCacheReady) {
      if (isRestricted) {
        setRegionDisplay(displayRegions);
      } else {
        setRegionDisplay(regions);
      }
    }
  }, [isCacheReady, isRestricted, displayRegions, regions]);

  return (
    <Select
      showSearch
      optionFilterProp="children"
      filterOption={(input, option) =>
        option!.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      onChange={onChange}
      allowClear={regionDisplay.length > 1}
      defaultValue={
        value || sessionUser.region?.id || sessionUser.jamatKhana?.region?.id
      }
    >
      {regionDisplay &&
        regionDisplay.map((r) => {
          return (
            <Option key={r.name} value={r.id}>
              {r.name}
            </Option>
          );
        })}
    </Select>
  );
};
