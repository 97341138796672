import {
  Form,
  Input,
  Button,
  Typography,
  Select,
  Spin,
  Radio,
  Space,
  RadioChangeEvent,
  Alert,
} from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Exception } from "../../components/Exception";
import { useCacheContext } from "../../hooks/useCacheContext";
import { ConfirmDetails, Donor, Pledge } from "../../types";
import { CommonUtils } from "../../utils/CommonUtils";

export const ChangeDonorDetails = () => {
  const [error, setError] = useState<string | React.ReactNode | null>(null);
  const [isPending, setIsPending] = useState(false);

  const { envInfo } = useCacheContext();

  const [donor, setDonor] = useState<Donor | null>(null);
  const [requestType, setRequestType] = useState<string | null>(null);
  const [selectedPledge, setSelectedPledge] = useState<Pledge | undefined>(
    undefined
  );

  const [formInstance] = Form.useForm();
  const { Title } = Typography;
  const { Option } = Select;
  const navigate = useNavigate();
  const { TextArea } = Input;

  const { fetchFc } = CommonUtils();

  const { id } = useParams();

  useEffect(() => {
    setIsPending(true);

    async function loadDonor() {
      const { fetchFc } = CommonUtils();

      try {
        const donor = await fetchFc<Donor>(
          `/donors/${id}?retrieveDetails=true`,
          "GET"
        );
        setDonor(donor);

        setIsPending(false);
      } catch (err) {
        setError((err as Error).message);
        setIsPending(false);
      }
    }

    loadDonor();
  }, [id]);

  const onFinish = async (editDonor: Donor) => {
    setIsPending(true);
    setError(null);

    try {
      if (requestType === "CHANGE_DETAILS" && !donor?.raiserId) {
        setError("Change Details not allowed for new donors");
        return;
      }

      const pledgeId = selectedPledge?.id;
      const updateDonor = {
        id: donor?.id,
        requestType: requestType,
        comment: editDonor.comment,
        selectedPledge: { id: pledgeId },
      };

      const updated = await fetchFc<any>(
        "/donors/submitDonorChange",
        "POST",
        updateDonor
      );

      const confirmDetails: ConfirmDetails = {
        title: "Donor Change Submitted",
        subTitle: updated.message,
      };

      navigate("/confirm", { state: confirmDetails });
    } catch (err) {
      setError((err as Error).message);
      setIsPending(false);
    }
  };

  const handleRequestTypeChange = (e: RadioChangeEvent) => {
    setRequestType(e.target.value);
  };

  const handleActivePledgeChange = (pledgeId: number) => {
    const pledge = donor?.pledgeList?.find((p) => p.id === pledgeId);
    setSelectedPledge(pledge);
    formInstance.setFieldsValue({ selectedPledge: pledge });
  };

  return (
    <div>
      {(!donor || !envInfo) && (
        <div className="loading">
          <Spin size="large" />
        </div>
      )}
      {donor && envInfo && (
        <>
          <Title className="text-center">
            Submit Change Requests for Donor
          </Title>
          {error && <Exception exceptionDetails={error} />}
          <Form
            form={formInstance}
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 8 }}
            onFinish={onFinish}
            autoComplete="off"
            initialValues={{
              raiserId: donor.raiserId,
              firstName: donor.firstName,
              lastName: donor.lastName,
              orgName: donor.orgName,
              selectedPledge: {},
            }}
          >
            <Form.Item label="Donor ID" name="raiserId">
              <Input disabled={true} />
            </Form.Item>
            <Form.Item label="First Name" name="firstName">
              <Input disabled={true} />
            </Form.Item>
            <Form.Item label="Last Name" name="lastName">
              <Input disabled={true} />
            </Form.Item>
            <Form.Item label="Org Name" name="orgName">
              <Input disabled={true} />
            </Form.Item>
            <Form.Item
              label="Type of change being requested"
              name="requestType"
            >
              <Radio.Group onChange={handleRequestTypeChange}>
                <Space direction="vertical">
                  <Radio value="CHANGE_COMMITMENT">
                    Changes to Existing Pledge
                  </Radio>
                  <Radio value="CHANGE_DETAILS">
                    Change Donor Contact Info
                  </Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
            {requestType && (
              <>
                <Form.Item label="Notice">
                  {requestType === "CHANGE_COMMITMENT" && (
                    <Alert
                      message={envInfo.messagesMap["change.commitment"]}
                      type="info"
                    ></Alert>
                  )}
                  {requestType === "CHANGE_DETAILS" && (
                    <Alert
                      message={
                        <span
                          dangerouslySetInnerHTML={{
                            __html: envInfo?.messagesMap["change.details"],
                          }}
                        ></span>
                      }
                      type="info"
                    ></Alert>
                  )}
                </Form.Item>
                {requestType === "CHANGE_COMMITMENT" &&
                  donor.activePledges?.length === 0 && (
                    <Form.Item label="Alert">
                      <Alert
                        message="No active pledges found for the donor!"
                        type="error"
                      ></Alert>
                    </Form.Item>
                  )}
                {requestType === "CHANGE_COMMITMENT" &&
                  donor.activePledges &&
                  donor.activePledges.length > 0 && (
                    <>
                      <Form.Item label="Current Pledge(s)">
                        <Select onChange={handleActivePledgeChange}>
                          {donor.activePledges.map((r) => {
                            const found = donor.pledgeList?.find(
                              (p) => p["@id"] === r
                            );
                            if (!found) {
                              return <></>;
                            }

                            return (
                              <Option key={found.id} value={found.id}>
                                {found.reGiftId}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                      {selectedPledge && (
                        <>
                          <Form.Item
                            label="Gift Amount"
                            name={["selectedPledge", "amount"]}
                          >
                            <Input disabled={true} />
                          </Form.Item>
                          <Form.Item
                            label="Monthly Instalment"
                            name={["selectedPledge", "monthlyInstalment"]}
                          >
                            <Input disabled={true} />
                          </Form.Item>
                          <Form.Item
                            label="Contribution Type"
                            name={["selectedPledge", "paymentMethod"]}
                          >
                            <Input disabled={true} />
                          </Form.Item>
                          <Form.Item
                            label="Payment Method"
                            name={["selectedPledge", "paymentType"]}
                          >
                            <Input disabled={true} />
                          </Form.Item>
                          <Form.Item
                            label="Pledge Comment"
                            name={["selectedPledge", "comment"]}
                          >
                            <Input disabled={true} />
                          </Form.Item>
                        </>
                      )}
                    </>
                  )}
                {requestType === "CHANGE_DETAILS" && !donor.raiserId && (
                  <Form.Item label="Alert">
                    <Alert
                      message="New donor record! No changes allowed!"
                      type="error"
                    ></Alert>
                  </Form.Item>
                )}
              </>
            )}
            <Form.Item label="Comment" name="comment">
              <TextArea placeholder="Change Details" />
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 8, span: 8 }}>
              {!isPending && (
                <Button type="primary" htmlType="submit">
                  Submit Change
                </Button>
              )}
              {isPending && (
                <Button type="primary" disabled>
                  Submitting Change...
                </Button>
              )}
            </Form.Item>
          </Form>
        </>
      )}
    </div>
  );
};
