import { createContext, useReducer, useEffect } from "react";
import { User } from "../types";
import { CommonUtils } from "../utils/CommonUtils";

type AppState = {
  user: User | null;
  authIsReady: boolean;
  dispatch?: React.Dispatch<Action>;
};

type Action =
  | { type: "LOGIN"; payload: User }
  | { type: "LOGOUT" }
  | { type: "AUTH_IS_READY"; payload: User | null };

export const AuthContext = createContext<AppState>({
  user: null,
  authIsReady: false,
});

export const authReducer = (state: AppState, action: Action): AppState => {
  const { processUserAuth } = CommonUtils();

  switch (action.type) {
    case "LOGIN":
      const user = action.payload;
      processUserAuth(user);
      return { ...state, user, authIsReady: true };
    case "LOGOUT":
      return { ...state, user: null, authIsReady: true };
    case "AUTH_IS_READY":
      const authUser = action.payload;
      if (authUser) {
        processUserAuth(authUser);
      }
      return { ...state, user: authUser, authIsReady: true };
    default:
      return state;
  }
};

interface Props {
  children: React.ReactNode;
}

export const AuthContextProvider: React.FC<Props> = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, {
    user: null,
    authIsReady: false,
  });

  useEffect(() => {
    async function fetchCurrentUser() {
      const { fetchFc } = CommonUtils();

      try {
        const body = await fetchFc<User>("/users/current", "GET");
        dispatch!({ type: "LOGIN", payload: body });
      } catch (err) {
        dispatch!({ type: "AUTH_IS_READY", payload: null });
      }
    }

    fetchCurrentUser();
  }, []);

  return (
    <AuthContext.Provider value={{ ...state, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
};
