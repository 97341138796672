import { Button, Form, Input, Space, Spin, Tooltip, Typography } from "antd";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { BackButton } from "../../components/BackButton";
import { Exception } from "../../components/Exception";
import { ConfirmDetails, Donor, Pledge } from "../../types";
import { CommonUtils } from "../../utils/CommonUtils";

export const ReassignPledge = () => {
  const { state }: { state: any } = useLocation();
  const display: Pledge = state;

  const [pledge, setPledge] = useState<Pledge | null>(null);

  const [error, setError] = useState<string | null>(null);
  const [isPending, setIsPending] = useState(false);

  const { Title } = Typography;

  const navigate = useNavigate();

  const { formatCurrency } = CommonUtils();

  useEffect(() => {
    setIsPending(true);

    async function loadForm() {
      const { fetchFc } = CommonUtils();

      try {
        const form = await fetchFc<Pledge>(
          `/pledges/${display.id}?retrieveDetails=false`,
          "GET"
        );

        if (display.newDonor?.id) {
          if (form.donor?.id === display.newDonor.id) {
            setError("Current Donor and New Donor cannot be the same");
            setIsPending(false);
            setPledge(form);
            return;
          }

          const newDonor = await fetchFc<Donor>(
            `/donors/${display.newDonor.id}?retrieveDetails=false`,
            "GET"
          );
          form.newDonor = newDonor;
        }

        setPledge(form);
        setIsPending(false);
      } catch (err) {
        setError((err as Error).message);
        setIsPending(false);
      }
    }

    loadForm();
  }, [display.id, display?.newDonor?.id]);

  const handleSearchDonor = () => {
    navigate("/searchDonors", {
      state: {
        id: pledge?.id,
      },
    });
  };

  const onFinish = async (formInput: Pledge) => {
    if (!pledge) {
      setError("No Pledge loaded...");
      return;
    }

    setError(null);

    try {
      if (!pledge.newDonor || !pledge.newDonor.id) {
        setError("New Donor ID not defined.");
        return;
      }

      const submitInput: Pledge = {
        id: pledge.id,
        newDonor: { id: pledge.newDonor.id },
      };

      const { fetchFc } = CommonUtils();
      setIsPending(true);
      const result = await fetchFc<Pledge>(
        `/pledges/reassign/${submitInput.id}`,
        "POST",
        submitInput
      );

      const confirmDetails: ConfirmDetails = {
        title: "Reassign Successful",
        subTitle: `${result.formNumber} successfully reassigned`,
      };

      navigate("/confirm", { state: confirmDetails });
    } catch (err) {
      const errorMessage = (err as Error).message;
      setError(errorMessage);
      setIsPending(false);
    }
  };

  return (
    <div>
      {isPending && (
        <div className="loading">
          <Spin size="large" />
        </div>
      )}
      {!isPending && pledge && (
        <>
          <BackButton buttonText="Back to search page" />
          <Title className="text-center">Reassign Pledge</Title>
          {error && <Exception exceptionDetails={error} />}
          <Form
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 8 }}
            autoComplete="off"
            onFinish={onFinish}
            initialValues={{
              formNumber: pledge.formNumber,
              jamatKhana: {
                name: pledge.jamatKhana?.name,
                region: {
                  name: pledge.jamatKhana?.region?.name,
                },
              },
              amount: formatCurrency(pledge.amount as number),
              donor: {
                raiserId: pledge.donor?.raiserId,
                firstName: pledge.donor?.firstName,
                lastName: pledge.donor?.lastName,
                orgName: pledge.donor?.orgName,
              },
              newDonor: {
                raiserId: pledge.newDonor?.raiserId,
                firstName: pledge.newDonor?.firstName,
                lastName: pledge.newDonor?.lastName,
                orgName: pledge.newDonor?.orgName,
              },
            }}
          >
            <Form.Item label="Form Number" name="formNumber">
              <Input disabled={true} />
            </Form.Item>
            <Form.Item label="Region" name={["jamatKhana", "region", "name"]}>
              <Input disabled={true} />
            </Form.Item>
            <Form.Item label="Jamat Khana" name={["jamatKhana", "name"]}>
              <Input disabled={true} />
            </Form.Item>
            <Form.Item label="Gift Amount" name="amount">
              <Input disabled={true} />
            </Form.Item>
            {pledge.donor && (
              <>
                <Form.Item label="Donor ID">
                  <Space>
                    <Form.Item name={["donor", "raiserId"]} noStyle>
                      <Input disabled={true} />
                    </Form.Item>
                    <Tooltip title="Donor Details">
                      <Link to={`/donorDetails/${pledge.donor.id}`}>
                        Show Details
                      </Link>
                    </Tooltip>
                  </Space>
                </Form.Item>
                <Form.Item
                  label="Donor First Name"
                  name={["donor", "firstName"]}
                >
                  <Input disabled={true} />
                </Form.Item>
                <Form.Item label="Donor Last Name" name={["donor", "lastName"]}>
                  <Input disabled={true} />
                </Form.Item>
                <Form.Item label="Donor Org Name" name={["donor", "orgName"]}>
                  <Input disabled={true} />
                </Form.Item>
              </>
            )}
            {pledge.newDonor && (
              <>
                <Form.Item label="New Donor ID">
                  <Space>
                    <Form.Item name={["newDonor", "raiserId"]} noStyle>
                      <Input disabled={true} />
                    </Form.Item>
                    <Tooltip title="Donor Details">
                      <Link to={`/donorDetails/${pledge.newDonor.id}`}>
                        Show Details
                      </Link>
                    </Tooltip>
                  </Space>
                </Form.Item>
                <Form.Item
                  label="New Donor First Name"
                  name={["newDonor", "firstName"]}
                >
                  <Input disabled={true} />
                </Form.Item>
                <Form.Item
                  label="New Donor Last Name"
                  name={["newDonor", "lastName"]}
                >
                  <Input disabled={true} />
                </Form.Item>
                <Form.Item
                  label="New Donor Org Name"
                  name={["newDonor", "orgName"]}
                >
                  <Input disabled={true} />
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 8, span: 8 }}>
                  {!isPending && (
                    <Button type="primary" htmlType="submit">
                      Reassign Pledge
                    </Button>
                  )}
                  {isPending && (
                    <Button type="primary" disabled>
                      Processing...
                    </Button>
                  )}
                </Form.Item>
              </>
            )}
            {!pledge.newDonor && (
              <Form.Item label="New Donor">
                <Typography.Link onClick={() => handleSearchDonor()}>
                  Search New Donor
                </Typography.Link>
              </Form.Item>
            )}
          </Form>
        </>
      )}
    </div>
  );
};
