import { Button, Form, Typography } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Exception } from "../../components/Exception";
import { ConfirmDetails } from "../../types";
import { CommonUtils } from "../../utils/CommonUtils";

export const SendReminderEmails = () => {
  const [error, setError] = useState<string | null>(null);
  const [isPending, setIsPending] = useState(false);

  const { Title } = Typography;
  const navigate = useNavigate();

  const { fetchFc } = CommonUtils();

  const onFinish = async () => {
    setIsPending(true);
    setError(null);

    try {
      const edited = await fetchFc<any>("/users/sendReminderEmails", "POST");

      const confirmDetails: ConfirmDetails = {
        title: "Send Reminder Emails",
        subTitle: edited.message,
      };

      navigate("/confirm", { state: confirmDetails });
    } catch (err) {
      setError((err as Error).message);
      setIsPending(false);
    }
  };

  return (
    <div>
      <Title className="text-center">Send Reminder Emails</Title>
      {error && <Exception exceptionDetails={error} />}
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 8 }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item wrapperCol={{ offset: 8, span: 8 }}>
          {!isPending && (
            <Button type="primary" htmlType="submit">
              Send Reminder Emails
            </Button>
          )}
          {isPending && (
            <Button type="primary" disabled>
              Sending Reminder Emails...
            </Button>
          )}
        </Form.Item>
      </Form>
    </div>
  );
};
