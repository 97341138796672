import { Button } from "antd";
import { useNavigate } from "react-router-dom";

type Input = {
  buttonText: string;
};

export const BackButton: React.FC<Input> = ({ buttonText }) => {
  const navigate = useNavigate();

  return (
    <Button onClick={() => navigate(-1)} style={{ marginLeft: "10px" }}>
      {buttonText}
    </Button>
  );
};
