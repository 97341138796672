import { Button, Space, Spin, Table, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BackButton } from "../../components/BackButton";
import { Exception } from "../../components/Exception";
import { ShowDetails } from "../../components/ShowDetails";
import { TransactionList } from "../../components/TransactionList";
import { DataMap, Deposit, Pledge } from "../../types";
import { CommonUtils } from "../../utils/CommonUtils";

type DisplayState = {
  deposit?: Deposit;
  dataMap?: DataMap[];
  error?: string;
};

export const DepositDetails = () => {
  const [displayState, setDisplayState] = useState<DisplayState>({});

  const { Title } = Typography;

  const navigate = useNavigate();
  const { id } = useParams();

  const { formatCurrency } = CommonUtils();

  useEffect(() => {
    setDisplayState({ error: undefined });

    async function loadDeposit() {
      const { fetchFc, formatDate } = CommonUtils();

      try {
        const deposit = await fetchFc<Deposit>(
          `/deposits/${id}?includeDetails=true`,
          "GET"
        );

        const dataMap: DataMap[] = [];
        let key = 0;
        dataMap.push({ key: key++, label: "Number", value: deposit.number });
        dataMap.push({
          key: key++,
          label: "Region",
          value: deposit.jamatKhana?.region?.name,
        });
        dataMap.push({
          key: key++,
          label: "Jamat Khana",
          value: deposit.jamatKhana?.name,
        });
        dataMap.push({
          key: key++,
          label: "Date Submitted",
          value: formatDate(deposit.date as string),
        });
        dataMap.push({
          key: key++,
          label: "Submitted By",
          value: deposit.submittedBy,
        });
        dataMap.push({
          key: key++,
          label: "Notes",
          value: deposit.comment,
        });

        setDisplayState({ deposit: deposit, dataMap: dataMap });
      } catch (err) {
        const error = (err as Error).message;
        setDisplayState({ error: error });
      }
    }

    loadDeposit();
  }, [id]);

  const formDisplayColumns: ColumnsType<Pledge> = [
    {
      title: "Form Number",
      dataIndex: "formNumber",
      key: "formNumber",
    },
    {
      title: "Donor Name",
      dataIndex: ["donor", "fullName"],
      key: "donorName",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (amount: number) => formatCurrency(amount),
    },
    {
      title: "Payment Method",
      dataIndex: "paymentType",
      key: "paymentType",
    },
  ];

  return (
    <div>
      {!displayState.deposit && (
        <div className="loading">
          <Spin size="large" />
        </div>
      )}
      {!displayState.deposit && displayState.error && (
        <Exception exceptionDetails={displayState.error} />
      )}
      {displayState.deposit && (
        <>
          <Space>
            <BackButton buttonText="Back to search page" />
            <Button
              onClick={() => {
                navigate(`/printDeposit/${id}`, {
                  state: { fromPage: "Deposit Details" },
                });
              }}
            >
              Print Deposit
            </Button>
          </Space>
          <Title className="text-center">Deposit Details</Title>
          {displayState.error && (
            <Exception exceptionDetails={displayState.error} />
          )}
          <ShowDetails dataMap={displayState.dataMap} />
          {displayState.deposit.formList &&
            displayState.deposit.formList.length > 0 && (
              <Table
                dataSource={displayState.deposit.formList}
                columns={formDisplayColumns}
                pagination={false}
                rowKey={(p) => p.id!}
                title={() => (
                  <span style={{ fontWeight: "bold" }}>Pledges</span>
                )}
                className="content-margin"
              />
            )}
          <TransactionList
            transactions={displayState.deposit.transactionList}
          />
        </>
      )}
    </div>
  );
};
