import {
  Button,
  Collapse,
  DatePicker,
  Form,
  Input,
  Popconfirm,
  Space,
  Spin,
  Table,
  Typography,
} from "antd";
import { ColumnsType } from "antd/lib/table";
import { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Exception } from "../../components/Exception";
import { JkSelect } from "../../components/JkSelect";
import { RegionSelect } from "../../components/RegionSelect";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useCacheContext } from "../../hooks/useCacheContext";
import { User, FLOW_TYPE, Deposit, ConfirmDetails } from "../../types";
import { CommonUtils } from "../../utils/CommonUtils";

type PageState = {
  error: string | null;
  isPending: boolean;
  deposits?: Deposit[];
  openPanels: string | string[];
};

const initialPageState: PageState = {
  error: null,
  isPending: false,
  openPanels: "1",
};

export const SearchDeposits = () => {
  const { search }: { search: string } = useLocation();

  const { user: _sessionUser } = useAuthContext();
  const sessionUser = useRef<User>(_sessionUser).current as User;

  const [pageState, setPageState] = useState<PageState>(initialPageState);

  const { isCacheReady } = useCacheContext();

  const [formInstance] = Form.useForm();
  const { Panel } = Collapse;
  const { RangePicker } = DatePicker;
  const { Link: AntLink, Text, Title } = Typography;
  const navigate = useNavigate();

  const { serialize, stringComparator, getUserJk, formatDate, formatCurrency } =
    CommonUtils();

  useEffect(() => {
    const fetchDeposits = async () => {
      if (search) {
        setPageState((prevState) => {
          return { ...prevState, isPending: true };
        });

        try {
          const { fetchFc } = CommonUtils();
          const result = await fetchFc<Deposit[]>(`/deposits${search}`, "GET");

          if (result.length === 0) {
            setPageState((prevState) => {
              return {
                ...prevState,
                error: `No deposits found matching the search criteria`,
                isPending: false,
              };
            });
          } else {
            setPageState((prevState) => {
              return {
                ...prevState,
                deposits: result,
                openPanels: "2",
                isPending: false,
              };
            });
          }
        } catch (err) {
          const errorMessage = (err as Error).message;
          setPageState((prevState) => {
            return { ...prevState, error: errorMessage, isPending: false };
          });
        }
      }
    };

    fetchDeposits();
  }, [search]);

  const onFinish = async (deposit: Deposit) => {
    if (
      !deposit.number &&
      !deposit.dateRange &&
      !deposit.jamatKhana?.region?.id &&
      !deposit.jamatKhana?.id
    ) {
      setPageState((prevState) => {
        return { ...prevState, error: "No search criteria provided" };
      });

      return;
    }

    if (deposit.dateRange) {
      const startDateStr = deposit.dateRange[0].format("DD/MM/YYYY");
      const endDateStr = deposit.dateRange[1].format("DD/MM/YYYY");
      deposit = { ...deposit, startDateStr, endDateStr, dateRange: [] };
    }

    const searchString = serialize(deposit);
    navigate(`/searchDeposits?${searchString}`);
  };

  const resetDeposit = async (deposit: Deposit) => {
    setPageState((prevState) => {
      return { ...prevState, error: null, isPending: true };
    });

    try {
      const { fetchFc } = CommonUtils();
      const result = await fetchFc<Deposit>(
        `/deposits/reset/${deposit.id}`,
        "POST",
        deposit,
      );

      const confirmDetails: ConfirmDetails = {
        title: "Reset Successful",
        subTitle: `${result.number} successfully reset`,
        returnUrl: `/searchDeposits${search}`,
      };

      navigate("/confirm", { state: confirmDetails });
    } catch (err) {
      const errorMessage = (err as Error).message;
      setPageState((prevState) => {
        return { ...prevState, error: errorMessage, isPending: false };
      });
    }
  };

  const verifyDeposit = async (deposit: Deposit) => {
    setPageState((prevState) => {
      return { ...prevState, error: null, isPending: true };
    });

    try {
      const { fetchFc } = CommonUtils();
      const result = await fetchFc<Deposit>(
        `/deposits/verify/${deposit.id}`,
        "POST",
      );

      const confirmDetails: ConfirmDetails = {
        title: "Deposit successfully verified",
        subTitle: `${result.number} successfully verified`,
        returnUrl: `/searchDeposits${search}`,
      };

      navigate("/confirm", { state: confirmDetails });
    } catch (err) {
      const errorMessage = (err as Error).message;
      setPageState((prevState) => {
        return { ...prevState, error: errorMessage, isPending: false };
      });
    }
  };

  const deleteDeposit = async (deposit: Deposit) => {
    setPageState((prevState) => {
      return { ...prevState, error: null, isPending: true };
    });

    try {
      const { fetchFc } = CommonUtils();
      const result = await fetchFc<Deposit>(
        `/deposits/delete/${deposit.id}`,
        "POST",
      );

      const confirmDetails: ConfirmDetails = {
        title: "Deposit successfully deleted",
        subTitle: `${result.number} successfully deleted`,
        returnUrl: `/searchDeposits${search}`,
      };

      navigate("/confirm", { state: confirmDetails });
    } catch (err) {
      const errorMessage = (err as Error).message;
      setPageState((prevState) => {
        return { ...prevState, error: errorMessage, isPending: false };
      });
    }
  };

  const depositDisplayColumns: ColumnsType<Deposit> = [
    {
      title: "Region",
      dataIndex: ["jamatKhana", "region", "name"],
      key: "region",
      sorter: (a: Deposit, b: Deposit) =>
        stringComparator(
          a.jamatKhana?.region?.name,
          b.jamatKhana?.region?.name,
        ),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Jamat Khana",
      dataIndex: ["jamatKhana", "name"],
      key: "jamatKhana",
      sorter: (a: Deposit, b: Deposit) =>
        stringComparator(a.jamatKhana?.name, b.jamatKhana?.name),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Deposit Number",
      dataIndex: "number",
      key: "number",
      defaultSortOrder: "ascend",
      sorter: (a: Deposit, b: Deposit) => a.number!.localeCompare(b.number!),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (amount: number) => formatCurrency(amount),
    },
    {
      title: "Date Submitted",
      dataIndex: "date",
      key: "date",
      render: (date: string) => formatDate(date),
    },
    {
      title: "Date Verified",
      dataIndex: "verifiedDate",
      key: "verifiedDate",
      render: (verifiedDate: string) => formatDate(verifiedDate),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (_text, record) => (
        <Space size="small" direction="vertical">
          <Link to={`/depositDetails/${record.id}`}>Show Details</Link>
          {!record.date && (
            <>
              <Link
                to={`/editDeposit`}
                state={{ flowType: FLOW_TYPE.EDIT, id: record.id }}
              >
                Edit Deposit
              </Link>
            </>
          )}
          {record.date && (
            <>
              <Link
                to={`/printDeposit/${record.id}`}
                state={{ fromPage: "search" }}
              >
                Print Deposit
              </Link>
            </>
          )}
          {(sessionUser.auth?.isAdminOrAkfc || sessionUser.auth?.isCfc) &&
            !record.verifiedDate &&
            record.date && (
              <>
                <Popconfirm
                  title="Verify Deposit"
                  onConfirm={() => verifyDeposit(record)}
                  okButtonProps={{ loading: pageState.isPending }}
                  placement="topLeft"
                >
                  <AntLink>
                    <Text type="success">Verify Deposit</Text>
                  </AntLink>
                </Popconfirm>
              </>
            )}
          {record.formList &&
            record.formList.length > 0 &&
            (sessionUser.auth?.isAdminOrAkfc || !record.date) && (
              <Popconfirm
                title="Reset Deposit"
                onConfirm={() => resetDeposit(record)}
                okButtonProps={{ loading: pageState.isPending }}
                placement="topLeft"
              >
                <AntLink>
                  <Text type="danger">Reset Deposit</Text>
                </AntLink>
              </Popconfirm>
            )}
          {sessionUser.auth?.isAdminOrAkfc &&
            (!record.formList || record.formList.length === 0) &&
            !record.date &&
            !record.verifiedDate && (
              <Popconfirm
                title="Delete Deposit"
                onConfirm={() => deleteDeposit(record)}
                okButtonProps={{ loading: pageState.isPending }}
                placement="topLeft"
              >
                <AntLink>
                  <Text type="danger">Delete Deposit</Text>
                </AntLink>
              </Popconfirm>
            )}
        </Space>
      ),
    },
  ];

  return (
    <>
      {(!isCacheReady || pageState.isPending) && (
        <div className="loading">
          <Spin size="large" />
        </div>
      )}
      {isCacheReady && !pageState.isPending && (
        <>
          <Title className="text-center">Search Deposits</Title>
          {pageState.error && <Exception exceptionDetails={pageState.error} />}
          <Collapse
            defaultActiveKey={["1"]}
            activeKey={pageState.openPanels}
            onChange={(key) =>
              setPageState((prevState) => {
                return { ...prevState, openPanels: key };
              })
            }
          >
            <Panel header="Search Panel" key="1">
              <Form
                form={formInstance}
                name="basic"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 8 }}
                onFinish={onFinish}
                autoComplete="off"
                initialValues={{
                  jamatKhana: getUserJk(sessionUser),
                }}
              >
                <Form.Item label="Deposit Number" name="number">
                  <Input placeholder="Deposit Number" />
                </Form.Item>
                <Form.Item name="dateRange" label="Date Range">
                  <RangePicker />
                </Form.Item>
                <Form.Item label="Region" name={["jamatKhana", "region", "id"]}>
                  <RegionSelect />
                </Form.Item>
                <Form.Item
                  noStyle
                  shouldUpdate={(prev, curr) =>
                    prev.jamatKhana.region.id !== curr.jamatKhana.region.id
                  }
                >
                  {(form) => {
                    return <JkSelect form={form} />;
                  }}
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 8, span: 8 }}>
                  {!pageState.isPending && (
                    <Button type="primary" htmlType="submit">
                      Search Deposits
                    </Button>
                  )}
                  {pageState.isPending && (
                    <Button type="primary" disabled>
                      Searching...
                    </Button>
                  )}
                </Form.Item>
              </Form>
            </Panel>
            {pageState.deposits && (
              <Panel header="Result Panel" key="2">
                <Table
                  dataSource={pageState.deposits}
                  columns={depositDisplayColumns}
                  pagination={{
                    total: pageState.deposits.length,
                    showTotal: (t) => `${t} results`,
                    defaultPageSize: 20,
                    defaultCurrent: 1,
                  }}
                  rowKey="id"
                />
              </Panel>
            )}
          </Collapse>
        </>
      )}
    </>
  );
};
