import { Upload, Button, Typography, Form } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ConfirmDetails } from "../../types";
import { Exception } from "../../components/Exception";
import { UploadFile } from "antd/lib/upload/interface";
import { CommonUtils } from "../../utils/CommonUtils";

export const UploadDonorFile = () => {
  const [uploadFiles, setUploadFiles] = useState<UploadFile[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [isPending, setIsPending] = useState(false);

  const navigate = useNavigate();
  const { Title } = Typography;

  const props = {
    onRemove: () => {
      setUploadFiles([]);
    },
    beforeUpload: (file: UploadFile) => {
      if (
        file.type === "application/vnd.ms-excel" ||
        file.type === "text/csv"
      ) {
        setUploadFiles([file]);
      } else {
        setError("Upload must be CSV File");
      }
      return false;
    },
    fileList: uploadFiles,
    accept: "text/csv",
  };

  const onFinish = async () => {
    if (uploadFiles.length === 0) {
      setError("Please select file to upload");
      return;
    }

    const formData = new FormData();
    formData.append("file", uploadFiles[0] as unknown as Blob);

    setIsPending(true);

    try {
      const { uploadFile } = CommonUtils();

      const result = await uploadFile<any>("/donors/uploadDonors", formData);

      const confirmDetails: ConfirmDetails = {
        title: "Donor File loaded Successfully",
        subTitle: result.message,
      };

      navigate("/confirm", { state: confirmDetails });
    } catch (err) {
      setError((err as Error).message);
      setIsPending(false);
    }
  };

  return (
    <div>
      <Title className="text-center">Upload Donor File</Title>
      {error && <Exception exceptionDetails={error} />}
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 8 }}
        autoComplete="off"
        onFinish={onFinish}
      >
        <Form.Item wrapperCol={{ offset: 8, span: 8 }}>
          <Upload {...props}>
            <Button icon={<UploadOutlined />}>Select CSV File to Upload</Button>
          </Upload>
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 8, span: 8 }}>
          {!isPending && (
            <Button type="primary" htmlType="submit">
              Upload File
            </Button>
          )}
          {isPending && (
            <Button type="primary" disabled>
              Uploading...
            </Button>
          )}
        </Form.Item>
      </Form>
    </div>
  );
};
