import { Alert, List } from "antd";
import { useEffect, useState } from "react";

type ExceptionInput = {
  exceptionDetails: string | React.ReactNode;
};

export const Exception: React.FC<ExceptionInput> = ({ exceptionDetails }) => {
  const [exceptionMessage, setExceptionMessage] = useState<
    string | React.ReactNode | null
  >(null);
  const [details, setDetails] = useState<string[] | null>(null);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    try {
      if (typeof exceptionDetails === "string") {
        const jsonObj = JSON.parse(exceptionDetails);
        setExceptionMessage(jsonObj.message);

        if (jsonObj.messages) {
          setDetails(jsonObj.messages);
        }
      } else {
        setExceptionMessage(exceptionDetails);
      }
    } catch (err) {
      setExceptionMessage(
        exceptionDetails ? exceptionDetails : (err as Error).message
      );
    }
  }, [exceptionDetails]);

  return (
    <div>
      {exceptionMessage && (
        <Alert
          message={
            <>
              {details && (
                <List
                  size="small"
                  header={<div>{exceptionMessage}</div>}
                  dataSource={details}
                  renderItem={(item) => <List.Item>{item}</List.Item>}
                />
              )}
              {!details && <>{exceptionMessage}</>}
            </>
          }
          type="error"
        />
      )}
    </div>
  );
};
