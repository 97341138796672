import { Form, Input, Button, Typography, Select, Spin } from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Exception } from "../../components/Exception";
import { JkSelect } from "../../components/JkSelect";
import { useCacheContext } from "../../hooks/useCacheContext";
import { ConfirmDetails, Donor } from "../../types";
import { CommonUtils } from "../../utils/CommonUtils";

export const EditDonor = () => {
  const { isCacheReady, titleList } = useCacheContext();

  const [error, setError] = useState<string | React.ReactNode | null>(null);
  const [isPending, setIsPending] = useState(false);

  const [donor, setDonor] = useState<Donor | null>(null);

  const [formInstance] = Form.useForm();
  const { Title } = Typography;
  const { Option } = Select;
  const navigate = useNavigate();

  const {
    fetchFc,
    formatPhoneNumber,
    formatPostalCode,
    booleanArray,
    yesNoArray,
    ageRangeArray,
  } = CommonUtils();

  const { id } = useParams();

  useEffect(() => {
    setIsPending(true);

    async function loadDonor() {
      const { fetchFc } = CommonUtils();

      try {
        const donor = await fetchFc<Donor>(`/donors/${id}`, "GET");
        setDonor(donor);

        setIsPending(false);
      } catch (err) {
        setError((err as Error).message);
        setIsPending(false);
      }
    }

    loadDonor();
  }, [id]);

  const onFinish = async (editDonor: Donor) => {
    if (!editDonor.cellPhone && !editDonor.resPhone && !editDonor.busPhone) {
      setError("At least 1 phone number is required for the donor");
      return;
    }

    setIsPending(true);
    setError(null);

    try {
      const updated = await fetchFc<Donor>(`/donors/${id}`, "POST", editDonor);

      const confirmDetails: ConfirmDetails = {
        title: "Donor Updated",
        subTitle: `Donor ID: ${updated.id} updated successfully`,
        returnUrl: `/searchDonors?id=${updated.id}`,
      };

      navigate("/confirm", { state: confirmDetails });
    } catch (err) {
      setError((err as Error).message);
      setIsPending(false);
    }
  };

  return (
    <div>
      {(!isCacheReady || !donor) && (
        <div className="loading">
          <Spin size="large" />
        </div>
      )}
      {isCacheReady && donor && (
        <>
          <Title className="text-center">Update Donor</Title>
          {error && <Exception exceptionDetails={error} />}
          <Form
            form={formInstance}
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 8 }}
            onFinish={onFinish}
            autoComplete="off"
            initialValues={{
              raiserId: donor.raiserId,
              firstName: donor.firstName,
              lastName: donor.lastName,
              email: donor.email,
              resPhone: formatPhoneNumber(donor.resPhone),
              cellPhone: formatPhoneNumber(donor.cellPhone),
              busPhone: formatPhoneNumber(donor.busPhone),
              jamatKhana: {
                id: donor.jamatKhana?.id,
                region: { id: donor.jamatKhana?.region?.id },
              },
              prefix: donor.prefix,
              initial: donor.initial,
              deceased: donor.deceased,
              spouseFullName: donor.spouseFullName,
              spouseDeceased: donor.spouseDeceased,
              ageRange: donor.ageRange,
              orgName: donor.orgName,
              addressLine1: donor.addressLine1,
              addressLine2: donor.addressLine2,
              city: donor.city,
              province: donor.province,
              postalCode: formatPostalCode(donor.postalCode),
              country: donor.country,
              receiveEmail: donor.receiveEmail,
            }}
          >
            <Form.Item label="Donor ID" name="raiserId">
              <Input disabled={true} />
            </Form.Item>
            <Form.Item label="Title" name="prefix">
              <Input disabled={true} />
            </Form.Item>
            <Form.Item label="First Name" name="firstName">
              <Input disabled={true} />
            </Form.Item>
            <Form.Item label="Initial" name="initial">
              <Input disabled={true} />
            </Form.Item>
            <Form.Item label="Last Name" name="lastName">
              <Input disabled={true} />
            </Form.Item>
            <Form.Item label="Spouse Title" name="spouseTitle">
              <Select>
                {titleList &&
                  titleList.map((t) => {
                    return (
                      <Option key={t} value={t}>
                        {t}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
            <Form.Item label="Spouse First Name" name="spouseFirstName">
              <Input placeholder="Spouse First Name" />
            </Form.Item>
            <Form.Item label="Spouse Last Name" name="spouseLastName">
              <Input placeholder="Spouse Last Name" />
            </Form.Item>
            <Form.Item label="Spouse Deceased" name="spouseDeceased">
              <Select>
                {booleanArray.map((t) => {
                  return (
                    <Option key={t.label} value={t.value}>
                      {t.label}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item label="Age Range" name="ageRange">
              <Select>
                {ageRangeArray.map((t) => {
                  return (
                    <Option key={t} value={t}>
                      {t}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item label="Email Address" name="email">
              <Input type="email" placeholder="first.last@ds.akdn.org" />
            </Form.Item>
            <Form.Item label="Cell Phone Number" name="cellPhone">
              <Input placeholder="(111) 222-3333" />
            </Form.Item>
            <Form.Item label="Res Phone Number" name="resPhone">
              <Input placeholder="(111) 222-3333" />
            </Form.Item>
            <Form.Item label="Bus Phone Number" name="busPhone">
              <Input placeholder="(111) 222-3333" />
            </Form.Item>
            <Form.Item label="Org Name" name="orgName">
              <Input disabled={true} />
            </Form.Item>
            <Form.Item label="Address Line 1" name="addressLine1">
              <Input disabled={true} />
            </Form.Item>
            <Form.Item label="Address Line 2" name="addressLine2">
              <Input disabled={true} />
            </Form.Item>
            <Form.Item label="City" name="city">
              <Input disabled={true} />
            </Form.Item>
            <Form.Item label="Province" name="province">
              <Input disabled={true} />
            </Form.Item>
            <Form.Item label="Postal Code" name="postalCode">
              <Input disabled={true} />
            </Form.Item>
            <Form.Item label="Country" name="country">
              <Input disabled={true} />
            </Form.Item>
            <Form.Item
              label="Receive Email"
              name="receiveEmail"
              rules={[
                { required: true, message: "Please input Receive Email" },
              ]}
            >
              <Select>
                {yesNoArray.map((t) => {
                  return (
                    <Option key={t.label} value={t.value}>
                      {t.label}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <JkSelect
              form={formInstance}
              isRequired={true}
              isRestricted={false}
              showAll={true}
            />
            <Form.Item wrapperCol={{ offset: 8, span: 8 }}>
              {!isPending && (
                <Button type="primary" htmlType="submit">
                  Update Donor
                </Button>
              )}
              {isPending && (
                <Button type="primary" disabled>
                  Updating Donor...
                </Button>
              )}
            </Form.Item>
          </Form>
        </>
      )}
    </div>
  );
};
