import { Form, Input, Button, Typography, Select, Spin } from "antd";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Exception } from "../../components/Exception";
import { JkSelect } from "../../components/JkSelect";
import { RegionSelect } from "../../components/RegionSelect";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useCacheContext } from "../../hooks/useCacheContext";
import { ConfirmDetails, User } from "../../types";
import { CommonUtils } from "../../utils/CommonUtils";

export const AddUser = () => {
  const { user: _sessionUser } = useAuthContext();
  const sessionUser = useRef<User>(_sessionUser).current as User;

  const { newUserRoles, isCacheReady } = useCacheContext();

  const [error, setError] = useState<string | null>(null);
  const [isPending, setIsPending] = useState(false);

  const { Title } = Typography;
  const { Option } = Select;
  const navigate = useNavigate();

  const { fetchFc, getUserJk } = CommonUtils();

  const onFinish = async (user: User) => {
    setIsPending(true);
    setError(null);

    try {
      const created = await fetchFc<User>("/users", "POST", user);

      const confirmDetails: ConfirmDetails = {
        title: "User Added",
        subTitle: `User ID: ${created.login} created successfully`,
        returnUrl: "/searchUsers",
      };

      navigate("/confirm", { state: confirmDetails });
    } catch (err) {
      setError((err as Error).message);
      setIsPending(false);
    }
  };

  return (
    <div>
      {!isCacheReady && (
        <div className="loading">
          <Spin size="large" />
        </div>
      )}
      {isCacheReady && (
        <>
          <Title className="text-center">Add User</Title>
          {error && <Exception exceptionDetails={error} />}
          <Form
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 8 }}
            onFinish={onFinish}
            autoComplete="off"
            initialValues={{
              jamatKhana: getUserJk(sessionUser),
            }}
          >
            <Form.Item
              label="First Name"
              name="firstName"
              rules={[
                { required: true, message: "Please input the First Name!" },
              ]}
            >
              <Input placeholder="First Name" />
            </Form.Item>
            <Form.Item
              label="Last Name"
              name="lastName"
              rules={[
                { required: true, message: "Please input the Last Name!" },
              ]}
            >
              <Input placeholder="Last Name" />
            </Form.Item>
            <Form.Item
              label="Email Address"
              name="email"
              rules={[
                { required: true, message: "Please input the Email Address!" },
              ]}
            >
              <Input type="email" placeholder="first.last@ds.akdn.org" />
            </Form.Item>
            <Form.Item
              label="Phone Number"
              name="phone"
              rules={[
                { required: true, message: "Please input the Phone Number!" },
              ]}
            >
              <Input placeholder="(111) 222-3333" />
            </Form.Item>
            <Form.Item
              label="Role"
              name="roleName"
              rules={[{ required: true }]}
            >
              <Select>
                {newUserRoles &&
                  newUserRoles.map((ur) => {
                    return (
                      <Option key={ur.displayName} value={ur.displayName}>
                        {ur.displayName}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
            <Form.Item label="Region" name={["jamatKhana", "region", "id"]}>
              <RegionSelect />
            </Form.Item>
            <Form.Item
              noStyle
              shouldUpdate={(prev, curr) =>
                prev.jamatKhana.region.id !== curr.jamatKhana.region.id
              }
            >
              {(form) => {
                return <JkSelect form={form} />;
              }}
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 8, span: 8 }}>
              {!isPending && (
                <Button type="primary" htmlType="submit">
                  Add User
                </Button>
              )}
              {isPending && (
                <Button type="primary" disabled>
                  Adding User...
                </Button>
              )}
            </Form.Item>
          </Form>
        </>
      )}
    </div>
  );
};
