import { Form, Input, Spin, Typography } from "antd";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { BackButton } from "../../components/BackButton";
import { Exception } from "../../components/Exception";
import { Transaction } from "../../types";
import { CommonUtils } from "../../utils/CommonUtils";

export const TransactionDetails = () => {
  const [transaction, setTransaction] = useState<Transaction | null>(null);

  const [error, setError] = useState<string | null>(null);
  const [isPending, setIsPending] = useState(false);

  const { id } = useParams();

  const { Title } = Typography;

  useEffect(() => {
    setIsPending(true);

    async function loadTransaction() {
      const { fetchFc } = CommonUtils();

      try {
        const transaction = await fetchFc<Transaction>(
          `/transactions/${id}`,
          "GET"
        );
        setTransaction(transaction);

        setIsPending(false);
      } catch (err) {
        setError((err as Error).message);
        setIsPending(false);
      }
    }

    loadTransaction();
  }, [id]);

  return (
    <div>
      {isPending && (
        <div className="loading">
          <Spin size="large" />
        </div>
      )}
      {!isPending && !transaction && error && (
        <Exception exceptionDetails={error} />
      )}
      {!isPending && transaction && (
        <>
          <BackButton buttonText="Back to search page" />
          <Title className="text-center">Transaction Details</Title>
          {error && <Exception exceptionDetails={error} />}
          <Form
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 8 }}
            autoComplete="off"
            initialValues={{
              id: transaction.id,
              typeName: transaction.typeName,
              message: transaction.message,
              comment: transaction.comment,
              user: {
                id: transaction.user?.id,
                login: transaction.user?.login,
                fullName: transaction.user?.fullName,
              },
              donor: {
                id: transaction.donor?.id,
                raiserId: transaction.donor?.raiserId,
                fullName: transaction.donor?.fullName,
              },
              pledge: {
                id: transaction.pledge?.id,
                formNumber: transaction.pledge?.formNumber,
                reGiftId: transaction.pledge?.reGiftId,
              },
              deposit: {
                id: transaction.deposit?.id,
                number: transaction.deposit?.number,
              },
              batch: {
                id: transaction.batch?.id,
                name: transaction.batch?.name,
              },
            }}
          >
            <Form.Item label="Transaction ID" name={["id"]}>
              <Input disabled={true} />
            </Form.Item>
            <Form.Item label="Transaction Type" name={["typeName"]}>
              <Input disabled={true} />
            </Form.Item>
            <Form.Item label="Transaction Message" name={["message"]}>
              <Input disabled={true} />
            </Form.Item>
            <Form.Item label="Transaction Comment" name={["comment"]}>
              <Input disabled={true} />
            </Form.Item>
            <Form.Item label="Transaction Time Taken" name={["timeTaken"]}>
              <Input disabled={true} />
            </Form.Item>
            <Form.Item label="User ID" name={["user", "id"]}>
              <Input disabled={true} />
            </Form.Item>
            <Form.Item label="User Login" name={["user", "login"]}>
              <Input disabled={true} />
            </Form.Item>
            <Form.Item label="User Name" name={["user", "fullName"]}>
              <Input disabled={true} />
            </Form.Item>
            <Form.Item label="Donor ID" name={["donor", "id"]}>
              <Input disabled={true} />
            </Form.Item>
            <Form.Item label="Donor Raiser ID" name={["donor", "raiserId"]}>
              <Input disabled={true} />
            </Form.Item>
            <Form.Item label="Donor Name" name={["donor", "fullName"]}>
              <Input disabled={true} />
            </Form.Item>
            <Form.Item label="Pledge ID" name={["pledge", "id"]}>
              <Input disabled={true} />
            </Form.Item>
            <Form.Item label="Deposit ID" name={["deposit", "id"]}>
              <Input disabled={true} />
            </Form.Item>
            <Form.Item label="Batch ID" name={["batch", "id"]}>
              <Input disabled={true} />
            </Form.Item>
          </Form>
        </>
      )}
    </div>
  );
};
