import { Table, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import moment from "moment";
import { Transaction } from "../types";
import { CommonUtils } from "../utils/CommonUtils";

type TransactionInput = {
  transactions?: Transaction[];
  hideUser?: boolean;
};

export const TransactionList: React.FC<TransactionInput> = ({
  transactions,
  hideUser,
}) => {
  const { Link: AntLink } = Typography;
  const { formatDate, downloadFile } = CommonUtils();

  const transactionDisplayWithUserColumns: ColumnsType<Transaction> = [
    {
      title: "Transaction Date",
      dataIndex: "time",
      key: "time",
      render: (date: string) => formatDate(date),
      width: "22%",
    },
    {
      title: "Transaction User",
      dataIndex: ["user", "fullName"],
      key: "id",
      width: "15%",
    },
    {
      title: "Details",
      dataIndex: "message",
      key: "message",
    },
  ];

  const transactionDisplayWithoutUserColumns: ColumnsType<Transaction> = [
    {
      title: "Transaction Date",
      dataIndex: "time",
      key: "time",
      render: (date: string) => formatDate(date),
      width: "22%",
    },
    {
      title: "Details",
      dataIndex: "message",
      key: "message",
    },
  ];

  const footer = () => {
    return <AntLink onClick={exportToCsv}>Download Transactions</AntLink>;
  };

  function exportToCsv() {
    const processRow = function (transaction: Transaction) {
      return `"${moment(transaction.time).format("YYYY-MM-DD hh:mm:ss")}",${
        transaction.type
      },"${transaction.user?.fullName}","${transaction.message}"\n`;
    };

    if (transactions) {
      let csvFile =
        "Transaction Date,Transaction Type,Transaction User,Details\n";
      for (var i = 0; i < transactions.length; i++) {
        csvFile += processRow(transactions[i]);
      }

      const fileName = `Transactions_${moment().format("YYYYMMDD_hhmmss")}.csv`;
      var blob = new Blob([csvFile], { type: "text/csv;charset=utf-8;" });

      downloadFile(blob, fileName);
    }
  }

  return (
    <div>
      {transactions && transactions.length > 0 && (
        <Table
          dataSource={transactions}
          columns={
            hideUser
              ? transactionDisplayWithoutUserColumns
              : transactionDisplayWithUserColumns
          }
          rowKey="id"
          footer={footer}
          className="content-margin"
        />
      )}
    </div>
  );
};
