import {
  Button,
  Collapse,
  Form,
  Input,
  Popconfirm,
  Select,
  Space,
  Spin,
  Table,
  Typography,
} from "antd";
import { ColumnsType } from "antd/lib/table/Table";
import { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Exception } from "../../components/Exception";
import { JkSelect } from "../../components/JkSelect";
import { RegionSelect } from "../../components/RegionSelect";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useCacheContext } from "../../hooks/useCacheContext";
import { Pledge, User } from "../../types";
import { CommonUtils } from "../../utils/CommonUtils";

export const SearchPledges = () => {
  const { search }: { search: string } = useLocation();

  const { user: _sessionUser } = useAuthContext();
  const sessionUser = useRef<User>(_sessionUser).current as User;

  const [pledges, setPledges] = useState<Pledge[] | null>(null);

  const { isCacheReady } = useCacheContext();

  const [error, setError] = useState<string | null>(null);
  const [isPending, setIsPending] = useState(false);

  const [openPanels, setOpenPanels] = useState<string | string[]>(["1"]);

  const { Panel } = Collapse;
  const { Text, Title } = Typography;
  const { Option } = Select;
  const navigate = useNavigate();

  const { serialize, stringComparator, pledgeStatus, getUserJk } =
    CommonUtils();

  const resetOrVoidForm = async (id: number, flowType: string) => {
    setError(null);
    setIsPending(true);

    try {
      const { fetchFc } = CommonUtils();
      await fetchFc<Pledge>(
        `/pledges/${flowType === "void" ? "void" : "reset"}/${id}`,
        "POST"
      );

      setIsPending(false);
    } catch (err) {
      const errorMessage = (err as Error).message;
      setError(errorMessage);
      setIsPending(false);
    }
  };

  const pledgeDisplayColumns: ColumnsType<Pledge> = [
    {
      title: "Region",
      dataIndex: ["jamatKhana", "region", "name"],
      key: "region",
      sorter: (a: Pledge, b: Pledge) =>
        stringComparator(
          a.jamatKhana?.region?.name,
          b.jamatKhana?.region?.name
        ),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Jamatkhana",
      dataIndex: ["jamatKhana", "name"],
      key: "jamatKhana",
      sorter: (a: Pledge, b: Pledge) =>
        stringComparator(a.jamatKhana?.name, b.jamatKhana?.name),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Form Number",
      key: "formNumber",
      render: (_text, record) =>
        !record.formNumber ? record.reGiftId : record.formNumber,
    },
    {
      title: "Donor ID",
      dataIndex: ["donor", "raiserId"],
      key: "donorId",
    },
    {
      title: "Donor Name",
      dataIndex: ["donor", "fullName"],
      key: "donorName",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (_text, record) => (
        <Space size="small" direction="vertical">
          <Link to={`/pledgeDetails/${record.id}`}>Show Details</Link>
          {sessionUser.auth?.isDs && (
            <>
              {sessionUser.auth?.isAdminOrAkfc &&
                record.donor &&
                !record.reGiftId && (
                  <Link to={`/reassignPledge`} state={{ id: record.id }}>
                    Reassign Form
                  </Link>
                )}
              {sessionUser.auth?.isAdminOrAkfc && record.donor && (
                <Link to="/sendPledgeAcknowledgement" state={{ id: record.id }}>
                  Send Pledge Acknowledgement
                </Link>
              )}
              {(sessionUser.auth?.isAdminOrAkfc ||
                (record.canReset &&
                  !record.reGiftId &&
                  record.receiveDate &&
                  !record.voidDate)) && (
                <Popconfirm
                  title="Void Form"
                  onConfirm={() => resetOrVoidForm(record.id!, "void")}
                  okButtonProps={{
                    loading: isPending,
                  }}
                >
                  <Typography.Link>
                    <Text type="danger">Void Form</Text>
                  </Typography.Link>
                </Popconfirm>
              )}
              {(sessionUser.auth?.isAdminOrAkfc || record.canReset) && (
                <Popconfirm
                  title="Reset Form"
                  onConfirm={() => resetOrVoidForm(record.id!, "reset")}
                  okButtonProps={{
                    loading: isPending,
                  }}
                >
                  <Typography.Link>
                    <Text type="danger">Reset Form</Text>
                  </Typography.Link>
                </Popconfirm>
              )}
            </>
          )}
        </Space>
      ),
    },
  ];

  useEffect(() => {
    const fetchPledges = async () => {
      if (search) {
        setError(null);
        setIsPending(true);

        try {
          const { fetchFc } = CommonUtils();
          const result = await fetchFc<Pledge[]>(`/pledges${search}`, "GET");

          if (result.length === 0) {
            setError("No pledge found matching your search criteria");
          } else {
            setPledges(result);
            setOpenPanels("2");
          }

          setIsPending(false);
        } catch (err) {
          const errorMessage = (err as Error).message;
          setError(errorMessage);
          setIsPending(false);
        }
      }
    };

    fetchPledges();
  }, [search]);

  const onFinish = async (pledge: Pledge) => {
    if (
      !pledge.formNumber &&
      !pledge.reGiftId &&
      !pledge.donor?.raiserId &&
      !pledge.donor?.resPhone &&
      !pledge.pledgeStatus &&
      !pledge.jamatKhana?.region?.id &&
      !pledge.jamatKhana?.id
    ) {
      setError("No search criteria provided");
      return;
    }

    const searchString = serialize(pledge);
    navigate("/searchPledges?" + searchString);
  };

  return (
    <>
      {(!isCacheReady || isPending) && (
        <div className="loading">
          <Spin size="large" />
        </div>
      )}
      {isCacheReady && !isPending && (
        <>
          <Title className="text-center">Search Form(s)</Title>
          {error && <Exception exceptionDetails={error} />}
          <Collapse
            defaultActiveKey={["1"]}
            activeKey={openPanels}
            onChange={setOpenPanels}
          >
            <Panel header="Search Panel" key="1">
              <Form
                name="basic"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 8 }}
                onFinish={onFinish}
                autoComplete="off"
                initialValues={{
                  jamatKhana: getUserJk(sessionUser),
                }}
              >
                <Form.Item label="Form Number" name="formNumber">
                  <Input placeholder="Form Number" />
                </Form.Item>
                <Form.Item label="RE Gift ID" name="reGiftId">
                  <Input placeholder="RE Gift ID" />
                </Form.Item>
                <Form.Item label="Donor ID" name={["donor", "raiserId"]}>
                  <Input placeholder="Donor ID" />
                </Form.Item>
                <Form.Item
                  label="Donor Phone Number"
                  name={["donor", "cellPhone"]}
                >
                  <Input placeholder="(111) 222-3333" />
                </Form.Item>
                <Form.Item label="Pledge Status" name="pledgeStatus">
                  <Select>
                    {pledgeStatus.map((s) => {
                      return (
                        <Option key={s} value={s}>
                          {s}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
                <Form.Item label="Region" name={["jamatKhana", "region", "id"]}>
                  <RegionSelect />
                </Form.Item>
                <Form.Item
                  noStyle
                  shouldUpdate={(prev, curr) =>
                    prev.jamatKhana.region.id !== curr.jamatKhana.region.id
                  }
                >
                  {(form) => {
                    return <JkSelect form={form} />;
                  }}
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 8, span: 8 }}>
                  {!isPending && (
                    <Button type="primary" htmlType="submit">
                      Search Form(s)
                    </Button>
                  )}
                  {isPending && (
                    <Button type="primary" disabled>
                      Searching...
                    </Button>
                  )}
                </Form.Item>
              </Form>
            </Panel>
            {pledges && (
              <Panel header="Result Panel" key="2">
                <Table
                  dataSource={pledges}
                  columns={pledgeDisplayColumns}
                  pagination={{
                    total: pledges.length,
                    showTotal: (t) => `${t} results`,
                    defaultPageSize: 20,
                    defaultCurrent: 1,
                  }}
                  rowKey="id"
                />
              </Panel>
            )}
          </Collapse>
        </>
      )}
    </>
  );
};
