import {
  Button,
  Collapse,
  Form,
  Input,
  InputNumber,
  Select,
  Space,
  Spin,
  Table,
  Typography,
} from "antd";
import { ColumnsType } from "antd/lib/table";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Exception } from "../../components/Exception";
import { useCacheContext } from "../../hooks/useCacheContext";
import { Transaction } from "../../types";
import { CommonUtils } from "../../utils/CommonUtils";

export const SearchTransactions = () => {
  const { search }: { search: string } = useLocation();

  const [transactions, setTransactions] = useState<Transaction[] | null>(null);

  const { isCacheReady, transactionTypes } = useCacheContext();

  const [error, setError] = useState<string | null>(null);
  const [isPending, setIsPending] = useState(false);

  const [openPanels, setOpenPanels] = useState<string | string[]>(["1"]);

  const [formInstance] = Form.useForm();
  const { Panel } = Collapse;
  const { Title, Link: AntLink } = Typography;
  const { Option } = Select;
  const navigate = useNavigate();

  const { serialize, formatDate } = CommonUtils();

  const downloadTransactionContent = async (id: number) => {
    const { fetchBlob, downloadFile } = CommonUtils();

    try {
      const blob = await fetchBlob(`/transactions/${id}/download`);
      downloadFile(blob, `TransactionContent_${id}.zip`);
    } catch (err) {
      throw err;
    }
  };

  const donorDisplayColumns: ColumnsType<Transaction> = [
    {
      title: "Transaction Type",
      dataIndex: "typeName",
      key: "typeName",
    },
    {
      title: "Transaction Time",
      dataIndex: "time",
      render: (text: string) => formatDate(text),
    },
    {
      title: "User",
      dataIndex: ["user", "fullName"],
      key: "userName",
    },
    {
      title: "Message",
      dataIndex: "message",
      key: "message",
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (_text, record) => (
        <Space size="small" direction="vertical">
          <Link to={`/transactionDetails/${record.id}`}>Show Details</Link>
          <Link to={`/editTransaction/${record.id}`}>Edit Transaction</Link>
          {record.showDownloadLink && (
            <AntLink onClick={() => downloadTransactionContent(record.id)}>
              Download Content
            </AntLink>
          )}
        </Space>
      ),
    },
  ];

  useEffect(() => {
    setError(null);
    setOpenPanels("1");

    const fetchTransactions = async () => {
      if (search) {
        setIsPending(true);

        try {
          const { fetchFc } = CommonUtils();
          const result = await fetchFc<Transaction[]>(
            `/transactions${search}`,
            "GET"
          );

          if (result.length === 0) {
            setError("No records found matching your search criteria");
          } else {
            setTransactions(result);
            setOpenPanels("2");
          }

          setIsPending(false);
        } catch (err) {
          const errorMessage = (err as Error).message;
          setError(errorMessage);
          setIsPending(false);
        }
      }
    };

    fetchTransactions();
  }, [search]);

  const onFinish = async (transaction: Transaction) => {
    if (
      !transaction.donor?.raiserId &&
      !transaction.donor?.id &&
      !transaction.user?.id &&
      !transaction.user?.login &&
      !transaction.typeName
    ) {
      setError("At least one search criteria is required");
      return;
    }

    const searchString = serialize(transaction);
    navigate("/searchTransactions?" + searchString);
  };

  return (
    <>
      {!isCacheReady && (
        <div className="loading">
          <Spin size="large" />
        </div>
      )}
      {isCacheReady && (
        <>
          <Title className="text-center">Search Transactions</Title>
          {error && <Exception exceptionDetails={error} />}
          <Collapse
            defaultActiveKey={["1"]}
            activeKey={openPanels}
            onChange={setOpenPanels}
          >
            <Panel header="Search Panel" key="1">
              <Form
                form={formInstance}
                name="basic"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 8 }}
                onFinish={onFinish}
                autoComplete="off"
              >
                <Form.Item label="Donor Raiser ID" name={["donor", "raiserId"]}>
                  <InputNumber
                    placeholder="Donor Raiser ID"
                    style={{ width: "100%" }}
                    controls={false}
                  />
                </Form.Item>
                <Form.Item label="Donor ID" name={["donor", "id"]}>
                  <InputNumber
                    placeholder="Donor ID"
                    style={{ width: "100%" }}
                    controls={false}
                  />
                </Form.Item>
                <Form.Item label="User ID" name={["user", "id"]}>
                  <Input placeholder="User ID" />
                </Form.Item>
                <Form.Item label="User Login" name={["user", "login"]}>
                  <Input placeholder="User Login" />
                </Form.Item>
                <Form.Item label="Transaction Type" name="typeName">
                  <Select
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option!.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {transactionTypes &&
                      transactionTypes.map((t) => {
                        return (
                          <Option key={t} value={t}>
                            {t}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 8, span: 8 }}>
                  {!isPending && (
                    <Button type="primary" htmlType="submit">
                      Search Transactions
                    </Button>
                  )}
                  {isPending && (
                    <Button type="primary" disabled>
                      Searching...
                    </Button>
                  )}
                </Form.Item>
              </Form>
            </Panel>
            {transactions && (
              <Panel header="Result Panel" key="2">
                <Table
                  dataSource={transactions}
                  columns={donorDisplayColumns}
                  pagination={{
                    total: transactions.length,
                    showTotal: (t) => `${t} results`,
                    defaultPageSize: 20,
                    defaultCurrent: 1,
                  }}
                  rowKey="id"
                />
              </Panel>
            )}
          </Collapse>
        </>
      )}
    </>
  );
};
