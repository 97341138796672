import { UserOutlined } from "@ant-design/icons";
import { Menu, Tooltip, Typography } from "antd";
import { useIdleTimer } from "react-idle-timer";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../hooks/useAuthContext";
import { useCacheContext } from "../hooks/useCacheContext";
import { CommonUtils } from "../utils/CommonUtils";
import { MainMenu } from "./MainMenu";

import styles from "./Navbar.module.css";

export const Navbar = () => {
  const { user, dispatch } = useAuthContext();
  const { SubMenu } = Menu;
  const { fetchFc } = CommonUtils();
  const navigate = useNavigate();

  const { envInfo } = useCacheContext();

  const { Link: AntLink } = Typography;

  const signOut = async (e?: React.MouseEvent) => {
    e?.preventDefault();

    try {
      await fetchFc("/logout", "POST");
      sessionStorage.clear();

      if (!e) {
        sessionStorage.setItem("sessionTimedOut", "true");
      }

      dispatch!({ type: "LOGOUT" });
      navigate("/");
    } catch (err) {
      sessionStorage.clear();
    }
  };

  const handleOnIdle = () => {
    signOut();
  };

  useIdleTimer({
    timeout: 1000 * 60 * 15,
    onIdle: handleOnIdle,
    debounce: 500,
  });

  return (
    <div>
      <Menu theme="dark" mode="horizontal">
        <SubMenu key="MainMenu" title="Main Menu">
          <MainMenu />
        </SubMenu>
        <Menu.Item key="contact">
          <a
            href={`mailto:${envInfo?.messagesMap["application.contactEmail"]}?subject=${envInfo?.messagesMap["application.name"]} - Support`}
          >
            Contact
          </a>
        </Menu.Item>
        <Tooltip
          placement="left"
          title={user!.fullName + " / " + user!.currentRoleDisplayName}
        >
          <SubMenu
            className={styles.floatRight}
            title={user!.fullName}
            icon={<UserOutlined />}
            key="currentUserMenu"
          >
            <Menu.Item key="signOut">
              <AntLink onClick={signOut}>Sign Out</AntLink>
            </Menu.Item>
          </SubMenu>
        </Tooltip>
      </Menu>
    </div>
  );
};
