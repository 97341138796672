import { Spin, Typography } from "antd";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { BackButton } from "../../components/BackButton";
import { Exception } from "../../components/Exception";
import { ShowDetails } from "../../components/ShowDetails";
import { TransactionList } from "../../components/TransactionList";
import { DataMap, User } from "../../types";
import { CommonUtils } from "../../utils/CommonUtils";

type DisplayState = {
  user?: User;
  dataMap?: DataMap[];
  error?: string;
};

export const UserDetails = () => {
  const [displayState, setDisplayState] = useState<DisplayState>({});

  const { id } = useParams();

  const { Title } = Typography;

  useEffect(() => {
    setDisplayState({ error: undefined });

    async function loadUser() {
      const { fetchFc, formatDate } = CommonUtils();

      try {
        const user = await fetchFc<User>(`/users/${id}?forAudit=true`, "GET");

        const dataMap: DataMap[] = [];
        let key = 0;
        dataMap.push({ key: key++, label: "Login", value: user.login });
        dataMap.push({
          key: key++,
          label: "First Name",
          value: user.firstName,
        });
        dataMap.push({
          key: key++,
          label: "Last Name",
          value: user.lastName,
        });
        dataMap.push({
          key: key++,
          label: "Email",
          value: user.email,
        });
        dataMap.push({
          key: key++,
          label: "Phone",
          value: user.phone,
        });
        dataMap.push({
          key: key++,
          label: "Role",
          value: user.currentRoleDisplayName,
        });
        dataMap.push({
          key: key++,
          label: "Agreement Date",
          value: formatDate(user.agreementDate as string),
        });

        if (user.jamatKhana) {
          dataMap.push({
            key: key++,
            label: "Region",
            value: user.jamatKhana.region?.name,
          });

          dataMap.push({
            key: key++,
            label: "Jamat Khana",
            value: user.jamatKhana.name,
          });
        } else if (user.region) {
          dataMap.push({
            key: key++,
            label: "Region",
            value: user.region.name,
          });
        }

        dataMap.push({
          key: key++,
          label: "Last Login Date",
          value: formatDate(user.lastLogin as string),
        });

        setDisplayState({ user: user, dataMap: dataMap });
      } catch (err) {
        const error = (err as Error).message;
        setDisplayState({ error: error });
      }
    }

    loadUser();
  }, [id]);

  return (
    <div>
      {!displayState.user && (
        <div className="loading">
          <Spin size="large" />
        </div>
      )}
      {!displayState.user && displayState.error && (
        <Exception exceptionDetails={displayState.error} />
      )}
      {displayState.user && (
        <>
          <BackButton buttonText="Back to search page" />
          <Title className="text-center">User Details</Title>
          {displayState.error && (
            <Exception exceptionDetails={displayState.error} />
          )}
          <ShowDetails dataMap={displayState.dataMap} />
          <TransactionList
            transactions={displayState.user.tranList}
            hideUser={true}
          />
        </>
      )}
    </div>
  );
};
