import { Button, Space, Spin, Table, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BackButton } from "../../components/BackButton";
import { Exception } from "../../components/Exception";
import { ShowDetails } from "../../components/ShowDetails";
import { TransactionList } from "../../components/TransactionList";
import { Batch, DataMap, Pledge } from "../../types";
import { CommonUtils } from "../../utils/CommonUtils";

type DisplayState = {
  batch?: Batch;
  dataMap?: DataMap[];
  error?: string;
};

export const BatchDetails = () => {
  const [displayState, setDisplayState] = useState<DisplayState>({});

  const { Title } = Typography;

  const navigate = useNavigate();
  const { id } = useParams();

  const { formatCurrency } = CommonUtils();

  useEffect(() => {
    setDisplayState({ error: undefined });

    async function loadBatch() {
      const { fetchFc, formatDate } = CommonUtils();

      try {
        const batch = await fetchFc<Batch>(`/batches/${id}`, "GET");

        const dataMap: DataMap[] = [];
        let key = 0;
        dataMap.push({ key: key++, label: "Name", value: batch.name });
        dataMap.push({
          key: key++,
          label: "Region",
          value: batch.jamatKhana?.region?.name,
        });
        dataMap.push({
          key: key++,
          label: "Jamat Khana",
          value: batch.jamatKhana?.name,
        });
        dataMap.push({
          key: key++,
          label: "Date Created",
          value: formatDate(batch.createDate as string),
        });
        dataMap.push({
          key: key++,
          label: "Notes",
          value: batch.comment,
        });

        setDisplayState({ batch: batch, dataMap: dataMap });
      } catch (err) {
        const error = (err as Error).message;
        setDisplayState({ error: error });
      }
    }

    loadBatch();
  }, [id]);

  const formDisplayColumns: ColumnsType<Pledge> = [
    {
      title: "Form Number",
      dataIndex: "formNumber",
      key: "formNumber",
    },
    {
      title: "Donor Name",
      dataIndex: ["donor", "fullName"],
      key: "donorName",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (amount: number) => formatCurrency(amount),
    },
    {
      title: "Payment Method",
      dataIndex: "paymentType",
      key: "paymentType",
    },
  ];

  return (
    <div>
      {!displayState.batch && (
        <div className="loading">
          <Spin size="large" />
        </div>
      )}
      {!displayState.batch && displayState.error && (
        <Exception exceptionDetails={displayState.error} />
      )}
      {displayState.batch && (
        <>
          <Space>
            <BackButton buttonText="Back to search page" />
            <Button
              onClick={() => {
                navigate(`/printBatch/${id}`, {
                  state: { fromPage: "Batch Details" },
                });
              }}
            >
              Print Batch
            </Button>
          </Space>
          <Title className="text-center">Batch Details</Title>
          {displayState.error && (
            <Exception exceptionDetails={displayState.error} />
          )}
          <ShowDetails dataMap={displayState.dataMap} />
          {displayState.batch.formList &&
            displayState.batch.formList.length > 0 && (
              <Table
                dataSource={displayState.batch.formList}
                columns={formDisplayColumns}
                pagination={false}
                rowKey={(p) => p.id!}
                title={() => (
                  <span style={{ fontWeight: "bold" }}>Pledges</span>
                )}
                className="content-margin"
              />
            )}
          <TransactionList transactions={displayState.batch.transactionList} />
        </>
      )}
    </div>
  );
};
