import {
  Button,
  Collapse,
  Form,
  Input,
  Select,
  Space,
  Spin,
  Table,
  Typography,
} from "antd";
import { ColumnsType } from "antd/lib/table/Table";
import { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Exception } from "../../components/Exception";
import { JkSelect } from "../../components/JkSelect";
import { RegionSelect } from "../../components/RegionSelect";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useCacheContext } from "../../hooks/useCacheContext";
import { Pledge, User } from "../../types";
import { CommonUtils } from "../../utils/CommonUtils";

export const SearchUsers = () => {
  const { search, state }: { search: string; state: any } = useLocation();
  const display: Pledge = state;

  const { user: _sessionUser } = useAuthContext();
  const sessionUser = useRef<User>(_sessionUser).current as User;

  const [users, setUsers] = useState<User[] | null>(null);

  const { newUserRoles, isCacheReady } = useCacheContext();

  const [error, setError] = useState<string | null>(null);
  const [isPending, setIsPending] = useState(false);

  const [openPanels, setOpenPanels] = useState<string | string[]>(["1"]);

  const { Panel } = Collapse;
  const { Title } = Typography;
  const { Option } = Select;
  const navigate = useNavigate();

  const { serialize, formatPhoneNumber, getUserJk, stringComparator } =
    CommonUtils();

  const userDisplayColumns: ColumnsType<User> = [
    {
      title: "Region",
      key: "region",
      sorter: (a: User, b: User) =>
        stringComparator(
          a.region?.name || a.jamatKhana?.region?.name,
          b.region?.name || b.jamatKhana?.region?.name
        ),
      sortDirections: ["ascend", "descend"],
      render: (_: string, u: User) =>
        u.region?.name || u.jamatKhana?.region?.name,
    },
    {
      title: "Jamat Khana",
      dataIndex: ["jamatKhana", "name"],
      key: "jamatKhana",
      sorter: (a: User, b: User) =>
        stringComparator(a.jamatKhana?.name, b.jamatKhana?.name),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Name",
      dataIndex: "fullName",
      key: "fullName",
      defaultSortOrder: "ascend",
      sorter: (a: User, b: User) => a.fullName!.localeCompare(b.fullName!),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Login ID",
      dataIndex: "login",
      key: "login",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      render: (text: string) => formatPhoneNumber(text),
    },
    {
      title: "Role",
      dataIndex: "currentRoleDisplayName",
      key: "currentRoleDisplayName",
    },
    {
      title: "Enabled",
      dataIndex: "enabled",
      key: "enabled",
      render: (enabled: boolean) => (enabled ? "Yes" : "No"),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (_text, record) => (
        <Space size="small" direction="vertical">
          {!display && (
            <>
              {sessionUser.auth?.canAddUsers && (
                <Link to={`/editUser/${record.id}`}>Edit User</Link>
              )}
              <Link to={`/userDetails/${record.id}`}>User Details</Link>
            </>
          )}
          {display && display.volunteer1?.login && (
            <Link
              to="/editPledge"
              state={{ ...display, volunteer1: { id: record.id } }}
            >
              Assign Pledge Volunteer
            </Link>
          )}
          {display && display.volunteer2?.login && (
            <Link
              to="/editPledge"
              state={{ ...display, volunteer2: { id: record.id } }}
            >
              Assign Pledge Volunteer
            </Link>
          )}
        </Space>
      ),
    },
  ];

  useEffect(() => {
    const fetchUsers = async () => {
      if (search) {
        setError(null);
        setIsPending(true);

        try {
          const { fetchFc } = CommonUtils();
          const result = await fetchFc<User[]>(`/users${search}`, "GET");

          if (result.length === 0) {
            setError("No user found matching your search criteria");
          } else {
            setUsers(result);
            setOpenPanels("2");
          }

          setIsPending(false);
        } catch (err) {
          const errorMessage = (err as Error).message;
          setError(errorMessage);
          setIsPending(false);
        }
      }
    };

    fetchUsers();
  }, [search]);

  const onFinish = async (user: User) => {
    if (
      !sessionUser.auth?.isAdmin &&
      !user.firstName &&
      !user.lastName &&
      !user.email &&
      !user.phone &&
      !user.roleName &&
      !user.region?.id &&
      !user.jamatKhana?.id
    ) {
      setError("No search criteria provided");
      return;
    }

    const searchString = serialize(user);
    navigate("/searchUsers?" + searchString, { state: display });
  };

  return (
    <>
      {(!isCacheReady || isPending) && (
        <div className="loading">
          <Spin size="large" />
        </div>
      )}
      {isCacheReady && !isPending && (
        <>
          <Title className="text-center">Search Users</Title>
          {error && <Exception exceptionDetails={error} />}
          <Collapse
            defaultActiveKey={["1"]}
            activeKey={openPanels}
            onChange={setOpenPanels}
          >
            <Panel header="Search Panel" key="1">
              <Form
                name="basic"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 8 }}
                onFinish={onFinish}
                autoComplete="off"
                initialValues={{
                  jamatKhana: getUserJk(sessionUser),
                }}
              >
                <Form.Item label="First Name" name="firstName">
                  <Input placeholder="First Name" />
                </Form.Item>
                <Form.Item label="Last Name" name="lastName">
                  <Input placeholder="Last Name" />
                </Form.Item>
                <Form.Item label="Email Address" name="email">
                  <Input type="email" placeholder="first.last@ds.akdn.org" />
                </Form.Item>
                <Form.Item label="Phone Number" name="phone">
                  <Input placeholder="(111) 222-3333" />
                </Form.Item>
                <Form.Item label="Role" name="roleName">
                  <Select>
                    {newUserRoles &&
                      newUserRoles.map((ur) => {
                        return (
                          <Option key={ur.name} value={ur.name}>
                            {ur.displayName}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
                <Form.Item label="Region" name={["jamatKhana", "region", "id"]}>
                  <RegionSelect />
                </Form.Item>
                <Form.Item
                  noStyle
                  shouldUpdate={(prev, curr) =>
                    prev.jamatKhana.region.id !== curr.jamatKhana.region.id
                  }
                >
                  {(form) => {
                    return <JkSelect form={form} />;
                  }}
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 8, span: 8 }}>
                  {!isPending && (
                    <Button type="primary" htmlType="submit">
                      Search Users
                    </Button>
                  )}
                  {isPending && (
                    <Button type="primary" disabled>
                      Searching...
                    </Button>
                  )}
                </Form.Item>
              </Form>
            </Panel>
            {users && (
              <Panel header="Result Panel" key="2">
                <Table
                  dataSource={users}
                  columns={userDisplayColumns}
                  pagination={{
                    total: users.length,
                    showTotal: (t) => `${t} results`,
                    defaultPageSize: 20,
                    defaultCurrent: 1,
                  }}
                  rowKey={(u) => u.id!}
                />
              </Panel>
            )}
          </Collapse>
        </>
      )}
    </>
  );
};
